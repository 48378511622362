import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS, getCSRFToken } from "../config";
import {
  MetamapVerificationResponse,
  metamapVerificationResponseDecoder,
  MetamapVerification,
  MetaMapIdVerificationResponse,
  metaMapIdVerificationResponseDecoder,
  MetaMapVideoVerificationResponse,
  metaMapVideoVerificationResponseDecoder,
  MetaMapVerificationStatus,
  metaMapVerificationStatusDecoder,
} from "./interfaces";

export async function createIdentityVerification(): Promise<MetamapVerificationResponse> {
  const response = await enhancedFetch(`${API_URL}/metamap/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
  });

  return metamapVerificationResponseDecoder.verify(await response.json());
}

export async function submitDocuments(
  verification: MetamapVerification,
  data: FormData
): Promise<MetaMapIdVerificationResponse> {
  const response = await enhancedFetch(
    `${API_URL}/metamap/${verification.id}/verifications/${verification.identity}/documents/`,
    {
      ...FETCH_DEFAULT_CONFIGS,
      headers: {
        "X-CSRFToken": getCSRFToken(),
      },
      method: "POST",
      body: data,
    }
  );

  return metaMapIdVerificationResponseDecoder.verify(await response.json());
}

export async function submitVideos(
  verification: MetamapVerification,
  data: FormData
): Promise<MetaMapVideoVerificationResponse> {
  const response = await enhancedFetch(
    `${API_URL}/metamap/${verification.id}/verifications/${verification.identity}/videos/`,
    {
      ...FETCH_DEFAULT_CONFIGS,
      headers: {
        "X-CSRFToken": getCSRFToken(),
      },
      method: "POST",
      body: data,
    }
  );

  return metaMapVideoVerificationResponseDecoder.verify(await response.json());
}

export async function getVerificationStatus({ id }: MetamapVerification): Promise<MetaMapVerificationStatus> {
  const response = await enhancedFetch(`${API_URL}/metamap/${id}/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return metaMapVerificationStatusDecoder.verify(await response.json());
}
