import { TokenErrorResponse } from "Pages/Login/interfaces";

export default async function enhancedFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  const response = await fetch(input, init);
  if (!response.ok) {
    const data = await parseResponseData(response);
    const responseError = {
      statusText: response.statusText,
      status: response.status,
      data,
    };

    if (isTokenResponse(data) && data["code"] === "token_not_valid" && window.invalidTokenEvent)
      dispatchEvent(window.invalidTokenEvent);

    throw responseError;
  }
  return response;
}

function isTokenResponse(data: unknown): data is TokenErrorResponse {
  return Boolean(data && typeof data === "object" && "detail" in data && "code" in data);
}

async function parseResponseData(response: Response): Promise<unknown> {
  const contentType = response.headers.get("Content-Type");
  /* istanbul ignore if */
  if (contentType === "application/json") return (await response.json()) as unknown;
  /* istanbul ignore if */
  if (contentType === "text/plain") return await response.text();
  return "";
}
