import { ForgotPasswordData } from "Pages/ForgotPassword/interfaces";
import { Dispatch } from "react";
import { SecretQuestionData } from "Shared/SecretQuestionsForm/interfaces";
import SecretQuestionsForm from "Shared/SecretQuestionsForm/SecretQuestionsForm";

export default function SecretQuestionsStep({
  model,
  setModel,
}: {
  model: ForgotPasswordData;
  setModel: Dispatch<ForgotPasswordData>;
}): JSX.Element {
  return (
    <SecretQuestionsForm
      secretQuestions={model.secretQuestions as SecretQuestionData[]}
      onSubmit={(secretQuestions) =>
        new Promise((resolve) =>
          resolve(
            setModel({
              ...model,
              status: "restore-password",
              secretQuestions: secretQuestions,
            })
          )
        )
      }
    />
  );
}
