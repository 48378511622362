import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from "@mui/material";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { affiliationPurpose as assignPurpose } from "Services/api/register/register";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import FormikForm from "Shared/FormikForm/FormikForm";
import { requiredValidation } from "Utils/validations";
import { Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import { ActionButtons } from "../ActionButtons/ActionButtons";
import { useRegistrationContext } from "../Registration";

export function AffiliationPurpose() {
  const [{ id, name }, setRegContext] = useRegistrationContext();
  const msg = `¿Cuál es la razón principal que te motiva a afiliarte${name ? ", " + name : ""}?`;

  return (
    <SplitFormContainer
      title={msg}
      sideInfoTop={<AccessInfo />}
      form={
        <Formik
          initialValues={{
            id: id,
            purpose: "financing",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const { data } = await assignPurpose(values);
            setRegContext(data);
            setSubmitting(false);
          }}
          validationSchema={Yup.object({
            id: requiredValidation,
            purpose: requiredValidation,
          })}
        >
          <FormikForm width="100%">
            <FormikAffiliationPurpose />
            <ActionButtons />
          </FormikForm>
        </Formik>
      }
    />
  );
}

function FormikAffiliationPurpose() {
  const name = "purpose";
  const [{ value }, , { setValue }] = useField<string>(name);
  const { isSubmitting, errors: formikErrors, touched } = useFormikContext<{ [k: string]: string }>();
  const formikError = Boolean(touched[name]) && Boolean(formikErrors[name]);

  const checkLabelStyle = {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "25px",
    letterSpacing: "0.02em",
    color: "#383838",
  };

  return (
    <FormControl component="fieldset" variant="standard" disabled={isSubmitting}>
      <RadioGroup
        value={value}
        name={name}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      >
        <FormControlLabel
          value="financing"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>Financiamiento</Typography>}
        />

        <FormControlLabel
          value="savings"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>Ahorros</Typography>}
        />

        <FormControlLabel
          value="certificate"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>Certificado de Inversión</Typography>}
        />
      </RadioGroup>
      {formikError ? <FormHelperText error> {formikErrors[name]}</FormHelperText> : null}
    </FormControl>
  );
}
