import { StackProps, Stack, Typography } from "@mui/material";

export default function ValueElement({
  title,
  value,
  stackProps,
}: {
  title: React.ReactNode;
  value: React.ReactNode;
  stackProps?: StackProps;
}) {
  return (
    <Stack {...(stackProps ? stackProps : {})}>
      <Typography component="div">
        <strong>{title}</strong>
      </Typography>
      <Typography component="div">{value}</Typography>
    </Stack>
  );
}
