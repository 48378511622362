import { Checkbox, CheckboxProps, FormControlLabel, FormGroup, FormHelperText } from "@mui/material";
import { Field, useFormikContext } from "formik";

export default function FormikCheckbox(props: CheckboxProps & { name: string; label: React.ReactNode }): JSX.Element {
  const { isSubmitting, errors, touched } = useFormikContext<{ [k: string]: string }>();
  const error = Boolean(touched[props.name]) && Boolean(errors[props.name]);

  return (
    <Field
      as={LabeledCheckbox}
      disabled={isSubmitting}
      error={error}
      helperText={error ? errors[props.name] : ""}
      {...props}
    />
  );
}

function LabeledCheckbox(
  props: CheckboxProps & {
    name: string;
    label: React.ReactNode;
    error: boolean;
    helperText: React.ReactNode;
    value: boolean;
  }
): JSX.Element {
  const { error, helperText, label, ...filteredProps } = props;

  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox {...filteredProps} checked={filteredProps.value} />} label={label} />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormGroup>
  );
}
