import { MenuItem } from "@mui/material";
import { useFormikContext } from "formik";
import { Fragment } from "react";
import { ReadOnlySecurityQuestion, UserFormData } from "Services/api/register/interfaces";
import FormikSelect from "Shared/FormikSelect/FormikSelect";
import FormikTextField from "Shared/FormikTextField/FormikTextField";

export default function QuestionsSelect({ data }: { data: ReadOnlySecurityQuestion[] }) {
  const half = Math.ceil(data.length / 2);
  const firstHalf = data.slice(0, half);
  const secondHalf = data.slice(half);

  const {
    values: { question1, question2 },
  } = useFormikContext<UserFormData>();
  return (
    <Fragment>
      <FormikSelect
        id="question1"
        name="question1"
        label="Pregunta de seguridad:"
        required
        displayEmpty
        placeholder="Elige una pregunta de seguridad"
        SelectDisplayProps={{
          style: {
            whiteSpace: "break-spaces",
          },
        }}
      >
        {firstHalf
          .filter((question) => {
            return question2 !== question.name;
          })
          .map((question) => {
            return (
              <MenuItem sx={{ whiteSpace: "break-spaces" }} key={question.unique_id} value={question.name}>
                {question.name}
              </MenuItem>
            );
          })}
      </FormikSelect>
      <FormikTextField name="answer1" id="answer1" fullWidth label="Respuesta:" variant="outlined" required />

      <FormikSelect
        id="question2"
        name="question2"
        label="Pregunta de seguridad:"
        required
        displayEmpty
        placeholder="Elige una pregunta de seguridad"
      >
        {secondHalf
          .filter((question) => {
            return question1 !== question.name;
          })
          .map((question) => {
            return (
              <MenuItem sx={{ whiteSpace: "break-spaces" }} key={question.unique_id} value={question.name}>
                {question.name}
              </MenuItem>
            );
          })}
      </FormikSelect>
      <FormikTextField name="answer2" id="answer2" fullWidth label="Respuesta:" variant="outlined" required />
    </Fragment>
  );
}
