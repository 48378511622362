import { SVGProps } from "react";

export default function MailIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0.860596C5.87904 0.863053 3.84566 1.69165 2.34592 3.16461C0.846168 4.63758 0.00251067 6.63465 8.51118e-06 8.71774C-0.00253177 10.42 0.56363 12.0762 1.61164 13.432C1.61164 13.432 1.82982 13.7142 1.86546 13.7549L8 20.8606L14.1374 13.7513C14.1694 13.7135 14.3884 13.432 14.3884 13.432L14.3891 13.4299C15.4366 12.0746 16.0025 10.4193 16 8.71774C15.9975 6.63465 15.1538 4.63758 13.6541 3.16461C12.1543 1.69165 10.121 0.863053 8 0.860596ZM8 11.5749C7.42464 11.5749 6.86219 11.4073 6.3838 11.0934C5.9054 10.7794 5.53254 10.3332 5.31235 9.81112C5.09217 9.28905 5.03456 8.71457 5.14681 8.16034C5.25906 7.60611 5.53612 7.09701 5.94296 6.69743C6.34981 6.29785 6.86816 6.02574 7.43247 5.91549C7.99677 5.80525 8.58169 5.86183 9.11326 6.07808C9.64483 6.29433 10.0992 6.66054 10.4188 7.1304C10.7385 7.60025 10.9091 8.15265 10.9091 8.71774C10.9081 9.47521 10.6013 10.2014 10.056 10.737C9.51062 11.2726 8.77124 11.5739 8 11.5749Z"
        fill="#547725"
      />
    </svg>
  );
}
