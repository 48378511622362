import { ButtonBase, InputAdornment, Popover, TextFieldProps } from "@mui/material";
import { Field, useFormikContext } from "formik";
import InfoIcon from "Icons/InfoIcon";
import { Fragment, ReactNode, useState } from "react";
import { LabeledTextField } from "Shared/LabeledTextField/LabeledTextField";

export default function FormikTextField(
  props: TextFieldProps & { name: string; step?: string; min?: string; help?: ReactNode }
): JSX.Element {
  const { isSubmitting, errors, touched } = useFormikContext<{ [k: string]: string }>();
  const error = Boolean(touched[props.name]) && Boolean(errors[props.name]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { help, InputProps = {}, ...rest } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? `${props.name}-popover` : undefined;

  return (
    <Fragment>
      <Field
        as={LabeledTextField}
        disabled={isSubmitting}
        error={error}
        helperText={error ? errors[props.name] : ""}
        InputProps={{
          endAdornment: help ? (
            <InputAdornment component={ButtonBase} position="end" onClick={handleClick}>
              <InfoIcon width="22" height="22" />
            </InputAdornment>
          ) : null,
          ...InputProps,
        }}
        {...rest}
      />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {help}
      </Popover>
    </Fragment>
  );
}
