import {
  Box,
  Button,
  ButtonBase,
  FormHelperText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PhotoCapture from "Icons/PhotoCapture";
import PhotoUpload from "Icons/PhotoUpload";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import getBase64FileType from "Utils/getBase64FileType";
import urlToFile from "Utils/urlToFile";
import { FormProps } from "./interfaces";
import { ImageCaptureDialog } from "Shared/ImageCaptureDialog/ImageCaptureDialog";

const Title = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "17px",
  color: "#7C7C7C",
}));

export default function IdForm(props: FormProps) {
  const { onSubmit } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const [idImg, setIdImg] = useState<{ front: string; back: string }>({
    front: "",
    back: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    front: "",
    back: "",
  });
  const labels = ["Imagen delantera:", "Imagen trasera:"];

  return (
    <Stack spacing={2} sx={{ width: "100%", ...(isSmall ? {} : { padding: "50px", border: "1px dashed #000000" }) }}>
      <ImageCapture
        label={labels[0]}
        imgAlt="Parte delantera de cédula"
        error={errors.front}
        onChange={(front) => setIdImg({ ...idImg, front })}
      />

      <ImageCapture
        label={labels[1]}
        imgAlt="Parte trasera de cédula"
        error={errors.back}
        onChange={(back) => setIdImg({ ...idImg, back })}
      />

      <Button
        fullWidth
        variant="contained"
        onClick={() => void OnClick()}
        disabled={!idImg.front || !idImg.back || isSubmitting}
      >
        Enviar
      </Button>
    </Stack>
  );

  async function OnClick() {
    setIsSubmitting(true);
    setErrors({ front: "", back: "" });
    const formData = new FormData();

    const frontFiletype = getBase64FileType(idImg.front);
    formData.append(
      "front",
      await urlToFile(idImg.front, `front.${getBase64FileType(frontFiletype)}`, `image/${frontFiletype}`)
    );

    const backFiletype = getBase64FileType(idImg.back);
    formData.append(
      "back",
      await urlToFile(idImg.back, `back.${getBase64FileType(backFiletype)}`, `image/${backFiletype}`)
    );

    const res = await onSubmit(formData);

    if (res && "front" in res) {
      const { front, back } = res;
      const errors = { front: "", back: "" };
      if ("errorKey" in front) {
        enqueueSnackbar(`${labels[0]} ${front.message}`, { variant: "error" });
        errors.front = front.message;
      }

      if ("errorKey" in back) {
        enqueueSnackbar(`${labels[1]} ${back.message}`, { variant: "error" });
        errors.back = back.message;
      }

      setErrors(errors);
    }

    setIsSubmitting(false);
  }
}

interface ImageCaptureProps {
  label: string;
  imgAlt: string;
  error: string;
  onChange: (imgSrc: string) => void;
}

function ImageCapture(props: ImageCaptureProps) {
  const { label, imgAlt, error, onChange } = props;
  const [images, setImages] = useState<ImageListType>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [image, setImage] = useState<string>("");

  const onImageChange = (imageList: ImageListType) => {
    setImages(imageList);
    setImage(imageList[0]["data_url"] as string);
  };

  useEffect(() => {
    onChange(image);
  }, [image]);

  return (
    <Stack spacing={1}>
      <Title variant="h2">{label}</Title>
      <ImageUploading
        value={images}
        onChange={onImageChange}
        acceptType={["jpg", "jpeg", "png"]}
        maxFileSize={15728640}
        dataURLKey="data_url"
      >
        {({ onImageUpload, errors }) => (
          <Stack>
            <Stack
              sx={{ width: "100%", p: "25px 16px", border: "1px dashed #000000", borderRadius: "12px" }}
              direction="row"
              flexWrap="wrap"
              justifyContent={"space-around"}
            >
              {image ? (
                <Box sx={{ width: "100%" }} display="flex" justifyContent="center">
                  {" "}
                  <img src={image} alt={imgAlt} style={{ maxWidth: "100%", maxHeight: "250px" }} />{" "}
                </Box>
              ) : null}
              <Stack
                component={ButtonBase}
                spacing={2}
                style={{
                  width: "180px",
                  border: "2px solid rgba(124, 124, 124, 0.8)",
                  borderRadius: "13px",
                  margin: "15px 0px",
                  ...(image ? {} : { height: "180px" }),
                }}
                data-testid="upload-image"
                onClick={onImageUpload}
              >
                {!image ? <PhotoUpload /> : null}
                <Title>Subir</Title>
              </Stack>

              <Stack
                component={ButtonBase}
                spacing={2}
                style={{
                  width: "180px",

                  border: "2px solid rgba(124, 124, 124, 0.8)",
                  borderRadius: "13px",
                  margin: "15px 0px",
                  ...(image ? {} : { height: "180px" }),
                }}
                onClick={() => setOpenDialog(true)}
              >
                {!image ? <PhotoCapture /> : null}
                <Title>Capturar</Title>
              </Stack>
            </Stack>
            {error ? <FormHelperText error>{error}</FormHelperText> : null}
            {errors?.maxFileSize ? <FormHelperText error>La imagen no puede ser mayor de 15 MB</FormHelperText> : null}
          </Stack>
        )}
      </ImageUploading>
      <ImageCaptureDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onCapture={(imageSrc) => setImage(imageSrc)}
      />
    </Stack>
  );
}
