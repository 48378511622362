import { useState } from "react";
import {
  AppBar,
  Card,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { MoneyFormat } from "Shared/MoneyFormat/MoneyFormat";
import { GeneralBalanceData } from "Services/api/finance/interfaces";

const types = {
  savingAccount: "Cuentas de Ahorro",
  certificate: "Certificados",
  loan: "Préstamos",
};

type AvailableValues = "savingAccount" | "certificate" | "loan";

export function ProductTypeSummary({ data }: { data: GeneralBalanceData }): JSX.Element {
  const [type, setType] = useState<AvailableValues>("savingAccount");
  return (
    <>
      <AppBar position="static" sx={{ boxShadow: "none", borderRadius: "4px" }}>
        <Toolbar>
          <Stack flexGrow={1} spacing={1}>
            <Typography component="h2">
              <strong>Resumen de productos</strong>
            </Typography>
          </Stack>
          <Stack flexGrow={0}>
            <FormControl>
              <Select id="type" name="type" value={type} onChange={handleChange} sx={{ backgroundColor: "#fff" }}>
                {Object.entries(types).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Toolbar>
      </AppBar>
      <Stack
        spacing={2}
        component={Card}
        sx={{ padding: "30px", backgroundColor: "#F6F9F2 !important" }}
        elevation={0}
        alignItems="center"
        alignContent="center"
        justifyContent="center"
      >
        <Typography sx={{ color: "#315500", fontWeight: "bold" }}>Balance {types[type]}:</Typography>
        <Typography sx={{ color: "#315500", fontSize: "2rem", fontWeight: "bold" }}>
          <MoneyFormat amount={data[type].total} />
        </Typography>
      </Stack>
    </>
  );

  function handleChange(event: SelectChangeEvent<typeof type>): void {
    setType(event.target.value as AvailableValues);
  }
}
