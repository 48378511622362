import { NumericFormat } from "react-number-format";

interface IMoneyFormat {
  amount: number | string;
  decimalDigits?: number;
  prefix?: string;
}

const defaultPrefix = "RD$ ";
const defaultDecimalDigits = 2;

export function MoneyFormat({ amount, ...rest }: IMoneyFormat): JSX.Element {
  return typeof amount === "number" ? (
    <NumberTypeFormat amount={amount} {...rest} />
  ) : (
    <StringTypeFormat amount={amount} {...rest} />
  );
}

function NumberTypeFormat({
  amount,
  decimalDigits = defaultDecimalDigits,
  prefix = defaultPrefix,
}: Omit<IMoneyFormat, "amount"> & { amount: number }) {
  const numberFormat = amount.toFixed(decimalDigits).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  const format = prefix + numberFormat;
  return <span>{format}</span>;
}

function StringTypeFormat({
  amount,
  decimalDigits = defaultDecimalDigits,
  prefix = defaultPrefix,
}: Omit<IMoneyFormat, "amount"> & { amount: string }) {
  return (
    <NumericFormat
      thousandSeparator
      valueIsNumericString
      value={amount}
      displayType={"text"}
      decimalScale={decimalDigits}
      prefix={prefix}
    />
  );
}
