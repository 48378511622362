import { Formik, FormikHelpers } from "formik";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikForm from "Shared/FormikForm/FormikForm";
import { SecretQuestionData, secretQuestionsSchema } from "./interfaces";

export interface ISecretQuestions {
  [name: string]: string;
}

export default function SecretQuestionsForm({
  secretQuestions,
  onSubmit,
}: {
  secretQuestions: SecretQuestionData[];
  onSubmit: (secretQuestions: SecretQuestionData[]) => Promise<void>;
}): JSX.Element {
  const initValues = Object.assign(
    {},
    ...secretQuestions.map((record, index) => ({
      [`secret-question-${index}`]: record.question,
      [`secret-answer-${index}`]: record.answer || "",
    }))
  ) as ISecretQuestions;

  return (
    <Formik
      initialValues={initValues}
      validationSchema={secretQuestionsSchema(secretQuestions.length)}
      onSubmit={onValidateQuestionAnswers}
    >
      <FormikForm width="100%">
        <p>Por favor completa las preguntas secretas para proceder.</p>
        <SecretQuestions data={secretQuestions} />
        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );

  async function onValidateQuestionAnswers(
    values: ISecretQuestions,
    { setSubmitting }: FormikHelpers<ISecretQuestions>
  ) {
    await onSubmit(
      [...new Array(Object.keys(values).length / 2).keys()].map((index) => ({
        question: values[`secret-question-${index}`],
        answer: values[`secret-answer-${index}`],
      })) as SecretQuestionData[]
    );
    setSubmitting(false);
  }
}

export function SecretQuestions({
  data,
  questionAsLabel = false,
}: {
  data: SecretQuestionData[];
  questionAsLabel?: boolean;
}): JSX.Element {
  return (
    <>
      {data.map((record, index) => (
        <div key={index}>
          <FormikTextField
            name={`secret-question-${index}`}
            id={`secret-question-${index}`}
            fullWidth
            label={`Pregunta secreta ${index + 1}`}
            variant="outlined"
            required
            disabled
            hidden={questionAsLabel}
          />
          <FormikTextField
            name={`secret-answer-${index}`}
            id={`secret-answer-${index}`}
            fullWidth
            label={questionAsLabel ? record.question : `Respuesta secreta ${index + 1}`}
            variant="outlined"
            required
          />
        </div>
      ))}
    </>
  );
}
