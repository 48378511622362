import { SVGProps } from "react";

export default function PhoneIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.6673 13.5032L12.0451 11.8664C12.2307 11.6487 12.4654 11.4997 12.7209 11.4374C12.9764 11.3752 13.2416 11.4024 13.4844 11.5157L15.1635 12.3121C15.4088 12.4304 15.6191 12.6323 15.768 12.8924C15.9169 13.1525 15.9976 13.459 16 13.7735V17.427C15.9986 17.6409 15.9607 17.8523 15.8887 18.0484C15.8167 18.2445 15.712 18.4212 15.581 18.568C15.45 18.7148 15.2953 18.8285 15.1263 18.9024C14.9573 18.9763 14.7775 19.0088 14.5976 18.998C2.83136 18.1284 0.457192 6.29127 0.00819117 1.76099C-0.0126518 1.53852 0.00639187 1.31333 0.0640691 1.10024C0.121746 0.887139 0.21675 0.690964 0.34283 0.524618C0.468911 0.358272 0.62321 0.225524 0.795577 0.135108C0.967944 0.0446917 1.15447 -0.00134385 1.34289 2.98626e-05H4.31368C4.57878 0.000962066 4.83758 0.0960917 5.0568 0.273183C5.27602 0.450274 5.44563 0.701229 5.54382 0.993768L6.21424 2.98855C6.31281 3.27576 6.33796 3.59111 6.28654 3.8952C6.23513 4.19929 6.10944 4.47864 5.92516 4.69836L4.54741 6.33511C4.54741 6.33511 5.34084 12.714 10.6673 13.5032Z"
        fill="#547725"
      />
    </svg>
  );
}
