import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import {
  ForgotPasswordData,
  RestorePasswordData,
  restorePasswordDataKey,
  restorePasswordDataSchema,
} from "Pages/ForgotPassword/interfaces";
import { Dispatch } from "react";
import { useNavigate } from "react-router-dom";
import { restorePassword } from "Services/api/auth/auth";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import Password from "Shared/Password/Password";
import PasswordHide from "Shared/PasswordHide/PasswordHide";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

export default function RestorePasswordStep({
  model,
  setModel,
}: {
  model: ForgotPasswordData;
  setModel: Dispatch<ForgotPasswordData>;
}): JSX.Element {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{ [restorePasswordDataKey[0]]: "", [restorePasswordDataKey[1]]: "" }}
      validationSchema={restorePasswordDataSchema}
      onSubmit={onValidateNewPasswords}
    >
      <FormikForm width="100%">
        <p>Ingresa tu nueva contraseña.</p>
        <PasswordHide
          name={restorePasswordDataKey[0]}
          id={restorePasswordDataKey[0]}
          label="Crea una contraseña (4 dígitos):"
        />
        <Password
          name={restorePasswordDataKey[1]}
          id={restorePasswordDataKey[1]}
          label="Confirmar contraseña:"
          type="password"
        />
        <FormikSubmitButton fullWidth variant="contained">
          Restaurar
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );

  async function onValidateNewPasswords(
    values: RestorePasswordData,
    { setSubmitting }: FormikHelpers<RestorePasswordData>
  ) {
    try {
      const { status, data } = await restorePassword({
        ...model,
        newPassword: values.newPassword,
        newPasswordConfirm: values.newPasswordConfirm,
      });
      if (status === "success") {
        enqueueSnackbar("La contraseña se ha restaurado correctamente. Favor de iniciar sesión.", {
          variant: "success",
        });
        navigate("/");
      } else {
        if (data.errorKey === "invalidSecretQuestions") {
          enqueueSnackbar(data.message, { variant: "error" });
          setModel({
            ...model,
            status: "secret-questions",
          });
        } else if (data.errorKey === "invalidPassword") {
          enqueueSnackbar(data.message, { variant: "error" });
        }
      }
    } catch (error) {
      enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
      console.error(error);
    }

    setSubmitting(false);
  }
}
