import { ButtonBase, InputAdornment, TextFieldProps } from "@mui/material";
import VisibilityOffIcon from "Icons/VisibilityOffIcon";
import VisibilityIcon from "Icons/VisibilityIcon";
import Password from "Shared/Password/Password";
import { PatternFormatProps } from "react-number-format";
import { useToggle } from "Hooks/useToogle";

export default function PasswordHide(
  props: Partial<PatternFormatProps<TextFieldProps>> & { name: string }
): JSX.Element {
  const { name, id, label, InputProps } = props;
  const [showPassword, setShowPassword] = useToggle();

  return (
    <Password
      name={name}
      id={id}
      label={label}
      type={showPassword ? "text" : "password"}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment
            data-testid="password-visibility"
            component={ButtonBase}
            position="end"
            onClick={setShowPassword}
          >
            {showPassword ? <VisibilityOffIcon width="22" height="22" /> : <VisibilityIcon width="22" height="22" />}
          </InputAdornment>
        ),
      }}
    />
  );
}
