import { PhotoCamera } from "@mui/icons-material";
import { Button, FormHelperText, Stack } from "@mui/material";
import { useHasCamera } from "Hooks/useHasCamera";
import { useSingleEffect } from "Hooks/useSingleEffect";
import { FormikImageField } from "Shared/FormikImageField/FormikImageField";
import { FormikMoney } from "Shared/FormikMoney/FormikMoney";
import { FormikSlider } from "Shared/FormikSlider/FormikSlider";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { ImageCaptureDialog } from "Shared/ImageCaptureDialog/ImageCaptureDialog";
import { requiredValidation } from "Utils/validations";
import { useField, useFormikContext } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useNumericFormat } from "react-number-format";
import * as Yup from "yup";

export type SavingsAccountType = "in_sight" | "youth";

const numericConfig = {
  allowNegative: false,
  decimalScale: 2,
  thousandSeparator: ",",
  fixedDecimalScale: true,
};

interface GeneralProps {
  minMonthlyDeposit?: number;
  maxMonthlyDeposit?: number;
  isRegistration?: boolean;
}

interface SavingsAccountFormProps extends GeneralProps {
  type: SavingsAccountType;
}

export function SavingsAccountForm({
  minMonthlyDeposit = 0,
  maxMonthlyDeposit = 0,
  type,
  isRegistration = false,
}: SavingsAccountFormProps) {
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });

  const showInitialDeposit = !isRegistration || (isRegistration && type === "in_sight");
  const showMonthlyDeposit = !isRegistration || (isRegistration && type === "youth");

  return (
    <Fragment>
      {type === "youth" && <ChildAccount />}
      {showInitialDeposit && (
        <FormikMoney
          id="initialDeposit"
          name="initialDeposit"
          label={isRegistration ? "Digitar aumento" : "Balance de apertura"}
          placeholder={isRegistration ? "" : "Opcional"}
          variant="outlined"
        />
      )}
      {showMonthlyDeposit && (
        <FormikSlider
          id="monthlyDeposit"
          name="monthlyDeposit"
          label="Cuota"
          aria-label="Cuota"
          valueLabelDisplay="auto"
          min={minMonthlyDeposit}
          max={maxMonthlyDeposit}
          step={200}
          marks
          formatValue={(value) => `RD$${format(value)}`}
        />
      )}
    </Fragment>
  );
}

function ChildAccount() {
  const [hasCamara, setHasCamera] = useHasCamera();
  const [openDialog, setOpenDialog] = useState(false);
  const [{ value: image }, { error }, { setValue: setImage }] = useField<File | string | undefined>("birthCertificate");
  const [touched, setTouched] = useState(false);
  const { isSubmitting } = useFormikContext<{ [k: string]: string }>();

  useEffect(() => {
    if (isSubmitting) setTouched(true);
  }, [isSubmitting, setTouched]);

  useSingleEffect(() => {
    setHasCamera();
  });

  return (
    <Fragment>
      <FormikTextField id="childName" name="childName" label="Nombre del niño" variant="outlined" required />
      <FormikTextField
        id="childAge"
        name="childAge"
        label="Edad del niño"
        variant="outlined"
        required
        type="number"
        step="1"
        min="0"
      />
      <label htmlFor={"birthCertificate"}>{"Acta de nacimiento"}</label>
      <Stack direction="row" spacing={1}>
        {hasCamara && (
          <Button
            variant="outlined"
            startIcon={<PhotoCamera style={{ fontSize: "44px" }} />}
            onClick={() => setOpenDialog(true)}
            fullWidth
          >
            Tomar foto
          </Button>
        )}
        <FormikImageField
          name="birthCertificate"
          containerProps={{ spacing: 0, width: "100%" }}
          showHelperText={false}
        />
      </Stack>

      {typeof image === "string" ? (
        <FormHelperText>Acta.jpg</FormHelperText>
      ) : (
        <FormHelperText>
          {image
            ? image.name
            : "El archivo debe estar en formato jpg, png o pdf, y pesar menos de 6 MB. 1 solo archivo."}
        </FormHelperText>
      )}
      {touched && error && <FormHelperText error> {error}</FormHelperText>}

      <ImageCaptureDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onCapture={(imageSrc) => setImage(imageSrc)}
      />
    </Fragment>
  );
}

export function useSavingsAccountFormSchema({
  minMonthlyDeposit = 0,
  maxMonthlyDeposit = 0,
  isRegistration = false,
}: GeneralProps) {
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });

  let monthlyDepositSchema = Yup.object({});
  const monthlyDepositNumberSchema = Yup.number()
    .required("Requerido")
    .integer("Debe ser un entero")
    .positive("Debe ser un entero positivo")
    .min(minMonthlyDeposit, `No menos de RD$${format(minMonthlyDeposit.toString())}`)
    .max(maxMonthlyDeposit, `No mas de RD$${format(maxMonthlyDeposit.toString())}`);

  if (isRegistration) {
    monthlyDepositSchema = Yup.object({
      monthlyDeposit: Yup.number().when("type", {
        is: "youth",
        then: () => monthlyDepositNumberSchema,
      }),
    });
  } else {
    monthlyDepositSchema = Yup.object({
      monthlyDeposit: monthlyDepositNumberSchema,
    });
  }

  return Yup.object({
    type: requiredValidation,
    childName: Yup.string().when("type", {
      is: "youth",
      then: (schema) => schema.required("Requerido"),
    }),
    childAge: Yup.number()
      .integer("Debe ser un entero")
      .positive("Debe ser un entero positivo")
      .when("type", {
        is: "youth",
        then: (schema) => schema.required("Requerido"),
      }),
    birthCertificate: Yup.mixed().when("type", {
      is: "youth",
      then: (schema) => schema.required("Archivo requerido"),
    }),
    initialDeposit: Yup.number().nullable(),
  }).concat(monthlyDepositSchema);
}
