import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ClientIdForm from "Pages/ForgotPassword/ForgotPasswordDialog/ClientIdForm";
import { BasicDialogProps } from "Pages/Login/interfaces";
import MaskEmailForm from "Pages/ForgotPassword/ForgotPasswordDialog/MaskEmailForm";

export interface ForgotPasswordDialogModel {
  status: "client-verification" | "email-validation";
  clientId?: string;
  email?: string;
  maskedEmail?: string;
}

const ForgotPasswordDialogView = {
  "client-verification": ClientIdForm,
  "email-validation": MaskEmailForm,
};

const defaultState: ForgotPasswordDialogModel = {
  status: "client-verification",
  clientId: "",
  email: "",
  maskedEmail: "",
};

export default function ForgotPasswordDialog(props: BasicDialogProps): JSX.Element {
  const { onClose, open } = props;
  const [forgotPasswordDialogModel, setForgotPasswordDialogModel] = useState<ForgotPasswordDialogModel>(defaultState);
  const StepElement = ForgotPasswordDialogView[forgotPasswordDialogModel.status];
  const closeDialog = () => {
    onClose();
    setForgotPasswordDialogModel(defaultState);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle id={"forgotPasswordDialog"}>¿Olvidaste tu contraseña?</DialogTitle>
      <DialogContent>
        <StepElement
          model={forgotPasswordDialogModel}
          setModel={setForgotPasswordDialogModel}
          closeDialog={closeDialog}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
