import enhancedFetch from "Utils/enhancedFetch";
import { InstitutionsResponse, institutionsResponseDecoder } from "./interfaces";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS } from "../config";

const ENDPOINT = `${API_URL}/institutions`;

export async function searchInstitution(criteria: string): Promise<InstitutionsResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/search/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ criteria }),
  });

  return institutionsResponseDecoder.verify(await response.json());
}
