import { Skeleton, Stack } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { createSearchParams, useNavigate } from "react-router-dom";
import { SavingsPlanPayload, SavingsPlanPoolResponse } from "Services/api/request/interfaces";
import { getProductRequests, getSavingsPlanPool, savePlanRequest } from "Services/api/request/request";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { Query } from "Shared/Query/Query";
import { SavingsPlanForm, useSavingsPlanFormSchema } from "Shared/SavingsPlanForm/SavingsPlanForm";
import { SectionTitle } from "Shared/styled";

export function SavingsPlanRequest() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getSavingsPlanPool.name],
    queryFn: async () => {
      try {
        const response = await getSavingsPlanPool();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Stack>
      <SectionTitle variant="h1">Solicitud de plan de ahorros</SectionTitle>
      <Query
        result={result}
        OnLoading={() => (
          <Stack width={"100%"} spacing={2}>
            <Stack spacing={1} width="100%">
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Stack>
            <Stack spacing={1} width="100%">
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Stack>
            <Stack spacing={1} width="100%">
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Stack>
            <Stack spacing={1} width="100%">
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Stack>
            <Stack spacing={1} width="100%">
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Stack>
            <Stack spacing={1} width="100%">
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Stack>
            <Skeleton variant="rectangular" width={"100%"} height={37} />
          </Stack>
        )}
        onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
        onSuccess={(result) => <Form result={result} />}
      />
    </Stack>
  );
}

interface FormProps {
  result: SavingsPlanPoolResponse["data"];
}

function Form({ result }: FormProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const savingsPlanFormSchema = useSavingsPlanFormSchema(result);
  const { planDepositMin, planGoalMin, planTermMin } = result;
  const initialValues: SavingsPlanPayload = {
    typeOfPlan: "",
    planDeposit: planDepositMin,
    planGoal: planGoalMin,
    planTerm: planTermMin,
    periodicity: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { data } = await savePlanRequest(values);
          enqueueSnackbar("Solicitud creada exitosamente", { variant: "success" });

          void queryClient.invalidateQueries({
            queryKey: [getProductRequests.name],
            exact: true,
          });

          if (data.signatureRequired)
            navigate({
              pathname: "/solicitudes/firma/",
              search: createSearchParams({
                id: data.uniqueId,
                type: "savings",
              }).toString(),
            });
          else navigate("/solicitudes/estados/");

          setSubmitting(false);
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        }
      }}
      validationSchema={savingsPlanFormSchema}
    >
      <FormikForm width="100%">
        <SavingsPlanForm options={result} />

        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );
}
