import { styled, Typography, Stack } from "@mui/material";

const SideInfoTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: "36px",
  lineHeight: "58px",
}));

interface SideInfoProps {
  title: string;
  description: string;
  steps: string[];
}

export default function SideInfo({ title, description, steps }: SideInfoProps): JSX.Element {
  return (
    <Stack spacing={2} direction="column">
      <SideInfoTitle variant="h1">{title}</SideInfoTitle>
      <article>
        {description}
        <ol className="side-info-list">
          {steps.map((txt, index) => (
            <li key={index}>{txt}</li>
          ))}
        </ol>
      </article>
    </Stack>
  );
}
