import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { otpCodeSchema, OtpProps, OtpValidationFormFields } from "Shared/OtpValidation/OtpValidation";
import * as Yup from "yup";

const otpValidationSchema = Yup.object({
  code: otpCodeSchema,
});

interface Props extends OtpProps {
  onSubmit: (values: { code: string }, { setSubmitting }: FormikHelpers<{ code: string }>) => Promise<void>;
  onBack?: () => void;
  goBackPending?: boolean;
}

export default function OtpValidationForm(props: Props): JSX.Element {
  const { onSubmit, onBack, goBackPending = false } = props;

  return (
    <Formik initialValues={{ code: "" }} validationSchema={otpValidationSchema} onSubmit={onSubmit}>
      <FormikForm width="100%">
        <OtpValidationFormFields {...props} />

        {onBack ? (
          <Stack direction="row" width="100%" spacing={2}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<ChevronLeft />}
              sx={{ justifyContent: "flex-start" }}
              onClick={onBack}
              disabled={goBackPending}
            >
              ATRAS
            </Button>
            <FormikSubmitButton
              fullWidth
              variant="contained"
              endIcon={<ChevronRight />}
              sx={{ justifyContent: "flex-end" }}
            >
              SIGUIENTE
            </FormikSubmitButton>
          </Stack>
        ) : (
          <FormikSubmitButton fullWidth variant="contained">
            Siguiente
          </FormikSubmitButton>
        )}
      </FormikForm>
    </Formik>
  );
}
