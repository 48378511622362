import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, BoxProps, ButtonBase, IconButton, Stack, Toolbar, useTheme } from "@mui/material";
import { Fragment, PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../../../public/logo.png";

interface NavBarProps extends PropsWithChildren {
  loggedIn?: boolean;
  handleDrawerToggle?: () => void;
  rightMenu?: React.ReactNode;
}

export default function NavBar({
  children,
  loggedIn = false,
  rightMenu,
  handleDrawerToggle = () => undefined,
}: NavBarProps): JSX.Element {
  const theme = useTheme();
  const logoProps: BoxProps<"img"> = {
    sx: { height: 40 },
    src: logo,
    component: "img",
    alt: "Cooperativa Barcelona logo",
  };

  return (
    <Stack sx={{ height: "100vh", justifyContent: "space-between" }}>
      <AppBar sx={{ boxShadow: "none" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            {loggedIn ? (
              <Fragment>
                <ButtonBase component={RouterLink} to="/">
                  <Box
                    {...logoProps}
                    sx={(theme) => ({ height: 40, [theme.breakpoints.down("sm")]: { display: "none" } })}
                  />
                </ButtonBase>
                <IconButton
                  className="logout-button"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  sx={{ backgroundColor: "#fff", mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon htmlColor={theme.palette.primary.main} />
                </IconButton>
              </Fragment>
            ) : (
              <ButtonBase component={RouterLink} to="/">
                <Box {...logoProps} />
              </ButtonBase>
            )}
          </Box>
          <Box sx={{ flexGrow: 0 }}>{rightMenu}</Box>
        </Toolbar>
      </AppBar>

      <main>{children}</main>
    </Stack>
  );
}
