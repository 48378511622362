import { SVGProps } from "react";

export default function BiometricStep2(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="50" height="50" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="30.5192" cy="30.5192" r="30.5192" fill="#547725" />
      <path
        d="M28.2518 27.5744C28.2822 25.997 28.3732 23.5401 31.3458 23.5401C33.4084 23.5401 34.3184 25.1781 34.3184 26.6037C34.3184 29.0303 32.7714 30.1223 28.8888 32.9433C24.2175 36.3406 24.0355 38.9796 23.8839 41.4972H38.5954V37.7359H29.2528C29.7381 36.7349 31.2245 35.7643 34.0758 33.7623C36.1687 32.3366 38.565 30.456 38.565 26.7554C38.565 22.3874 35.2891 19.9608 31.1638 19.9608C29.2528 19.9608 24.1265 20.5674 24.1265 27.5744H28.2518Z"
        fill="white"
      />
    </svg>
  );
}
