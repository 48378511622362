import { requiredValidation, passwordValidation } from "Utils/validations";
import * as Yup from "yup";

export const UserFormSchema = Yup.object({
  password: passwordValidation,
  confirm: passwordValidation.oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir"),
  question1: requiredValidation,
  answer1: requiredValidation.max(40, "La respuesta no debe exceder 40 caracteres"),
  question2: requiredValidation,
  answer2: requiredValidation.max(40, "La respuesta no debe exceder 40 caracteres"),
});
