import { styled, AccordionSummaryProps, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export const ProductSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<ExpandMore htmlColor="#fff" />} {...props} />
))(({ theme }) => ({
  color: "#fff",
  fontWeight: "bold",
  borderRadius: "4px",
  backgroundColor: theme.palette.primary.main,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
