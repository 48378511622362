import { Alert, MenuItem, Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { getTypeOfPaymentChoices } from "Services/api/request/request";
import FormikSelect, { FormikSelectProps } from "Shared/FormikSelect/FormikSelect";
import { Query } from "Shared/Query/Query";

export function TypeOfPayment({
  label = "",
  placeholder = "",
  ariaLabel = "",
  help,
}: {
  label?: string;
  placeholder?: string;
  ariaLabel?: string;
  help?: string;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getTypeOfPaymentChoices.name],
    queryFn: async () => {
      try {
        const response = await getTypeOfPaymentChoices();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const props: FormikSelectProps<string> = {
    id: "typeofPayment",
    name: "typeofPayment",
    label: label,
    displayEmpty: !!placeholder,
    placeholder: placeholder,
    SelectDisplayProps: { "aria-label": ariaLabel ? ariaLabel : label },
    required: true,
  };

  if (help)
    props["help"] = (
      <Alert severity="info" color="success" variant="outlined">
        {help}
      </Alert>
    );

  return (
    <Query
      result={result}
      OnLoading={() => (
        <Stack spacing={1} width="100%">
          <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
          <Skeleton variant="rectangular" width={"100%"} height={56} />
        </Stack>
      )}
      onError={() => <FormikSelect {...props} />}
      onSuccess={(typesOfPayment) => (
        <FormikSelect {...props}>
          {typesOfPayment.map(([value, display]) => (
            <MenuItem key={value} value={value}>
              {display}
            </MenuItem>
          ))}
        </FormikSelect>
      )}
    />
  );
}
