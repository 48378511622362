import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  CircularProgressProps,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { createSearchParams, Link as RouterLink } from "react-router-dom";
import { ProductRequest } from "Services/api/request/interfaces";
import { getProductRequests } from "Services/api/request/request";
import { Query } from "Shared/Query/Query";
import { SectionTitle } from "Shared/styled";

export function RequestStatus() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getProductRequests.name],
    queryFn: async () => {
      try {
        const response = await getProductRequests();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Fragment>
      <SectionTitle variant="h1">Estado de solicitudes</SectionTitle>
      <Query
        result={result}
        OnLoading={() => <div>Cargando solicitudes...</div>}
        onSuccess={(values) => <StatusList values={values} />}
      />
    </Fragment>
  );
}

interface StatusListProps {
  values: ProductRequest[];
}

function StatusList({ values }: StatusListProps) {
  const Signature = ({ id, type }: { id: ProductRequest["id"]; type: ProductRequest["type"] }) => {
    const params = createSearchParams({
      id,
      type,
    }).toString();

    return (
      <Button variant="contained" fullWidth component={RouterLink} to={`/solicitudes/firma/?${params}`}>
        Firmar
      </Button>
    );
  };
  return (
    <Fragment>
      <Stack spacing={2} sx={{ display: { md: "none" } }}>
        {values.map(({ id, statusDisplay, progress, product, type, created, signatureRequired }) => (
          <Card key={id} variant="outlined">
            <CardContent component={Stack} spacing={2}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {statusDisplay}
              </Typography>
              <LinearProgress variant="determinate" color={"primary"} value={progress} />
              <Typography>{product}</Typography>
              <Typography>
                <strong>Creación:</strong> {created}
              </Typography>
            </CardContent>
            <CardActions>{signatureRequired && <Signature id={id} type={type} />}</CardActions>
          </Card>
        ))}
      </Stack>
      <Table
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: { display: "none" },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell>Creación</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map(({ id, statusDisplay, progress, product, type, created, signatureRequired }) => (
            <TableRow key={id}>
              <TableCell sx={{ textWrap: "nowrap" }}>{created}</TableCell>
              <TableCell>{product}</TableCell>
              <TableCell
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {statusDisplay}
              </TableCell>
              <TableCell>
                <CircularProgressWithLabel variant="determinate" color={"primary"} value={progress} />
              </TableCell>
              <TableCell>{signatureRequired && <Signature id={id} type={type} />}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Fragment>
  );
}

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color={(theme) => theme.palette.primary.main}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
