import { Decoder, object, string } from "decoders";
import { JsendResponse, jsendResponseDecoder } from "Utils/jsend";

export type MaskedEmailResponse = JsendResponse<
  {
    email: string;
  },
  string
>;

export const maskedEmailResponseDecoder: Decoder<MaskedEmailResponse> = jsendResponseDecoder(
  object({
    email: string,
  }),
  string
);
