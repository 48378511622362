import { PatternFormat, PatternFormatProps } from "react-number-format";
import { TextFieldProps } from "@mui/material";
import { useField } from "formik";
import FormikTextField from "Shared/FormikTextField/FormikTextField";

export default function Password(props: Partial<PatternFormatProps<TextFieldProps>> & { name: string }): JSX.Element {
  const { name, id, label, type, InputProps } = props;
  const [{ value }, , { setValue }] = useField<string>(props.name);

  return (
    <PatternFormat
      name={name}
      id={id}
      fullWidth
      label={label}
      variant="outlined"
      type={type || "password"}
      required
      InputProps={InputProps}
      format="####"
      value={value}
      customInput={FormikTextField}
      onValueChange={({ value }) => {
        setValue(value);
      }}
    />
  );
}
