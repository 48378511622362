import { BasicDialogProps } from "Pages/Login/interfaces";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link } from "@mui/material";

export default function ClientWithAccessDialog(
  props: BasicDialogProps & { openForgotPassword: () => void }
): JSX.Element {
  const { onClose, open, openForgotPassword } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Cliente registrado</DialogTitle>
      <DialogContent>
        <p>Los datos que has utilizado para el registro son de un cliente que ya tiene acceso a nuestro sistema.</p>
        <p>
          Puedes intentar acceder cerrando este cuadro o{" "}
          <Link
            onClick={() => {
              onClose();
              openForgotPassword();
            }}
          >
            <strong>hacer click aqui para recuperar tu contraseña.</strong>
          </Link>
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
