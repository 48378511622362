import { SVGProps } from "react";

export default function WifiIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.75 10.2681C5.23242 9.03331 7.1007 8.35715 9.03 8.35715C10.9593 8.35715 12.8276 9.03331 14.31 10.2681"
        stroke="#547725"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.06494 7.60562C3.25676 5.67359 6.07816 4.6076 8.99994 4.6076C11.9217 4.6076 14.7431 5.67359 16.9349 7.60562"
        stroke="#547725"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.39746 12.9381C7.15886 12.3971 8.06972 12.1065 9.00371 12.1065C9.93771 12.1065 10.8486 12.3971 11.61 12.9381"
        stroke="#547725"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 15.8556H9.0075" stroke="#547725" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
