import { ForgotPasswordDialogData } from "Pages/ForgotPassword/interfaces";
import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS } from "../config";
import { MaskedEmailResponse, maskedEmailResponseDecoder } from "./interfaces";

export async function getMaskEmail(clientId: ForgotPasswordDialogData["clientId"]): Promise<MaskedEmailResponse> {
  const response = await enhancedFetch(`${API_URL}/mask/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ clientId }),
  });

  return maskedEmailResponseDecoder.verify(await response.json());
}
