import { Clear } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Record from "Icons/Record";
import { createRef, useCallback } from "react";
import Webcam from "react-webcam";

interface ImageCaptureDialogProps {
  open: boolean;
  onClose: () => void;
  onCapture: (imageSrc: string) => void;
}

export const ImageCaptureDialog = (props: ImageCaptureDialogProps) => {
  const { onClose, open, onCapture } = props;

  const webcamRef = createRef<Webcam>();
  const capture = useCallback(() => {
    const imageSrc: string = webcamRef.current?.getScreenshot() || "";
    onCapture(imageSrc);

    onClose();
  }, [webcamRef, onCapture, onClose]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
      <Stack component={DialogTitle} direction={"row"} justifyContent="space-between" alignItems={"center"}>
        Capturar imagen
        <IconButton onClick={onClose} sx={{ color: "#000" }}>
          <Clear />
        </IconButton>
      </Stack>

      <DialogContent>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          videoConstraints={{
            facingMode: "environment",
            width: 1280,
            height: 794,
          }}
          screenshotQuality={1}
          forceScreenshotSourceSize
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <IconButton onClick={capture} sx={{ width: "fit-content" }}>
          <Record />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
