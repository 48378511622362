import { Alert, Button, Grid, MenuItem } from "@mui/material";
import { Form, Formik, FormikHelpers, FormikValues, useFormikContext } from "formik";
import { useAsync, UseAsyncStatus } from "Hooks/useAsync";
import { useHandleError } from "Hooks/useHandleError";
import { clientFormSchema } from "Pages/Registration/ClientForm/interfaces";
import { Fragment, useCallback, useEffect } from "react";
import { getCities, getProvinces, getRegions } from "Services/api/crm/crm";
import { AddressFormOptions } from "Services/api/crm/interfaces";
import { FormikImageField } from "Shared/FormikImageField/FormikImageField";
import FormikSelect from "Shared/FormikSelect/FormikSelect";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import * as Yup from "yup";
import { AddressFormProps, addressSimplifiedSchema } from "./interfaces";

interface FormValues extends FormikValues {
  country: string;
  region: string;
  province: string;
  city: string;
  sector: string;
  line1: string;
}

export function AddressForm<T extends FormikValues>(
  props: AddressFormProps<T> & { simplified?: boolean; requiredCert?: boolean }
) {
  const { options, onSubmit, onBack, initialValues, simplified, requiredCert } = props;
  const schema = simplified ? addressSimplifiedSchema : clientFormSchema.address;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: T, { setSubmitting }: FormikHelpers<T>) => {
        await onSubmit(values, "address");
        setSubmitting(false);
      }}
      validationSchema={
        requiredCert ? schema.concat(Yup.object({ addressCert: Yup.mixed().required("Archivo requerido") })) : schema
      }
    >
      <Grid container width="100%" spacing={2} component={Form}>
        <AddressOptions options={options} />
        <Grid item sm={12} width="100%">
          <FormikTextField name="sector" id="sector" fullWidth label="Sector:" variant="outlined" />
        </Grid>
        <Grid item sm={12} width="100%">
          <FormikTextField
            name="line1"
            id="line1"
            fullWidth
            label={simplified ? "Dirección:" : " Dirección 1:"}
            variant="outlined"
            placeholder={
              simplified ? "Nombre de la calle, número de casa, apt, piso, etc." : "Nombre de la calle y número de casa"
            }
          />
        </Grid>

        {!simplified && (
          <Fragment>
            <Grid item sm={12} width="100%">
              <FormikTextField
                name="line2"
                id="line2"
                fullWidth
                label="Dirección 2:"
                variant="outlined"
                placeholder="Apt, piso, etc."
              />
            </Grid>
            {requiredCert && (
              <Grid item sm={12} width="100%">
                <FormikImageField
                  name="addressCert"
                  label="Certificación de dirección"
                  help={
                    <Alert severity="info" color="success" variant="outlined">
                      Puede ser una factura de servicio eléctrico o telefónico a nombre de la persona que realiza el
                      proceso de afiliación, y debe mostrar la dirección actual de dicha persona.
                    </Alert>
                  }
                />
              </Grid>
            )}
          </Fragment>
        )}
        {onBack ? (
          <Fragment>
            <Grid item xs={6} width="100%">
              <Button fullWidth variant="outlined" onClick={onBack}>
                Atrás
              </Button>
            </Grid>
            <Grid item xs={6} width="100%">
              <FormikSubmitButton fullWidth variant="contained">
                Siguiente
              </FormikSubmitButton>
            </Grid>
          </Fragment>
        ) : (
          <Grid item sm={12} width="100%">
            <FormikSubmitButton fullWidth variant="contained">
              Siguiente
            </FormikSubmitButton>
          </Grid>
        )}
      </Grid>
    </Formik>
  );
}

function AddressOptions({ options: { regions: providedRegions } }: { options: AddressFormOptions }) {
  const { values, setValues } = useFormikContext<FormValues>();
  const { country, region, province, city } = values;
  const handleError = useHandleError();

  const getFilteredRegions = useCallback(async () => {
    if (providedRegions.length > 0) return providedRegions;
    const { data } = await getRegions(country);
    return data;
  }, [country]);
  const {
    status: regionsStatus,
    value: regions,
    execute: executeGetRegions,
  } = useAsync(
    getFilteredRegions,
    [],
    (error) => {
      setValues({ ...values, region: "", province: "", city: "" });
      handleError(error);
    },
    false
  );

  useEffect(() => void executeGetRegions(), [country]);

  const getFilteredProvinces = useCallback(async () => {
    if (!region) return [];
    const { data } = await getProvinces(region);
    if (!data.map((provinceData) => provinceData.name).includes(province))
      setValues({ ...values, province: "", city: "" });
    return data;
  }, [region]);
  const {
    status: provincesStatus,
    value: provinces,
    execute: executeGetProvinces,
  } = useAsync(
    getFilteredProvinces,
    [],
    (error) => {
      setValues({ ...values, province: "", city: "" });
      handleError(error);
    },
    false
  );

  useEffect(() => void executeGetProvinces(), [region]);

  const getFilteredCities = useCallback(async () => {
    if (!province) return [];

    const { data } = await getCities(province);
    if (!data.map((cityData) => cityData.name).includes(city)) setValues({ ...values, city: "" });
    return data;
  }, [province]);
  const {
    status: citiesStatus,
    value: cities,
    execute: executeGetCities,
  } = useAsync(
    getFilteredCities,
    [],
    (error) => {
      setValues({ ...values, city: "" });
      handleError(error);
    },
    false
  );

  useEffect(() => void executeGetCities(), [province]);

  return (
    <Fragment>
      <Grid item sm={12} width="100%">
        <FormikSelect id="country" name="country" label="País:" disabled>
          <MenuItem value={country}>{country}</MenuItem>
        </FormikSelect>
      </Grid>
      <Grid item sm={12} width="100%">
        <FormikSelect
          id="region"
          name="region"
          label="Región:"
          displayEmpty
          placeholder="Seleccionar"
          status={parseSelectStatus(regionsStatus)}
        >
          {regions.map((regionOption) => (
            <MenuItem key={regionOption.core_id} value={regionOption.name}>
              {regionOption.name}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikSelect
          id="province"
          name="province"
          label="Provincia:"
          displayEmpty
          placeholder="Seleccionar"
          status={parseSelectStatus(regionsStatus) || parseSelectStatus(provincesStatus)}
        >
          {provinces.map((provinceOption) => (
            <MenuItem key={provinceOption.core_id} value={provinceOption.name}>
              {provinceOption.name}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikSelect
          id="city"
          name="city"
          label="Ciudad:"
          displayEmpty
          placeholder="Seleccionar"
          status={
            parseSelectStatus(regionsStatus) || parseSelectStatus(provincesStatus) || parseSelectStatus(citiesStatus)
          }
        >
          {cities.map((cityOption) => (
            <MenuItem key={cityOption.core_id} value={cityOption.name}>
              {cityOption.name}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
    </Fragment>
  );
}

function parseSelectStatus(status: UseAsyncStatus) {
  let selectStatus: "pending" | "error" | undefined;
  if (status === "pending" || status === "idle") selectStatus = "pending";
  else if (status === "error") selectStatus = "error";
  return selectStatus;
}
