import { SVGProps } from "react";

export default function FrontId(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="203" height="162" viewBox="0 0 203 162" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.0105954 49.9941C0.386731 48.8745 0.709889 47.739 1.1496 46.6457C3.45938 40.9417 8.82593 37.4137 15.3368 37.3978C25.4501 37.3661 35.5633 37.3873 45.6713 37.3873C65.8978 37.3873 86.1191 37.3873 106.346 37.3978C107.13 37.3978 107.956 37.3873 108.692 37.6091C110.133 38.0369 110.838 39.0985 110.727 40.609C110.61 42.1248 109.726 43.0173 108.253 43.2603C107.543 43.3765 106.801 43.3184 106.075 43.3184C76.133 43.3184 46.1958 43.3184 16.2533 43.3184C9.46166 43.3184 5.95989 46.7883 5.95989 53.5063C5.95989 84.2815 5.95989 115.062 5.95989 145.837C5.95989 152.413 9.51463 155.935 16.1473 155.935C65.1244 155.935 114.096 155.935 163.073 155.935C169.833 155.935 173.329 152.449 173.329 145.71C173.329 127.848 173.329 109.992 173.329 92.1297C173.329 91.4696 173.266 90.7988 173.382 90.1598C173.663 88.6123 174.649 87.6563 176.222 87.6299C177.896 87.5983 178.924 88.57 179.226 90.2073C179.332 90.783 179.273 91.3903 179.273 91.9819C179.273 109.976 179.279 127.965 179.273 145.959C179.273 155.65 173.012 161.872 163.248 161.872C114.34 161.872 65.4316 161.872 16.5182 161.872C7.67634 161.872 2.67533 158.106 0.20661 149.603C0.169526 149.481 0.0688698 149.381 0 149.27C0 116.182 0 83.0932 0 50.0047L0.0105954 49.9941Z"
        fill="#547725"
      />
      <path
        d="M17.8796 84.9787C17.8796 79.2483 17.8584 73.5179 17.8849 67.7875C17.9273 58.9991 24.3269 52.5663 33.1475 52.5293C44.7071 52.4818 56.2666 52.4871 67.8261 52.5293C76.5938 52.561 83.0464 58.7456 83.1629 67.5128C83.3166 79.3011 83.3113 91.0894 83.1629 102.878C83.057 111.275 76.4031 117.634 67.9957 117.666C56.4361 117.708 44.8766 117.708 33.317 117.666C24.2527 117.629 17.9114 111.222 17.8902 102.17C17.8743 96.4395 17.8902 90.7091 17.8902 84.9787H17.8796ZM73.6642 109.833C76.0958 108.006 77.1924 105.656 77.2083 102.915C77.2719 91.0524 77.3196 79.1902 77.1977 67.328C77.1448 62.3159 73.4576 58.5555 68.5731 58.5079C56.5474 58.3917 44.5163 58.397 32.4853 58.5079C27.7598 58.5502 23.9137 62.3159 23.8713 66.916C23.7653 79.0423 23.8077 91.1686 23.8607 103.295C23.8713 106.105 25.3599 108.481 27.4207 109.569C29.8577 104.495 33.9563 99.7791 39.7166 95.4202C29.8894 86.6476 32.6442 74.833 38.3922 69.3455C45.1256 62.918 55.6203 62.7595 62.4119 69.1659C65.67 72.2345 67.503 76.0477 67.7943 80.5C68.1917 86.5631 65.7706 91.4749 61.1775 95.3938C68.8857 100.644 70.618 102.645 73.6536 109.844L73.6642 109.833ZM61.9033 81.6144C61.8715 75.4139 56.7063 70.3067 50.4921 70.3226C44.2409 70.3437 39.1922 75.4139 39.1763 81.683C39.1657 87.9416 44.1985 93.0224 50.4603 93.0699C56.7434 93.1174 61.9351 87.9257 61.9033 81.6144ZM67.6778 111.666C66.2686 104.256 57.7023 98.5521 48.9134 99.1066C41.788 99.5556 33.8203 106.031 33.4972 111.666H67.6778Z"
        fill="#547725"
      />
      <path
        d="M124.612 68.0475C123.494 66.125 122.657 64.4772 121.603 62.9878C121.248 62.4914 120.332 62.1587 119.67 62.1534C113.19 62.09 106.717 62.1164 100.238 62.1164C99.7079 62.1164 99.1781 62.127 98.6537 62.1164C96.524 62.0583 95.189 60.8911 95.2208 59.1271C95.2526 57.3578 96.5664 56.2064 98.7437 56.1959C104.163 56.1642 109.583 56.1853 115.002 56.1853C116.178 56.1853 117.354 56.1853 118.499 56.1853C117.969 52.9636 117.243 49.9426 117 46.8846C115.267 24.7922 129.354 5.83172 150.995 0.988613C175.322 -4.45131 200.11 13.2152 202.732 37.8586C205.243 61.4562 189.885 81.848 166.369 85.5978C153.156 87.7051 141.39 83.9606 131.287 75.2092C130.26 74.3166 129.28 73.9522 127.945 73.9575C118.493 74.005 109.042 73.9839 99.5861 73.9786C98.9239 73.9786 98.0762 74.1898 97.6471 73.8677C96.7306 73.1705 95.4856 72.2463 95.3903 71.3114C95.2949 70.3872 96.3015 69.183 97.1173 68.4014C97.5729 67.9683 98.6166 68.0633 99.3953 68.0633C107.061 68.0422 114.732 68.0528 122.398 68.0528C123.044 68.0528 123.691 68.0528 124.618 68.0528L124.612 68.0475ZM197.132 43.1242C197.153 22.6902 180.439 5.98489 159.958 5.95848C139.499 5.93207 122.71 22.6374 122.684 43.045C122.652 63.4368 139.414 80.1896 159.879 80.216C180.349 80.2424 197.116 63.5477 197.132 43.1242Z"
        fill="#547725"
      />
      <path
        d="M89.7536 129.543C67.4186 129.543 45.0783 129.543 22.7433 129.543C22.15 129.543 21.5513 129.575 20.958 129.528C19.1038 129.38 17.8906 128.181 17.8906 126.544C17.8906 124.906 19.1303 123.723 20.9792 123.623C21.5725 123.591 22.1659 123.612 22.7645 123.612C67.3709 123.612 111.983 123.612 156.589 123.612C157.119 123.612 157.649 123.597 158.173 123.618C160.165 123.697 161.447 124.859 161.437 126.565C161.426 128.271 160.149 129.427 158.147 129.533C157.617 129.559 157.087 129.538 156.563 129.538C134.291 129.538 112.02 129.538 89.7483 129.538L89.7536 129.543Z"
        fill="#547725"
      />
      <path
        d="M89.6687 135.475C112.004 135.475 134.344 135.475 156.679 135.475C157.341 135.475 158.003 135.438 158.66 135.506C160.356 135.67 161.336 136.663 161.415 138.321C161.489 139.911 160.578 140.925 159.047 141.29C158.417 141.443 157.733 141.401 157.076 141.401C112.136 141.401 67.2012 141.401 22.261 141.401C21.6041 141.401 20.9207 141.438 20.2902 141.29C18.7592 140.925 17.848 139.911 17.9222 138.321C18.0016 136.663 18.9817 135.67 20.677 135.506C21.3339 135.443 21.9961 135.475 22.6583 135.475C44.9933 135.475 67.3336 135.475 89.6687 135.475Z"
        fill="#547725"
      />
      <path
        d="M128.326 99.8774C137.904 99.8774 147.483 99.8774 157.066 99.8774C157.728 99.8774 158.576 99.6608 159.005 99.983C159.927 100.675 161.177 101.594 161.278 102.523C161.373 103.448 160.393 104.689 159.561 105.439C159.047 105.904 157.935 105.793 157.087 105.793C137.931 105.808 118.769 105.808 99.6127 105.793C98.8286 105.793 98.0075 105.782 97.2711 105.555C95.8354 105.111 95.1203 104.007 95.2739 102.529C95.4222 101.066 96.3122 100.131 97.8115 99.9196C98.4631 99.8298 99.1306 99.8668 99.7928 99.8668C109.307 99.8668 118.822 99.8668 128.331 99.8668L128.326 99.8774Z"
        fill="#547725"
      />
      <path
        d="M128.421 117.676C118.774 117.676 109.132 117.681 99.485 117.665C98.7062 117.665 97.6679 117.781 97.2017 117.354C96.37 116.593 95.4747 115.51 95.3422 114.465C95.1621 113.049 96.2534 112.056 97.7368 111.808C98.3831 111.702 99.0559 111.75 99.7128 111.75C118.806 111.75 137.898 111.75 156.991 111.75C157.187 111.75 157.389 111.75 157.585 111.75C160.059 111.776 161.452 112.843 161.441 114.708C161.425 116.582 160.032 117.676 157.558 117.676C147.848 117.686 138.137 117.676 128.421 117.676Z"
        fill="#547725"
      />
      <path
        d="M153.304 59.7932C151.789 58.9745 150.062 58.3936 148.807 57.2792C146.142 54.9289 143.705 52.3199 141.21 49.7795C139.705 48.2426 139.626 46.505 140.908 45.2586C142.206 43.9963 143.869 44.1072 145.453 45.6494C147.625 47.762 149.834 49.8534 151.874 52.0875C153.135 53.466 153.94 53.5452 155.312 52.1403C161.394 45.924 167.597 39.8186 173.764 33.6816C174.23 33.2168 174.675 32.7098 175.221 32.3559C176.471 31.5531 177.769 31.6271 178.86 32.6306C179.962 33.6446 180.073 34.9386 179.385 36.2325C179.115 36.7395 178.659 37.1621 178.246 37.5793C171.761 44.0544 165.266 50.5136 158.793 56.994C157.33 58.457 155.678 59.4076 153.31 59.7826L153.304 59.7932Z"
        fill="#547725"
      />
    </svg>
  );
}
