import { Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { SaveSavingsRequestPayload } from "Services/api/register/interfaces";
import { saveSavingsData } from "Services/api/register/register";
import { SavingsDepositLimitsResponse, SavingsPlanPoolResponse } from "Services/api/request/interfaces";
import { getSavingsAccountDepositLimits, getSavingsPlanPool } from "Services/api/request/request";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import FormikForm from "Shared/FormikForm/FormikForm";
import { Query } from "Shared/Query/Query";
import {
  SavingsAccountForm,
  SavingsAccountType,
  useSavingsAccountFormSchema,
} from "Shared/SavingsAccountForm/SavingsAccountForm";
import { SavingsPlanForm, useSavingsPlanFormSchema } from "Shared/SavingsPlanForm/SavingsPlanForm";
import { requiredValidation } from "Utils/validations";
import { Formik, useField, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { ActionButtons } from "../ActionButtons/ActionButtons";
import { useRegistrationContext } from "../Registration";

import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from "@mui/material";

type SavingsRequestType = SavingsAccountType | "planned";

export function Savings() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getSavingsPlanPool.name],
    queryFn: async () => {
      try {
        const response = await getSavingsPlanPool();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const limitsResult = useQuery({
    queryKey: [getSavingsAccountDepositLimits.name, "reg"],
    queryFn: async () => {
      try {
        const response = await getSavingsAccountDepositLimits();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <SplitFormContainer
      title="¿Deseas solicitar un producto de ahorros adicional?"
      sideInfoTop={<AccessInfo />}
      form={
        <Query
          result={limitsResult}
          OnLoading={() => (
            <Stack width={"100%"} spacing={2}>
              <Stack spacing={1} width="100%">
                <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
                <Skeleton variant="rectangular" width={"100%"} height={56} />
              </Stack>
              <Skeleton variant="rectangular" width={"100%"} height={37} />
            </Stack>
          )}
          onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
          onSuccess={(limitsResult) => (
            <Query
              result={result}
              OnLoading={() => (
                <Stack width={"100%"} spacing={2}>
                  <Stack spacing={1} width="100%">
                    <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
                    <Skeleton variant="rectangular" width={"100%"} height={56} />
                  </Stack>
                  <Skeleton variant="rectangular" width={"100%"} height={37} />
                </Stack>
              )}
              onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
              onSuccess={(result) => <SavingsForm result={result} limitsResult={limitsResult} />}
            />
          )}
        />
      }
    />
  );
}

interface SavingsFormProps {
  result: SavingsPlanPoolResponse["data"];
  limitsResult: SavingsDepositLimitsResponse["data"];
}

function SavingsForm({ result, limitsResult }: SavingsFormProps) {
  const { minMonthlyDeposit } = limitsResult;
  const { planDepositMin, planGoalMin, planTermMin } = result;

  const [{ id }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useCreateValidationSchema(result, limitsResult);
  const initialValues: Partial<SaveSavingsRequestPayload> = {
    id,
    type: "",
    childName: "",
    childAge: "",
    birthCertificate: undefined,
    monthlyDeposit: minMonthlyDeposit,
    typeOfPlan: "",
    planDeposit: planDepositMin,
    planGoal: planGoalMin,
    planTerm: planTermMin,
    periodicity: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const requestData = new FormData();

          for (const key in values) {
            const el = values[key as keyof SaveSavingsRequestPayload];
            if (el) requestData.append(key, typeof el === "number" || typeof el === "boolean" ? el.toString() : el);
          }
          const { data } = await saveSavingsData(requestData);
          setRegContext(data);
          setSubmitting(false);
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        }
      }}
      validationSchema={validationSchema}
    >
      <FormikForm width="100%">
        <SavingsTypeSelect />
        <SavingsAccount result={limitsResult} />
        <SavingsPlan result={result} />
        <ActionButtons />
      </FormikForm>
    </Formik>
  );
}

function SavingsTypeSelect() {
  const name = "type";
  const [{ value }, , { setValue }] = useField<string>(name);
  const { isSubmitting, errors: formikErrors, touched } = useFormikContext<{ [k: string]: string }>();
  const formikError = Boolean(touched[name]) && Boolean(formikErrors[name]);

  const checkLabelStyle = {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "25px",
    letterSpacing: "0.02em",
    color: "#383838",
  };

  return (
    <FormControl component="fieldset" variant="standard" disabled={isSubmitting}>
      <RadioGroup
        value={value}
        id={name}
        name={name}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      >
        <FormControlLabel
          value="youth"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>Cuenta de ahorro infantil</Typography>}
        />
        <FormControlLabel
          value="planned"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>Plan de ahorros</Typography>}
        />
        <FormControlLabel
          value="in_sight"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>No deseo un producto de ahorros adicional</Typography>}
        />
      </RadioGroup>
      {formikError ? <FormHelperText error> {formikErrors[name]}</FormHelperText> : null}
    </FormControl>
  );
}

function useCreateValidationSchema(
  result: SavingsFormProps["result"],
  { minMonthlyDeposit, maxMonthlyDeposit }: SavingsFormProps["limitsResult"]
) {
  const savingsAccountFormSchema = useSavingsAccountFormSchema({
    minMonthlyDeposit,
    maxMonthlyDeposit,
  });
  const savingsPlanFormSchema = useSavingsPlanFormSchema(result);

  return Yup.object({
    id: requiredValidation,
  })
    .concat(savingsAccountFormSchema)
    .concat(savingsPlanFormSchema);
}

function SavingsAccount({
  result: { minMonthlyDeposit, maxMonthlyDeposit },
}: {
  result: SavingsFormProps["limitsResult"];
}) {
  const [{ value }] = useField<SavingsRequestType>("type");

  if (value !== "youth") return null;

  return (
    <SavingsAccountForm
      minMonthlyDeposit={minMonthlyDeposit}
      maxMonthlyDeposit={maxMonthlyDeposit}
      type={value}
      isRegistration
    />
  );
}

function SavingsPlan({ result }: { result: SavingsFormProps["result"] }) {
  const [{ value: type }] = useField<SavingsRequestType>("type");

  if (type !== "planned") return null;

  return <SavingsPlanForm options={result} />;
}
