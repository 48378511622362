import { Grid, Skeleton, Stack } from "@mui/material";

export function Loading() {
  return (
    <Stack justifyContent="center" alignItems="start" sx={{ px: "48px", pt: "25px" }}>
      <Skeleton variant="text" width={200} height={50} />

      <Stack spacing={2} width="100%">
        <Grid width="100%" container gap="16px">
          <Grid item sm width="100%">
            <Stack width="100%" spacing={1}>
              <Skeleton variant="text" width={120} height={25} />
              <Skeleton variant="rectangular" width="100%" height={56} />
            </Stack>
          </Grid>
          <Grid item sm width="100%">
            <Stack width="100%" spacing={1}>
              <Skeleton variant="text" width={120} height={25} />
              <Skeleton variant="rectangular" width="100%" height={56} />
            </Stack>
          </Grid>
        </Grid>
        <Stack width="100%" spacing={1}>
          <Skeleton variant="text" width={120} height={25} />
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Stack>
        <Stack width="100%" spacing={1}>
          <Skeleton variant="text" width={120} height={25} />
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Stack>

        <Skeleton variant="rectangular" width="100%" height={38} />
      </Stack>
    </Stack>
  );
}
