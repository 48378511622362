import { Button, FormHelperText, Box, Stack, styled, Typography } from "@mui/material";
import { useState } from "react";
import biometricDemo from "../../../../../public/verificacion_biometrica_demo.mp4";
import VideoCapture from "./VideoCapture";
import { FormProps } from "./interfaces";
import getVideoTypeByBrowser from "Utils/getVideoTypeByBrowser";

const Title = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "17px",
  color: "#7C7C7C",
}));

const videoErrMsg = "Tu navegador no soporta reproducción de videos.";

const videoMimeType: "video/mp4" | "video/webm" = `video/${getVideoTypeByBrowser()}`;

export default function BiometricVideo(props: FormProps) {
  const { onSubmit } = props;
  const [status, setStatus] = useState<"start" | "record" | "review">("start");
  const [video, setVideo] = useState<
    | {
        blob: Blob;
        url: string;
      }
    | undefined
  >();
  const [error, setError] = useState("");

  return (
    <Stack sx={{ width: "100%" }}>
      {status === "start" ? (
        <Stack spacing={2} sx={{ width: "100%" }} alignItems="center" justifyContent="center">
          <Title>Haz un video como se muestra a continuación.</Title>
          <Title>Haz circulos con la punta de tu nariz mientras miras a la cámara.</Title>
          <Title>Asegurate de estar en un lugar con buena luz.</Title>
          <Box sx={{ width: "240px", height: "240px", overflow: "hidden", flexShrink: 0, borderRadius: "50%" }}>
            {
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video autoPlay playsInline loop style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                <source src={biometricDemo} type="video/mp4" />
                {videoErrMsg}
              </video>
            }
          </Box>

          <Button fullWidth variant="contained" onClick={() => setStatus("record")}>
            Empezar
          </Button>
        </Stack>
      ) : status === "record" ? (
        <VideoCapture
          onRecord={(blob) => {
            if (blob.size === 0) return;
            setVideo({
              blob,
              url: window.URL.createObjectURL(blob),
            });
            setStatus("review");
          }}
          onClose={() => setStatus("start")}
        />
      ) : (
        <Stack spacing={2} sx={{ width: "100%" }}>
          {video ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <Stack spacing={2}>
              <Title>Comprueba si el video es similar a la demostración</Title>
              <Stack spacing={2} direction="row" flexWrap="wrap" justifyContent="space-around">
                <Box
                  sx={{
                    width: "240px",
                    height: "240px",
                    overflow: "hidden",
                    flexShrink: 0,
                    borderRadius: "50%",
                    marginBottom: "10px",
                  }}
                >
                  {
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video autoPlay playsInline loop style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                      <source src={video.url} type={videoMimeType} />
                      {videoErrMsg}
                    </video>
                  }
                </Box>
                <Box
                  sx={{
                    width: "240px",
                    height: "240px",
                    overflow: "hidden",
                    flexShrink: 0,
                    borderRadius: "50%",
                  }}
                >
                  {
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video autoPlay playsInline loop style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                      <source src={biometricDemo} type="video/mp4" />
                      {videoErrMsg}
                    </video>
                  }
                </Box>
              </Stack>
            </Stack>
          ) : null}

          {error ? (
            <FormHelperText error>
              <strong>Error de validación:</strong> {error}, intenta grabar el video nuevamente.
            </FormHelperText>
          ) : null}
          <Button
            fullWidth
            variant={error ? "contained" : "text"}
            onClick={() => {
              setStatus("record");
              setError("");
            }}
          >
            Volver a grabar
          </Button>
          {!error ? (
            <Button fullWidth variant="contained" onClick={() => void OnClick()}>
              Listo
            </Button>
          ) : null}
        </Stack>
      )}
    </Stack>
  );

  async function OnClick() {
    if (!video) return;
    const formData = new FormData();

    formData.append("video", new File([video.blob], `video.${getVideoTypeByBrowser()}`, { type: videoMimeType }));
    window.URL.revokeObjectURL(video.url);

    const isWebkit = videoMimeType === "video/mp4";

    if (isWebkit) {
      formData.append("safari", isWebkit.toString());
    }

    const res = await onSubmit(formData);

    if (res && "errorKey" in res) setError(res.message);
  }
}
