import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, ButtonProps, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { back } from "Services/api/register/register";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { useSnackbar } from "notistack";
import { useRegistrationContext } from "../Registration";

export function ActionButtons({ onBack, end = false }: { onBack?: () => void; end?: boolean }) {
  const { goBack, goBackPending } = useGoBack();

  const submitProps: ButtonProps = {
    fullWidth: true,
    variant: "contained",
    endIcon: <ChevronRight />,
    sx: { justifyContent: "flex-end" },
  };

  if (goBackPending) submitProps["disabled"] = true;

  return (
    <Stack direction="row" width="100%" spacing={2}>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<ChevronLeft />}
        sx={{ justifyContent: "flex-start" }}
        onClick={onBack ? onBack : () => void goBack()}
        disabled={goBackPending}
      >
        ATRAS
      </Button>
      <FormikSubmitButton {...submitProps}>{end ? "FINALIZAR" : "SIGUIENTE"}</FormikSubmitButton>
    </Stack>
  );
}

export function useGoBack() {
  const [{ id }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: goBack, isPending: goBackPending } = useMutation({
    mutationFn: async () => {
      const { data } = await back(id);
      setRegContext(data);
      return data;
    },
    onError: (error) => {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    },
  });

  return { goBack, goBackPending };
}
