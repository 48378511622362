import { SVGProps } from "react";

export default function BackId(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="203" height="162" viewBox="0 0 202 162" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M113.713 69.1963C113.713 66.359 113.687 63.5164 113.719 60.6791C113.766 56.3236 120.482 53.1355 129.748 53.1198C141.888 53.0936 154.028 53.0988 166.168 53.1198C175.376 53.1355 182.155 56.1979 182.276 60.5429C182.433 66.3852 182.433 72.2274 182.276 78.0696C182.16 82.2314 175.172 85.3828 166.346 85.3985C154.206 85.4195 142.066 85.4195 129.926 85.3985C120.409 85.3828 113.745 82.2052 113.724 77.7189C113.708 74.8815 113.724 72.0389 113.724 69.2016L113.713 69.1963Z"
        fill="#547725"
      />
      <path
        d="M36.2992 58.5068C34.802 57.6954 33.0954 57.1195 31.8547 56.015C29.2215 53.6854 26.8134 51.0993 24.3478 48.5813C22.861 47.0579 22.7825 45.3356 24.0494 44.1002C25.3319 42.849 26.9757 42.959 28.541 44.4876C30.6873 46.5816 32.8703 48.6546 34.8857 50.869C36.1317 52.2353 36.9274 52.3138 38.2832 50.9213C44.293 44.7598 50.4231 38.7082 56.5166 32.6252C56.9773 32.1645 57.417 31.6619 57.9562 31.3112C59.1917 30.5155 60.4742 30.5888 61.5526 31.5834C62.6415 32.5885 62.7514 33.8711 62.0709 35.1536C61.8039 35.6562 61.3537 36.075 60.9454 36.4886C54.5378 42.9066 48.1197 49.309 41.7226 55.7323C40.2778 57.1824 38.6444 58.1246 36.3044 58.4963L36.2992 58.5068Z"
        fill="#547725"
      />
      <path
        d="M201.76 50.5497C201.389 49.4399 201.069 48.3144 200.63 47.2307C198.337 41.577 193.002 38.08 186.527 38.0643C176.476 38.0329 166.424 38.0538 156.373 38.0538C136.271 38.0538 116.169 38.0538 96.0665 38.0643C95.2865 38.0643 94.4699 38.0538 93.7317 38.2737C92.3026 38.6977 91.5959 39.75 91.711 41.2472C91.8262 42.7496 92.7057 43.6343 94.1662 43.8751C94.873 43.9903 95.6058 43.9327 96.3283 43.9327C126.089 43.9327 155.85 43.9327 185.611 43.9327C192.358 43.9327 195.845 47.3721 195.845 54.0309C195.845 84.535 195.845 115.044 195.845 145.549C195.845 152.066 192.311 155.558 185.715 155.558C137.035 155.558 88.3554 155.558 39.6755 155.558C32.9538 155.558 29.4778 152.103 29.4778 145.423C29.4778 127.718 29.4778 110.019 29.4778 92.3142C29.4778 91.6598 29.5406 90.995 29.4255 90.3616C29.148 88.8277 28.1691 87.8802 26.5986 87.854C24.9339 87.8226 23.9131 88.7858 23.6147 90.4087C23.51 90.9793 23.5676 91.5813 23.5676 92.1676C23.5676 110.003 23.5623 127.833 23.5676 145.669C23.5676 155.275 29.7919 161.442 39.4975 161.442C88.1146 161.442 136.726 161.442 185.344 161.442C194.133 161.442 199.101 157.709 201.556 149.281C201.593 149.161 201.692 149.061 201.76 148.951C201.76 116.154 201.76 83.3572 201.76 50.5602V50.5497Z"
        fill="#547725"
      />
      <path
        d="M77.9117 67.4534C79.0215 65.5479 79.8538 63.9146 80.9061 62.4383C81.2568 61.9463 82.1677 61.6165 82.8273 61.6112C89.2663 61.5484 95.7053 61.5746 102.144 61.5746C102.668 61.5746 103.197 61.585 103.72 61.5746C105.835 61.517 107.165 60.3601 107.133 58.6116C107.102 56.8579 105.793 55.7167 103.631 55.7062C98.2443 55.6748 92.8575 55.6957 87.4707 55.6957C86.3033 55.6957 85.1307 55.6957 83.9947 55.6957C84.5234 52.5024 85.2458 49.508 85.4814 46.4769C87.209 24.5739 73.2002 5.78036 51.695 0.979906C27.5147 -4.4121 2.87375 13.0988 0.266737 37.5252C-2.23034 60.915 13.0348 81.1272 36.4141 84.844C49.5486 86.9327 61.2436 83.2211 71.2842 74.5468C72.3103 73.6621 73.2787 73.3009 74.6084 73.3061C84.0052 73.3532 93.3967 73.3323 102.793 73.3271C103.448 73.3271 104.296 73.5365 104.725 73.2171C105.641 72.5261 106.877 71.61 106.971 70.6834C107.065 69.7673 106.06 68.5737 105.254 67.7989C104.804 67.3697 103.762 67.4639 102.987 67.4639C95.365 67.443 87.7429 67.4534 80.1208 67.4534C79.4769 67.4534 78.8383 67.4534 77.9117 67.4534ZM5.8315 42.7497C5.81056 22.4956 22.4211 5.93741 42.7799 5.91124C63.1124 5.88506 79.8015 22.4432 79.8329 42.6711C79.8643 62.8833 63.2014 79.4886 42.8636 79.5148C22.5205 79.541 5.85244 62.9932 5.8315 42.7497Z"
        fill="#547725"
      />
      <path
        d="M112.562 129.399C134.764 129.399 156.965 129.399 179.172 129.399C179.763 129.399 180.355 129.43 180.946 129.383C182.794 129.236 183.998 128.048 183.993 126.425C183.993 124.802 182.758 123.63 180.92 123.53C180.329 123.499 179.737 123.52 179.146 123.52C134.805 123.52 90.4653 123.52 46.1252 123.52C45.6017 123.52 45.0729 123.504 44.5494 123.525C42.5706 123.603 41.2933 124.755 41.3038 126.446C41.3142 128.137 42.5811 129.283 44.5756 129.388C45.0991 129.414 45.6279 129.393 46.1513 129.393C68.29 129.393 90.4234 129.393 112.562 129.393V129.399Z"
        fill="#547725"
      />
      <path
        d="M112.646 135.277C90.4442 135.277 68.2427 135.277 46.036 135.277C45.3817 135.277 44.7168 135.241 44.0677 135.309C42.382 135.471 41.4083 136.455 41.3298 138.099C41.2565 139.675 42.1622 140.68 43.6855 141.041C44.3137 141.193 44.9891 141.151 45.6434 141.151C90.3133 141.151 134.978 141.151 179.648 141.151C180.302 141.151 180.983 141.188 181.606 141.041C183.124 140.68 184.035 139.675 183.962 138.099C183.883 136.455 182.909 135.471 181.224 135.309C180.575 135.246 179.91 135.277 179.255 135.277C157.054 135.277 134.852 135.277 112.646 135.277Z"
        fill="#547725"
      />
      <path
        d="M111.97 99.9938C91.5279 99.9938 71.0906 99.9938 50.6481 99.9938C49.2399 99.9938 47.4286 99.7792 46.5072 100.098C44.5389 100.784 41.8743 101.695 41.6649 102.617C41.4555 103.533 43.5547 104.763 45.3241 105.506C46.4235 105.967 48.7949 105.857 50.601 105.857C91.4808 105.873 132.361 105.873 173.246 105.857C174.916 105.857 176.674 105.846 178.245 105.621C181.313 105.182 182.831 104.088 182.512 102.622C182.192 101.172 180.297 100.245 177.099 100.036C175.711 99.9467 174.287 99.9833 172.874 99.9833C152.573 99.9833 132.277 99.9833 111.976 99.9833L111.97 99.9938Z"
        fill="#547725"
      />
      <path
        d="M111.761 117.636C132.345 117.636 152.924 117.641 173.508 117.625C175.172 117.625 177.387 117.74 178.387 117.316C180.167 116.562 182.072 115.489 182.355 114.453C182.742 113.05 180.418 112.066 177.245 111.819C175.869 111.715 174.434 111.762 173.026 111.762C132.288 111.762 91.5439 111.762 50.8055 111.762C50.3814 111.762 49.9574 111.762 49.5386 111.762C44.2618 111.788 41.2831 112.846 41.3093 114.693C41.3354 116.552 44.3194 117.636 49.6014 117.636C70.3214 117.646 91.0466 117.636 111.767 117.636H111.761Z"
        fill="#547725"
      />
    </svg>
  );
}
