import { Stack } from "@mui/material";
import SideInfo from "Shared/SideInfo/SideInfo";

export default function ForgotPasswordInfo(): JSX.Element {
  const steps = ["Introduce las preguntas secretas.", "Ingresa tu nueva contraseña.", "Accede al sistema."];
  return (
    <Stack spacing={2} direction="column" alignItems="center" width="100%" height="100%">
      <SideInfo title={"Restaurar Acceso"} description={"Es fácil, aquí te decimos como empezar."} steps={steps} />
    </Stack>
  );
}
