import { Clear, Edit } from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { Dispatch, Fragment, useState } from "react";
import { Contact, Profile } from "Services/api/profile/interfaces";
import { getContact, getProfile } from "Services/api/profile/profile";
import { Loading } from "Shared/Loading/Loading";
import { Query } from "Shared/Query/Query";
import { SectionTitle } from "Shared/styled";
import ValueElement from "Shared/ValueElement/ValueElement";
import { AddressChange } from "./AddressChange";
import { EmailChange } from "./EmailChange";
import { EditContentProps } from "./interfaces";
import { PasswordChange } from "./PasswordChange";
import { PhoneChange } from "./PhoneChange";
import { QuestionsChange } from "./QuestionsChange";

export default function Associate() {
  const queryClient = useQueryClient();
  const handleError = useHandleError();

  const result = useQuery({
    queryKey: [getProfile.name],
    queryFn: async () => {
      try {
        const response = await getProfile();
        queryClient.setQueryData<Contact>([getContact.name], response.data);
        return response.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    },
  });

  return (
    <Query
      result={result}
      OnLoading={() => <Loading />}
      onError={() => <>Ha ocurrido un error al intentar obtener el teléfono</>}
      onSuccess={(profile) => <AssociateInfo profile={profile} />}
    />
  );
}

type View = "" | "password" | "email" | "phone" | "address" | "questions";

function AssociateInfo({ profile }: { profile: Profile }) {
  const [view, setView] = useState<View>("");

  return (
    <Fragment>
      <ProfileInfo profile={profile} setView={setView} />
      <EditDialog profile={profile} view={view} onClose={() => setView("")}></EditDialog>
    </Fragment>
  );
}

function ProfileInfo({ profile, setView }: { profile: Profile; setView: Dispatch<View> }) {
  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Stack
          spacing={2}
          component={Card}
          style={{ padding: "30px", backgroundColor: "#F6F6F6", height: "100%" }}
          elevation={0}
        >
          <SectionTitle variant="h1">Datos del socio</SectionTitle>

          <ValueField title="Documento de identidad" value={profile.id} />

          <EditField
            title="Correo electronico"
            value={profile.email}
            onClick={() => setView("email")}
            testId="edit-email"
          />

          <EditField
            title="Telefono movil"
            value={profile.phone}
            onClick={() => setView("phone")}
            testId="edit-phone"
          />

          <EditField
            title="Dirección"
            value={`${profile.address.reference}, ${profile.address.sector}, ${profile.address.city}, ${profile.address.country}`}
            onClick={() => setView("address")}
            testId="edit-address"
          />
        </Stack>
      </Grid>
      <Grid item sm={6}>
        <Stack
          spacing={2}
          component={Card}
          style={{ padding: "30px", backgroundColor: "#F6F6F6", height: "100%" }}
          elevation={0}
        >
          <SectionTitle variant="h1">Datos de acceso y seguridad</SectionTitle>

          <EditField title="Contraseña" value="••••" onClick={() => setView("password")} testId="edit-password" />

          <EditField
            title="Preguntas de seguridad"
            value={
              <List sx={{ padding: 0 }}>
                {profile.securityQuestions.map(({ question }, index) => (
                  <ListItem key={question} sx={{ padding: 0 }}>
                    <ListItemText>
                      {index + 1}. {question}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            }
            onClick={() => setView("questions")}
            testId="edit-questions"
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

function ValueField({ title, value }: { title: React.ReactNode; value: React.ReactNode }) {
  return <ValueElement title={title} value={value} stackProps={{ spacing: 2, pr: "15px" }} />;
}

function EditField({
  title,
  value,
  onClick,
  testId,
}: {
  title: React.ReactNode;
  value: React.ReactNode;
  onClick: () => void;
  testId: string;
}) {
  return (
    <ButtonBase sx={{ textAlign: "left", justifyContent: "flex-start" }} onClick={onClick} data-testid={testId}>
      <ValueField
        title={
          <Stack direction={"row"} alignItems="center" gap="5px">
            {title}
            <Edit fontSize="small" />
          </Stack>
        }
        value={value}
      />
    </ButtonBase>
  );
}

export interface Props {
  view: View;
  onClose: () => void;
  profile: Profile;
}

type editTuple = [string, (props: EditContentProps) => JSX.Element];
const editContentMap: {
  password: editTuple;
  email: editTuple;
  phone: editTuple;
  address: editTuple;
  questions: editTuple;
} = {
  password: ["Cambiar contraseña", PasswordChange],
  email: ["Cambiar correo electronico", EmailChange],
  phone: ["Cambiar telefono movil", PhoneChange],
  address: ["Cambiar dirección", AddressChange],
  questions: ["Cambiar preguntas de seguridad", QuestionsChange],
};

function EditDialog(props: Props) {
  const queryClient = useQueryClient();
  const { profile, view, onClose } = props;
  if (!view) return null;
  const [title, EditContent] = editContentMap[view];
  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <Stack component={DialogTitle} direction={"row"} justifyContent="space-between" alignItems={"center"}>
        {title}
        <IconButton onClick={onClose} sx={{ color: "#000" }}>
          <Clear />
        </IconButton>
      </Stack>
      <DialogContent>
        <EditContent
          closeDialog={onClose}
          profile={profile}
          setProfile={(profile) => {
            queryClient.setQueryData<Profile>([getProfile.name], profile);
            queryClient.setQueryData<Contact>([getContact.name], profile);
          }}
        />
        <Button onClick={onClose} sx={{ mt: "25px" }} fullWidth>
          Cancelar
        </Button>
      </DialogContent>
    </Dialog>
  );
}
