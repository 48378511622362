import { useState, useCallback } from "react";

type UseHasCameraType = boolean | null;

export const useHasCamera = (initialState = null): [UseHasCameraType, () => void] => {
  const [state, setState] = useState<UseHasCameraType>(initialState);

  const start = useCallback(async () => {
    const md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) {
      setState(false);
      return;
    }

    const devices = await md.enumerateDevices();
    setState(devices.some((device) => "videoinput" === device.kind));
  }, []);

  return [state, start];
};
