import { SVGProps } from "react";

export default function BiometricStep1(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="50" height="50" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="30.5192" cy="30.5192" r="30.5192" fill="#547725" />
      <path
        d="M25.1882 26.6644H30.3145V41.4972H34.5611V19.9608H31.0728C30.9818 21.5684 29.8898 23.6917 25.7948 23.6917H25.1882V26.6644Z"
        fill="white"
      />
    </svg>
  );
}
