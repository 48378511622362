import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { saveName } from "Services/api/register/register";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { requiredValidation } from "Utils/validations";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { ActionButtons } from "../ActionButtons/ActionButtons";
import { useRegistrationContext } from "../Registration";

export function Name() {
  const [{ id }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <SplitFormContainer
      title="¿Cuál es tu nombre?"
      sideInfoTop={<AccessInfo />}
      form={
        <Formik
          initialValues={{ name: "" }}
          onSubmit={async ({ name }, { setSubmitting }) => {
            try {
              const response = await saveName(id, name);
              setRegContext(response.data);
              setSubmitting(false);
            } catch (error) {
              enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
            }
          }}
          validationSchema={Yup.object({
            name: requiredValidation,
          })}
        >
          <FormikForm width="100%">
            <FormikTextField name="name" id="name" type="name" fullWidth label="Nombre:" variant="outlined" required />
            <ActionButtons />
          </FormikForm>
        </Formik>
      }
    />
  );
}
