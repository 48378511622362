import { Alert, FormHelperText, Link, Typography } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { OtpValidationData } from "Services/api/otp/interfaces";
import { validateEmail } from "Services/api/otp/otp";
import { confirmEmail, saveEmail } from "Services/api/register/register";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { emailValidation } from "Utils/validations";
import * as Yup from "yup";
import { ActionButtons } from "../ActionButtons/ActionButtons";
import OtpValidation, { InputFormProps, ValidationFormProps } from "../OtpValidation/OtpValidation";
import OtpValidationForm from "../OtpValidation/OtpValidationForm";
import { useRegistrationContext } from "../Registration";

export default function EmailValidation(): JSX.Element {
  const [{ name }] = useRegistrationContext();
  const msg = name ? `${name}, por favor introduce tu correo electrónico.` : "Favor introducir tu correo electrónico.";

  return <OtpValidation title={msg} InputForm={EmailInputForm} ValidationForm={EmailValidationForm} />;
}

const emailInputSchema = Yup.object({
  email: emailValidation,
});

function EmailInputForm({ setStatus, setTo }: InputFormProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [{ id, isAffiliatedClient, partialEmail }] = useRegistrationContext();
  const [emailError, setEmailError] = useState("");

  return (
    <Formik initialValues={{ email: "" }} validationSchema={emailInputSchema} onSubmit={onEmailInputSubmit}>
      <FormikForm width="100%">
        {isAffiliatedClient ? (
          <Typography>
            Introduzca el email <strong>{partialEmail}</strong> para confirmar que todavía se le puede contactar por
            esta via. Si no reconoce o no recuerda este email puede{" "}
            <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/contactanos/">
              <strong>hacer click aqui para contactarnos.</strong>
            </Link>
          </Typography>
        ) : null}
        <FormikTextField
          name="email"
          id="email"
          type="email"
          fullWidth
          label="Correo electrónico:"
          variant="outlined"
          required
          help={
            <Alert severity="info" color="success" variant="outlined">
              Este correo se utilizará para acceder a todos los servicios y productos de COOPBARCELONA, así como para
              verificar su validez.
            </Alert>
          }
        />
        {emailError ? <FormHelperText error>{emailError}</FormHelperText> : null}
        <ActionButtons />
      </FormikForm>
    </Formik>
  );

  async function onEmailInputSubmit(
    { email }: { email: OtpValidationData["to"] },
    { setSubmitting }: FormikHelpers<{ email: OtpValidationData["to"] }>
  ) {
    setEmailError("");
    try {
      if (isAffiliatedClient) {
        const { status, data } = await confirmEmail(id, email);

        if (status === "success") {
          setStatus("validation");
          setTo(email);
          enqueueSnackbar(data, { variant: "success" });
        } else {
          setEmailError(data);
          enqueueSnackbar(data, { variant: "error" });
        }
      } else {
        await validateEmail(email);
        setStatus("validation");
        setTo(email);
      }
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }

    setSubmitting(false);
  }
}

function EmailValidationForm({ to, setStatus }: ValidationFormProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [{ id }, setRegContext] = useRegistrationContext();

  return (
    <OtpValidationForm
      channel="email"
      channelDisplay="correo"
      to={to}
      expiryTime={600}
      onSubmit={onEmailValidationSubmit}
      onBack={() => setStatus("input")}
    />
  );

  async function onEmailValidationSubmit(
    { code }: { code: OtpValidationData["code"] },
    { setSubmitting }: FormikHelpers<{ code: string }>
  ) {
    try {
      const response = await saveEmail({
        id,
        to,
        code,
      });

      if (response.status === "success") {
        enqueueSnackbar("Correo validado", { variant: "success" });
        setRegContext({ status: response.data.status });
      } else enqueueSnackbar(response.data, { variant: "error" });
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }

    setSubmitting(false);
  }
}
