import { Card, Stack } from "@mui/material";
import { useAsync } from "Hooks/useAsync";
import { CertificateSummary } from "Pages/Products/CertificateSummary";
import { LoanSummary } from "Pages/Products/LoanSummary";
import { ProductTypeSummary } from "Pages/Products/ProductTypeSummary";
import { SavingAccountSummary } from "Pages/Products/SavingAccountSummary";
import { getGeneralBalance } from "Services/api/finance/finance";
import { GeneralBalanceData } from "Services/api/finance/interfaces";
import { Loading } from "Shared/Loading/Loading";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

const initialValues: GeneralBalanceData = {
  savingAccount: {
    total: "0.00",
    items: [],
  },
  certificate: {
    total: "0.00",
    items: [],
  },
  loan: {
    total: "0.00",
    items: [],
  },
};

export function Products(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [generalBalance, setGeneralBalance] = useState<GeneralBalanceData>(initialValues);
  const getInitData = useCallback(async () => {
    const { status, data } = await getGeneralBalance();
    if (status === "success") {
      setGeneralBalance(data);
    }
  }, []);
  const { status } = useAsync(getInitData, null, (error) => {
    enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
    console.error(error);
  });

  return (
    <Stack width="100%" spacing={1}>
      {status === "pending" || status === "idle" ? (
        <Loading />
      ) : status === "success" ? (
        <>
          <ProductTypeSummary data={generalBalance} />
          <Stack
            spacing={2}
            component={Card}
            sx={{ padding: "15px", backgroundColor: "#F6F9F2 !important" }}
            elevation={0}
          >
            <SavingAccountSummary data={generalBalance.savingAccount} />
            <CertificateSummary data={generalBalance.certificate} />
            <LoanSummary data={generalBalance.loan} />
          </Stack>
        </>
      ) : status === "error" ? (
        <div>{GENERIC_ERROR_MESSAGE}</div>
      ) : (
        <></>
      )}
    </Stack>
  );
}
