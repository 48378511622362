import { ClientFormData, ClientFormOptions } from "Services/api/register/interfaces";
import { addressSimplifed } from "Shared/AddressForm/interfaces";
import { emailValidation, phoneValidation, requiredNumberValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";

export type StepFormProps<T> = {
  options: ClientFormOptions;
  onSubmit: (values: T, form: keyof ClientFormData) => void;
  onBack: () => void;
  initialValues: T;
};

export const clientFormSchema = {
  personal: Yup.object({
    firstName: requiredValidation,
    lastName: requiredValidation,
    dateOfBirth: requiredValidation,
    nationality: requiredValidation,
    profession: requiredValidation,
  }),
  address: Yup.object({
    ...addressSimplifed,
    line2: Yup.string(),
  }),

  work: Yup.object({
    salary: requiredNumberValidation,
    dateOfHire: requiredValidation,
    shouldCapturePaymentType: Yup.boolean(),
    paymentType: Yup.string().when("shouldCapturePaymentType", { is: true, then: () => requiredValidation }),
    shouldCaptureSessionType: Yup.boolean(),
    sessionType: Yup.string().when("shouldCaptureSessionType", { is: true, then: () => requiredValidation }),
    position: requiredValidation,
    department: requiredValidation,
    shouldCaptureInstitution: Yup.boolean(),
    institutionEmail: Yup.string().when("shouldCaptureInstitution", { is: true, then: () => emailValidation }),
    institutionPhone: Yup.number().when("shouldCaptureInstitution", { is: true, then: () => phoneValidation }),
    economicSector: Yup.string().when("shouldCaptureInstitution", { is: true, then: () => requiredValidation }),
  }),
};
