import { useState, useCallback, useEffect } from "react";

export type UseAsyncStatus = "idle" | "pending" | "success" | "error";

// from https://usehooks.com/
// Hook
export const useAsync = <T, D, E = string>(
  asyncFunction: () => Promise<T>,
  initValue: D,
  errorCb: (error: E) => void,
  immediate = true
) => {
  const [status, setStatus] = useState<UseAsyncStatus>("idle");
  const [value, setValue] = useState<T | D>(initValue);
  const [error, setError] = useState<E | null>(null);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setStatus("pending");
    setValue(initValue);
    setError(null);
    return asyncFunction()
      .then((response: T) => {
        setValue(response);
        setStatus("success");
      })
      .catch((error: E) => {
        setError(error);
        setStatus("error");
        // Add error call back if we want to execute some
        // logic as soon as the error happens
        errorCb(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncFunction]);
  // Call execute if we want to fire it right away.
  // Otherwise, execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      void execute();
    }
  }, [execute, immediate]);

  if (status === "success") return { execute, status, value: value as T, error: error as null };
  if (status === "error") return { execute, status, value: value as D, error: error as E };
  return { execute, status, value: value as D, error: error as null };
};
