import { array, boolean, Decoder, DecoderType, nullable, number, object, string } from "decoders";
import { JsendResponse, jsendResponseDecoder } from "Utils/jsend";

const itemCommonDecoder = {
  associateId: string,
  associateName: string,
  accountNumber: number,
  creationDate: string,
  accountType: string,
  accountBalance: string,
  currency: string,
  amountPledged: string,
  savingsClassType: string,
  availableAccountBalance: string,
};

const savingAccountItemDecoder = object({
  ...itemCommonDecoder,
  savingFee: string,
});

const certificateItem = {
  ...itemCommonDecoder,
  settlementType: string,
  timeLimit: number,
  interestRate: string,
  lastInterestPaymentDate: string,
};

const loanProductDetail = object({
  loanNumber: number,
  loanOutstandingBalance: string,
  totalOutstandingBalanceDueByLoan: string,
  nextAmountDue: string,
});

const loanProductTypeDetail = object({
  productDetails: nullable(array(loanProductDetail)),
});

const loansGeneralInfo = object({
  productClassification: string,
  totalLoanStock: string,
  wIntr: string,
  totalNextAmountDue: string,
  loansQty: number,
  totalPcpAmount: string,
  totalOustandingBalanceDueByPc: string,
  totalAmountPendingByPc: nullable(string),
  productTypeDetails: array(loanProductTypeDetail),
});

const loanItemDecoder = object({
  associateId: string,
  associateName: string,
  totalLoanOutstandingBalance: string,
  totalActiveLoans: number,
  isAssociateDue: boolean,
  loansDueQty: number,
  totalQuotesDue: number,
  totalArrDue: string,
  loansGeneralInfo: array(loansGeneralInfo),
});

const savingAccountDataDecoder = object({
  total: string,
  items: array(savingAccountItemDecoder),
});

const dashboardCertificateItemDecoder = object({
  ...itemCommonDecoder,
  ...certificateItem,
});

const certificateDataDecoder = object({
  total: string,
  items: array(dashboardCertificateItemDecoder),
});

const loanDataDecoder = object({
  total: string,
  items: array(loanItemDecoder),
});

const generalBalanceDecoder = object({
  savingAccount: savingAccountDataDecoder,
  certificate: certificateDataDecoder,
  loan: loanDataDecoder,
});

export type SavingAccountData = DecoderType<typeof savingAccountDataDecoder>;
export type CertificateData = DecoderType<typeof certificateDataDecoder>;
export type LoanData = DecoderType<typeof loanDataDecoder>;
export type GeneralBalanceData = DecoderType<typeof generalBalanceDecoder>;
export type GeneralBalanceResponse = JsendResponse<GeneralBalanceData, string>;

export const generalBalanceResponseDecoder: Decoder<GeneralBalanceResponse> = jsendResponseDecoder(
  generalBalanceDecoder,
  string
);

const savingAccountTransactionDecoder = object({
  transactionReference: string,
  savingsAccountNumber: number,
  transactionDate: string,
  transactionConcept: string,
  startingBalance: string,
  credit: string,
  debit: string,
  endingBalance: string,
});

export const savingAccountTransactions = array(savingAccountTransactionDecoder);

export type SavingAccountTransactions = DecoderType<typeof savingAccountTransactions>;

export type SavingAccountTransactionsResponse = JsendResponse<SavingAccountTransactions, string>;

export const savingAccountTransactionsResponseDecoder: Decoder<SavingAccountTransactionsResponse> =
  jsendResponseDecoder(savingAccountTransactions, string);

export const savingAccountDetails = object({
  details: savingAccountItemDecoder,
  transactions: savingAccountTransactions,
});

export type SavingAccountDetails = DecoderType<typeof savingAccountDetails>;

export type SavingAccountDetailsResponse = JsendResponse<SavingAccountDetails, string>;

export const savingAccountDetailsResponseDecoder: Decoder<SavingAccountDetailsResponse> = jsendResponseDecoder(
  savingAccountDetails,
  string
);

export const certificateTransactions = array(savingAccountTransactionDecoder);

export type CertificateTransactions = DecoderType<typeof certificateTransactions>;

export type CertificateTransactionsResponse = JsendResponse<CertificateTransactions, string>;

export const certificateTransactionsResponseDecoder: Decoder<CertificateTransactionsResponse> = jsendResponseDecoder(
  certificateTransactions,
  string
);

const certificateItemDecoder = object({
  ...itemCommonDecoder,
  ...certificateItem,
  documentId: string,
});

export const certificateDetails = object({
  details: certificateItemDecoder,
  transactions: certificateTransactions,
});

export type CertificateDetails = DecoderType<typeof certificateDetails>;

export type CertificateDetailsResponse = JsendResponse<CertificateDetails, string>;

export const certificateDetailsResponseDecoder: Decoder<CertificateDetailsResponse> = jsendResponseDecoder(
  certificateDetails,
  string
);

const loanTransaction = object({
  transactionReference: number,
  paymentDate: string,
  beginningBalance: string,
  principalPaid: string,
  interestPaid: string,
  insurancePaid: string,
  arrearsPaid: string,
  otherChargesPaid: string,
  totalAmountPaid: string,
  endingBalance: string,
});

export const loanDetails = object({
  details: object({
    loanNumber: number,
    disbursementDate: string,
    loanAmount: string,
    loanOutstandingBalance: string,
    interestRate: string,
    loanPeriods: number,
    paymentFrequency: string,
    paymentDay: number,
    nextPaymentDue: string,
    nextAmountDue: string,
    remainingPeriods: string,
    lastPaymentDate: nullable(string),
    loanStatus: string,
  }),
  transactions: array(loanTransaction),
});

export type LoanDetails = DecoderType<typeof loanDetails>;

export type LoanDetailsResponse = JsendResponse<LoanDetails, string>;

export const loanDetailsResponseDecoder: Decoder<LoanDetailsResponse> = jsendResponseDecoder(loanDetails, string);
