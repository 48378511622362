import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Popover,
  Select,
  SelectProps,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import { ReactNode, useState } from "react";

export interface FormikSelectProps<T = unknown> extends SelectProps<T> {
  name: string;
  status?: "pending" | "error";
  helperText?: string;
  help?: ReactNode;
}

export default function FormikSelect<T>(props: FormikSelectProps<T>): JSX.Element {
  const { helperText, ...filteredProps } = props;
  const { isSubmitting, errors, touched } = useFormikContext<{ [k: string]: string }>();
  const error = Boolean(touched[props.name]) && Boolean(errors[props.name]);
  const ht = helperText ? helperText : "";

  return (
    <Field
      as={LabeledSelect}
      disabled={isSubmitting}
      error={error}
      helperText={error ? errors[props.name] : ht}
      {...filteredProps}
    />
  );
}

function LabeledSelect(
  props: SelectProps & {
    name: string;
    label: React.ReactNode;
    error: boolean;
    helperText: React.ReactNode;
    status?: "pending" | "error";
    help?: ReactNode;
  }
): JSX.Element {
  const { error, helperText, label, placeholder, children, status, SelectDisplayProps, help, ...filteredProps } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? `${props.name}-popover` : undefined;

  return (
    <Stack spacing={1} component={FormControl} fullWidth justifyContent="center">
      <Stack direction="row" alignItems="center" flexWrap="wrap" spacing={1}>
        {label && <label htmlFor={filteredProps.id}>{label}</label>}
        {help && (
          <Button variant="text" onClick={handleClick} sx={{ p: 0 }}>
            ¿Qué es esto?
          </Button>
        )}
      </Stack>

      {status === "pending" ? (
        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: "8px", borderRadius: "5px" }} />
      ) : status === "error" ? (
        <Typography color="#d32f2f">Error cargando opciones</Typography>
      ) : (
        // @ts-expect-error test id
        <Select {...filteredProps} SelectDisplayProps={{ "data-testid": filteredProps.id, ...SelectDisplayProps }}>
          {placeholder ? (
            <MenuItem disabled value="">
              <span style={{ color: "#BABABA" }}>{placeholder}</span>
            </MenuItem>
          ) : null}
          {children}
        </Select>
      )}

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {help}
      </Popover>

      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Stack>
  );
}
