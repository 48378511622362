import { MenuItem, Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { getSavingsTypeChoices } from "Services/api/request/request";
import FormikSelect, { FormikSelectProps } from "Shared/FormikSelect/FormikSelect";
import { Query } from "Shared/Query/Query";

interface SavingsTypeSelectProps {
  name: string;
  label: string;
  removePlan?: boolean;
}

export function SavingsTypeSelect({ name, label, removePlan = false }: SavingsTypeSelectProps) {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getSavingsTypeChoices.name],
    queryFn: async () => {
      try {
        const response = await getSavingsTypeChoices();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const props: FormikSelectProps<string> = {
    id: name,
    name,
    label,
    SelectDisplayProps: { "aria-label": label },
    required: true,
  };

  return (
    <Query
      result={result}
      OnLoading={() => (
        <Stack spacing={1} width="100%">
          <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
          <Skeleton variant="rectangular" width={"100%"} height={56} />
        </Stack>
      )}
      onError={() => <FormikSelect {...props} />}
      onSuccess={(type) => (
        <FormikSelect {...props}>
          {type.map(([value, display]) => {
            if (removePlan && value === "planned") return null;
            return (
              <MenuItem key={value} value={value}>
                {display}
              </MenuItem>
            );
          })}
        </FormikSelect>
      )}
    />
  );
}
