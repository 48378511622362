import { Button, ButtonProps, FormHelperText, Popover, Stack, StackProps } from "@mui/material";
import { useField, useFormikContext } from "formik";
import FileIcon from "Icons/FileIcon";
import { Fragment, ReactNode, useEffect, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { ExportInterface } from "react-images-uploading/dist/typings";

interface FormikImageFieldProps {
  name: string;
  label?: string;
  containerProps?: StackProps;
  buttonProps?: ButtonProps;
  showHelperText?: boolean;
  help?: ReactNode;
}

export function FormikImageField(props: FormikImageFieldProps): JSX.Element {
  const [, , { setValue }] = useField<File | undefined>(props);
  const [images, setImages] = useState<ImageListType>([]);

  const onImageChange = (imageList: ImageListType) => {
    setImages(imageList);
    setValue(imageList[0].file);
  };

  return (
    <ImageUploading
      value={images}
      onChange={onImageChange}
      acceptType={["jpg", "jpeg", "png", "pdf"]}
      maxFileSize={6 * 1024 * 1024}
      dataURLKey="data_url"
      allowNonImageType
    >
      {(exportInterface) => <ImageUploadEl formikProps={props} exportInterface={exportInterface} />}
    </ImageUploading>
  );
}

interface ImageUploadElProps {
  formikProps: FormikImageFieldProps;
  exportInterface: ExportInterface;
}

function ImageUploadEl({ formikProps, exportInterface: { onImageUpload, errors } }: ImageUploadElProps): JSX.Element {
  const { name, label, help, showHelperText = true, containerProps = {}, buttonProps = {} } = formikProps;
  const [{ value }, { error }, { setError }] = useField<File | undefined>(formikProps);
  const { isSubmitting } = useFormikContext<{ [k: string]: string }>();
  const [touched, setTouched] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const buttonDisplay = value ? "Cambiar archivo" : "Adjuntar archivo";

  useEffect(() => {
    if (isSubmitting) setTouched(true);
  }, [isSubmitting, setTouched]);

  useEffect(() => {
    if (errors?.maxFileSize) setError("El archivo no puede ser mayor de 6 MB");
  }, [errors]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? `${formikProps.name}-popover` : undefined;

  return (
    <Stack spacing={1} {...containerProps}>
      <Stack direction="row" alignItems="center" flexWrap="wrap" spacing={1}>
        {label && <label htmlFor={name}>{label}</label>}
        {help && (
          <Button variant="text" onClick={handleClick} sx={{ p: 0 }}>
            ¿Qué es esto?
          </Button>
        )}
      </Stack>
      <Button
        variant="outlined"
        startIcon={<FileIcon />}
        disabled={isSubmitting}
        onClick={onImageUpload}
        {...buttonProps}
      >
        {buttonDisplay}
      </Button>
      {showHelperText && (
        <Fragment>
          <FormHelperText>
            {value
              ? value.name
              : "El archivo debe estar en formato jpg, png o pdf, y pesar menos de 6 MB. 1 solo archivo."}
          </FormHelperText>
          {touched && error && <FormHelperText error> {error}</FormHelperText>}
        </Fragment>
      )}

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {help}
      </Popover>
    </Stack>
  );
}
