/* istanbul ignore file */
import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS } from "../config";
import {
  NationalityOptionResponse,
  nationalityOptionResponse,
  ProfessionOptionResponse,
  professionOptionResponse,
  CityOptionResponse,
  cityOptionResponse,
  ProvinceOptionResponse,
  provinceOptionResponse,
  RegionOptionResponse,
  regionOptionResponse,
  PositionOptionResponse,
  positionOptionResponse,
  SecurityQuestionResponse,
  securityQuestionOptionResponse,
} from "./interfaces";

export async function getNationalities(): Promise<NationalityOptionResponse> {
  const response = await enhancedFetch(`${API_URL}/crm/countries/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return nationalityOptionResponse.verify(await response.json());
}

export async function getProfessions(): Promise<ProfessionOptionResponse> {
  const response = await enhancedFetch(`${API_URL}/crm/professions/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return professionOptionResponse.verify(await response.json());
}

export async function getCities(province = ""): Promise<CityOptionResponse> {
  const query = new URLSearchParams({ state: province }).toString();
  const response = await enhancedFetch(`${API_URL}/crm/cities/${province ? `?${query}` : ""}`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return cityOptionResponse.verify(await response.json());
}

export async function getProvinces(region = ""): Promise<ProvinceOptionResponse> {
  const query = new URLSearchParams({ region }).toString();

  const response = await enhancedFetch(`${API_URL}/crm/provinces/${region ? `?${query}` : ""}`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return provinceOptionResponse.verify(await response.json());
}

export async function getRegions(country = ""): Promise<RegionOptionResponse> {
  const query = new URLSearchParams({ country }).toString();

  const response = await enhancedFetch(`${API_URL}/crm/regions/${country ? `?${query}` : ""}`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return regionOptionResponse.verify(await response.json());
}

export async function getPositions(): Promise<PositionOptionResponse> {
  const response = await enhancedFetch(`${API_URL}/crm/positions/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return positionOptionResponse.verify(await response.json());
}

export async function getSecurityQuestions(): Promise<SecurityQuestionResponse> {
  const response = await enhancedFetch(`${API_URL}/crm/security-questions/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return securityQuestionOptionResponse.verify(await response.json());
}
