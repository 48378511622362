import { SVGProps } from "react";

export default function WhatsAppIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.2389 11.131C13.0366 11.0271 12.0291 10.5335 11.8417 10.4667C11.6543 10.3962 11.517 10.3628 11.3815 10.5706C11.2442 10.7766 10.8546 11.2349 10.7321 11.374C10.6134 11.5113 10.4928 11.528 10.2905 11.426C9.08818 10.8248 8.29961 10.3535 7.50732 8.99346C7.29766 8.63164 7.71699 8.65762 8.1085 7.87646C8.17529 7.73916 8.14189 7.62227 8.08994 7.51836C8.03799 7.41445 7.62979 6.40879 7.45908 5.99873C7.29395 5.5998 7.12324 5.65547 6.99893 5.64805C6.88018 5.64062 6.74473 5.64062 6.60742 5.64062C6.47012 5.64062 6.24932 5.69258 6.06191 5.89482C5.87451 6.10078 5.3457 6.59619 5.3457 7.60186C5.3457 8.60752 6.07861 9.58164 6.17881 9.71895C6.28271 9.85625 7.62051 11.9195 9.67451 12.8083C10.9733 13.3687 11.4817 13.4169 12.1312 13.3204C12.5264 13.261 13.3409 12.8269 13.5098 12.3463C13.6786 11.8676 13.6786 11.4575 13.6285 11.3722C13.5784 11.2813 13.4411 11.2293 13.2389 11.131Z"
        fill="#547725"
      />
      <path
        d="M17.1669 6.27891C16.7476 5.28252 16.1464 4.38818 15.3801 3.62002C14.6138 2.85371 13.7194 2.25068 12.7212 1.8332C11.7007 1.40459 10.6171 1.1875 9.50011 1.1875H9.463C8.33858 1.19307 7.24942 1.41572 6.2252 1.85361C5.23624 2.27666 4.34932 2.87783 3.59044 3.64414C2.83155 4.41045 2.23595 5.30107 1.82403 6.29375C1.39727 7.32168 1.18204 8.41455 1.18761 9.53896C1.19317 10.8267 1.50118 12.1051 2.07823 13.248V16.0684C2.07823 16.5396 2.46046 16.9219 2.93175 16.9219H5.75391C6.89688 17.4989 8.1753 17.8069 9.463 17.8125H9.50196C10.6134 17.8125 11.6914 17.5973 12.7064 17.1761C13.699 16.7623 14.5915 16.1686 15.356 15.4097C16.1223 14.6508 16.7253 13.7639 17.1465 12.7749C17.5844 11.7507 17.807 10.6615 17.8126 9.53711C17.8182 8.40713 17.5992 7.31055 17.1669 6.27891V6.27891ZM14.3633 14.4059C13.0626 15.6936 11.337 16.4023 9.50011 16.4023H9.46856C8.34971 16.3968 7.23829 16.1185 6.25675 15.5952L6.10089 15.5117H3.48839V12.8992L3.40489 12.7434C2.88165 11.7618 2.60333 10.6504 2.59776 9.53154C2.59034 7.68164 3.29727 5.94492 4.59425 4.63682C5.88936 3.32871 7.62052 2.60508 9.47042 2.59766H9.50196C10.4297 2.59766 11.3296 2.77764 12.1775 3.13389C13.0051 3.48086 13.7473 3.97998 14.3856 4.61826C15.022 5.25469 15.523 5.99873 15.8699 6.82627C16.2299 7.6835 16.4099 8.59268 16.4062 9.53154C16.395 11.3796 15.6695 13.1107 14.3633 14.4059V14.4059Z"
        fill="#547725"
      />
    </svg>
  );
}
