import { EditContentProps } from "./interfaces";
import { AddressForm } from "Shared/AddressForm/AddressForm";
import { useSnackbar } from "notistack";
import { updateAddress } from "Services/api/profile/profile";

export function AddressChange({ profile, setProfile, closeDialog }: EditContentProps) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <AddressForm
      onSubmit={async (values) => {
        try {
          const requestData = {
            country: profile.address.country,
            region: values.region,
            state: values.province,
            city: values.city,
            sector: values.sector,
            reference: values.line1,
          };
          const { status, data } = await updateAddress({
            address: requestData,
          });

          if (status === "success") {
            enqueueSnackbar(data, { variant: "success" });
            setProfile({
              ...profile,
              address: requestData,
            });
            closeDialog();
          } else enqueueSnackbar(data, { variant: "error" });
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
          console.error(error);
        }
      }}
      initialValues={{
        country: "República Dominicana",
        region: profile.address.region,
        province: profile.address.state,
        city: profile.address.city,
        sector: profile.address.sector,
        line1: profile.address.reference,
      }}
      options={{
        regions: [],
      }}
      simplified
    />
  );
}
