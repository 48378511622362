import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS, getCSRFToken } from "../config";
import { StringJsendSuccessResponse, stringJsendSuccessResponseDecoder } from "../interfaces";
import { MetamapVerification } from "../metamap/interfaces";
import { OtpValidationData } from "../otp/interfaces";
import {
  LoanDataJsendResponseDecoder,
  loanDataJsendResponseDecoder,
  LoanDataPayload,
  PrequalificationFields,
  PrequalificationFieldsResponse,
  prequalificationFieldsResponseDecoder,
} from "../request/interfaces";
import {
  AffiliationOptionsResponse,
  affiliationOptionsResponseDecoder,
  ClientFormData,
  ClientFormOptionsResponse,
  clientFormOptionsResponseDecoder,
  ConfirmChannelResponse,
  confirmChannelResponseDecoder,
  GetClientDataResponse,
  getClientDataResponseDecoder,
  GetClientIdResponse,
  getClientIdResponseDecoder,
  GetRegistrationResponse,
  getRegistrationResponseDecoder,
  IncomeSourceChoicesResponse,
  incomeSourceChoicesResponseDecoder,
  NoEmployerCodePayload,
  NoEmployerCodeResponse,
  NoEmployerCodeResponseDecoder,
  PrequalificationResponse,
  prequalificationResponseDecoder,
  RegistrationModel,
  RegistrationModelSuccessResponse,
  registrationModelSuccessResponseDecoder,
  RegistrationStatusResponse,
  registrationStatusResponseDecoder,
  RegistrationSuccessStatusResponse,
  registrationSuccessStatusResponseDecoder,
  SaveContactRequest,
  SaveContactResponse,
  saveContactResponseDecoder,
  SaveMetamapInfoResponse,
  saveMetamapInfoResponseDecoder,
  StartRegistrationRequest,
  StartRegistrationResponse,
  startRegistrationResponseDecoder,
  UserFormData,
} from "./interfaces";

const ENDPOINT = `${API_URL}/register`;

export async function getIncomeSourceChoices(): Promise<IncomeSourceChoicesResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/income-source-choices/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return incomeSourceChoicesResponseDecoder.verify(await response.json());
}

export async function startRegistration(
  startRegistrationRequest: StartRegistrationRequest
): Promise<StartRegistrationResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/start/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(startRegistrationRequest),
  });

  return startRegistrationResponseDecoder.verify(await response.json());
}

export async function startNoEmployerCode(
  noEmployerCodePayload: NoEmployerCodePayload
): Promise<NoEmployerCodeResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/start-with-no-employer-code/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(noEmployerCodePayload),
  });

  return NoEmployerCodeResponseDecoder.verify(await response.json());
}

export async function resetRegistration(id: RegistrationModel["id"]): Promise<RegistrationModelSuccessResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/reset/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id }),
  });

  return registrationModelSuccessResponseDecoder.verify(await response.json());
}

export async function back(id: RegistrationModel["id"]): Promise<RegistrationModelSuccessResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/back/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id }),
  });

  return registrationModelSuccessResponseDecoder.verify(await response.json());
}

export async function acceptTerms(
  id: RegistrationModel["id"],
  terms: boolean
): Promise<RegistrationSuccessStatusResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/terms/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, terms }),
  });

  return registrationSuccessStatusResponseDecoder.verify(await response.json());
}

export async function saveName(id: RegistrationModel["id"], name: string): Promise<RegistrationModelSuccessResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-name/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, name }),
  });

  return registrationModelSuccessResponseDecoder.verify(await response.json());
}

export async function affiliationPurpose(values: {
  id: RegistrationModel["id"];
  purpose: string;
}): Promise<RegistrationModelSuccessResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/affiliation-purpose/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(values),
  });

  return registrationModelSuccessResponseDecoder.verify(await response.json());
}

export async function confirmEmail(
  id: RegistrationModel["id"],
  to: OtpValidationData["to"]
): Promise<ConfirmChannelResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/confirm-email/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, to }),
  });

  return confirmChannelResponseDecoder.verify(await response.json());
}

export async function confirmPhone(
  id: RegistrationModel["id"],
  to: OtpValidationData["to"]
): Promise<ConfirmChannelResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/confirm-phone/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, to }),
  });

  return confirmChannelResponseDecoder.verify(await response.json());
}

export async function saveEmail(saveContactRequest: SaveContactRequest): Promise<SaveContactResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-email/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(saveContactRequest),
  });

  return saveContactResponseDecoder.verify(await response.json());
}

export async function savePhone(saveContactRequest: SaveContactRequest): Promise<SaveContactResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-phone/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(saveContactRequest),
  });

  return saveContactResponseDecoder.verify(await response.json());
}

export async function getPrequalificationValues(values: {
  id: RegistrationModel["id"];
}): Promise<PrequalificationFieldsResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/prequalification-values/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(values),
  });

  return prequalificationFieldsResponseDecoder.verify(await response.json());
}

export async function savePrequalification(
  values: PrequalificationFields & { id: RegistrationModel["id"] }
): Promise<PrequalificationResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-prequalification/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(values),
  });

  return prequalificationResponseDecoder.verify(await response.json());
}

export async function saveLoanData(
  data: LoanDataPayload & { id: RegistrationModel["id"] }
): Promise<LoanDataJsendResponseDecoder> {
  const response = await enhancedFetch(`${ENDPOINT}/loan-data/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(data),
  });

  return loanDataJsendResponseDecoder.verify(await response.json());
}

export async function confirmLoanData(id: RegistrationModel["id"]): Promise<RegistrationStatusResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/confirm-loan/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id }),
  });

  return registrationStatusResponseDecoder.verify(await response.json());
}

export async function saveSavingsData(data: FormData): Promise<RegistrationSuccessStatusResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-savings-request/`, {
    ...FETCH_DEFAULT_CONFIGS,
    headers: {
      "X-CSRFToken": getCSRFToken(),
    },
    method: "POST",
    body: data,
  });

  return registrationSuccessStatusResponseDecoder.verify(await response.json());
}

export async function saveCertificateData(data: FormData): Promise<RegistrationSuccessStatusResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-certificate/`, {
    ...FETCH_DEFAULT_CONFIGS,
    headers: {
      "X-CSRFToken": getCSRFToken(),
    },
    method: "POST",
    body: data,
  });

  return registrationSuccessStatusResponseDecoder.verify(await response.json());
}

export async function getAffiliationTermsOptions(values: {
  id: RegistrationModel["id"];
}): Promise<AffiliationOptionsResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/affiliation-terms-options/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(values),
  });

  return affiliationOptionsResponseDecoder.verify(await response.json());
}

export async function saveAffiliationTermsInitial(data: FormData): Promise<RegistrationModelSuccessResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-affiliation-terms-initial/`, {
    ...FETCH_DEFAULT_CONFIGS,
    headers: {
      "X-CSRFToken": getCSRFToken(),
    },
    method: "POST",
    body: data,
  });

  return registrationModelSuccessResponseDecoder.verify(await response.json());
}

export async function saveAffiliationTerms(values: {
  id: RegistrationModel["id"];
}): Promise<RegistrationSuccessStatusResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-affiliation-terms/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(values),
  });

  return registrationSuccessStatusResponseDecoder.verify(await response.json());
}

export async function saveMetamapInfo(
  id: RegistrationModel["id"],
  metamapVerification: MetamapVerification
): Promise<SaveMetamapInfoResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-metamap-info/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, metamapId: metamapVerification.id, metamapIdentity: metamapVerification.identity }),
  });

  return saveMetamapInfoResponseDecoder.verify(await response.json());
}

export async function getClientFormOptions(): Promise<ClientFormOptionsResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/get-client-form-options/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return clientFormOptionsResponseDecoder.verify(await response.json());
}

export async function getClientData(id: RegistrationModel["id"]): Promise<GetClientDataResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/get-client-data/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id }),
  });

  return getClientDataResponseDecoder.verify(await response.json());
}

export async function savePersonalData(
  id: RegistrationModel["id"],
  personalData: ClientFormData["personal"]
): Promise<StringJsendSuccessResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-personal-data/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, ...personalData }),
  });

  return stringJsendSuccessResponseDecoder.verify(await response.json());
}

export async function saveAddressData(data: FormData): Promise<StringJsendSuccessResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-address-data/`, {
    ...FETCH_DEFAULT_CONFIGS,
    headers: {
      "X-CSRFToken": getCSRFToken(),
    },
    method: "POST",
    body: data,
  });

  return stringJsendSuccessResponseDecoder.verify(await response.json());
}

export async function saveWorkData(data: FormData): Promise<RegistrationSuccessStatusResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-work-data/`, {
    ...FETCH_DEFAULT_CONFIGS,
    headers: {
      "X-CSRFToken": getCSRFToken(),
    },
    method: "POST",
    body: data,
  });

  return registrationSuccessStatusResponseDecoder.verify(await response.json());
}

export async function getClientId(id: RegistrationModel["id"]): Promise<GetClientIdResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/get-client-id/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id }),
  });

  return getClientIdResponseDecoder.verify(await response.json());
}

export async function completeRegistration(
  id: RegistrationModel["id"],
  userData: UserFormData
): Promise<RegistrationSuccessStatusResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/complete/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, ...userData }),
  });

  return registrationSuccessStatusResponseDecoder.verify(await response.json());
}

export async function getRegistration(id: RegistrationModel["id"]): Promise<GetRegistrationResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/${id}/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return getRegistrationResponseDecoder.verify(await response.json());
}
