import { styled, Typography } from "@mui/material";

export const Text = styled(Typography)(() => ({
  color: "#7C7C7C",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "20px",
}));

export const StepText = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "29px",
  color: "#547725",
}));
