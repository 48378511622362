import { useAsync } from "Hooks/useAsync";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { getSecurityQuestions } from "Services/api/crm/crm";
import { ReadOnlyUserFormData, RegistrationModel, UserFormData } from "Services/api/register/interfaces";
import { completeRegistration, getClientId } from "Services/api/register/register";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import FormikForm from "Shared/FormikForm/FormikForm";
import { LabeledTextField } from "Shared/LabeledTextField/LabeledTextField";
import Password from "Shared/Password/Password";
import PasswordHide from "Shared/PasswordHide/PasswordHide";
import QuestionsSelect from "Shared/QuestionsSelect/QuestionsSelect";
import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { ActionButtons } from "../ActionButtons/ActionButtons";
import { useRegistrationContext } from "../Registration";
import { UserFormSchema } from "./interfaces";

export default function UserForm(): JSX.Element {
  return <SplitFormContainer title="Configuración de usuario" sideInfoTop={<AccessInfo />} form={<FormContainer />} />;
}

const initialValues: UserFormData = {
  password: "",
  confirm: "",
  question1: "",
  answer1: "",
  question2: "",
  answer2: "",
};

function FormContainer(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [{ id }] = useRegistrationContext();

  const getInitData = useCallback(() => onLoad(id), [id]);
  const { status, value } = useAsync(getInitData, null, (error) => {
    enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
    console.error(error);
  });

  return status === "pending" || status === "idle" ? (
    <div>Cargando datos ...</div>
  ) : status === "success" ? (
    <Form {...value} />
  ) : status === "error" ? (
    <div>Ha ocurrido un error cargando los datos ...</div>
  ) : (
    <></>
  );
}

function Form({ username, securityQuestions }: ReadOnlyUserFormData) {
  const { enqueueSnackbar } = useSnackbar();
  const [{ id }, setRegContext] = useRegistrationContext();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers: FormikHelpers<UserFormData>) => void onSubmit(values, helpers)}
      validationSchema={UserFormSchema}
    >
      <FormikForm width="100%">
        <LabeledTextField
          name="user"
          id="user"
          disabled
          label="Usuario:"
          value={username || ""}
          fullWidth
          autoComplete="username"
        />
        <PasswordHide
          name="password"
          id="password"
          label="Crea una contraseña (4 dígitos):"
          InputProps={{
            autoComplete: "new-password",
          }}
        />
        <Password
          name="confirm"
          id="confirm"
          label="Confirmar contraseña:"
          type="password"
          InputProps={{
            autoComplete: "new-password",
          }}
        />

        <QuestionsSelect data={securityQuestions} />
        <ActionButtons end />
      </FormikForm>
    </Formik>
  );

  async function onSubmit(values: UserFormData, { setSubmitting }: FormikHelpers<UserFormData>) {
    try {
      const { data } = await completeRegistration(id, values);
      setRegContext(data);
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }
    setSubmitting(false);
  }
}

async function onLoad(id: RegistrationModel["id"]): Promise<ReadOnlyUserFormData> {
  const {
    data: { clientId },
  } = await getClientId(id);
  const { data: securityQuestions } = await getSecurityQuestions();

  return {
    username: clientId,
    securityQuestions,
  };
}
