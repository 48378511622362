import { useState, useCallback, createRef, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import getVideoTypeByBrowser from "Utils/getVideoTypeByBrowser";
import Videocam from "Icons/Videocam";
import Stop from "Icons/Stop";
import {
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  Stack,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

export interface VideoCaptureProps {
  onRecord: (video: Blob) => void;
  onClose: () => void;
}

const videoMimeType: "video/mp4" | "video/webm" = `video/${getVideoTypeByBrowser()}`;

const VideoCapture = (props: VideoCaptureProps) => {
  const { onRecord, onClose } = props;
  const webcamRef = createRef<Webcam>();
  const mediaRecorderRef = useRef<MediaRecorder>();
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<BlobPart[]>([]);
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout> | undefined>();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();

  /* istanbul ignore next */
  const handleStartCaptureClick = useCallback(() => {
    const stream = webcamRef.current?.stream;
    if (!stream) return;
    setCapturing(true);

    try {
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: videoMimeType,
      });

      mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
      setTimerId(
        setTimeout(() => {
          handleStopCaptureClick();
        }, 15000)
      );
      mediaRecorderRef.current.start();
    } catch (error) {
      enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
      throw error;
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  /* istanbul ignore next */
  const handleDataAvailable = useCallback(
    ({ data }: BlobEvent) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  /* istanbul ignore next */
  const handleStopCaptureClick = useCallback(() => {
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(undefined);
    }
    mediaRecorderRef.current?.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  useEffect(() => {
    onRecord(new Blob(recordedChunks, { type: videoMimeType }));
  }, [recordedChunks]);

  return (
    <Dialog onClose={onClose} open fullScreen={fullScreen}>
      <Stack component={DialogTitle} direction={"row"} justifyContent="space-between" alignItems={"center"}>
        Capturar video
        <IconButton onClick={onClose} sx={{ color: "#000" }}>
          <Clear />
        </IconButton>
      </Stack>
      <DialogContent>
        <Webcam
          audio={false}
          ref={webcamRef}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          videoConstraints={{
            facingMode: "user",
          }}
          mirrored
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        {capturing ? (
          <Button variant="outlined" color="error" onClick={handleStopCaptureClick} startIcon={<Stop />}>
            Detener
          </Button>
        ) : (
          <Button variant="outlined" onClick={handleStartCaptureClick} startIcon={<Videocam />}>
            Iniciar Grabación
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default VideoCapture;
