import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { Backdrop, BackdropState } from "Shared/Backdrop/Backdrop";
import { ForgotPasswordData } from "Pages/ForgotPassword/interfaces";
import RestorePasswordStep from "Pages/ForgotPassword/RestorePasswordStep";
import ForgotPasswordInfo from "Pages/ForgotPassword/ForgotPasswordInfo";
import { getSecretQuestions } from "Services/api/auth/auth";
import { useSingleEffect } from "Hooks/useSingleEffect";
import SecretQuestionsStep from "Pages/ForgotPassword/SecretQuestionsStep";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

const ForgotPasswordView = {
  loading: () => <div></div>,
  "secret-questions": SecretQuestionsStep,
  "restore-password": RestorePasswordStep,
};

const defaultState: ForgotPasswordData = {
  status: "loading",
};

export default function ForgotPassword(): JSX.Element {
  return (
    <SplitFormContainer
      title="Restaurar contraseña"
      sideInfoTop={<ForgotPasswordInfo />}
      form={<ForgotPasswordForm />}
    />
  );
}

function ForgotPasswordForm(): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [backdropState, setBackdropState] = useState<BackdropState>({ open: true, msg: "Validando ..." });
  const [forgotPasswordModel, setForgotPasswordModel] = useState<ForgotPasswordData>(defaultState);

  const StepElement = ForgotPasswordView[forgotPasswordModel.status];

  useSingleEffect(() => {
    void onLoad({ uid: params.uid || "", token: params.token || "" });
  });

  return (
    <>
      <StepElement model={forgotPasswordModel} setModel={setForgotPasswordModel} />
      <Backdrop {...backdropState} />
    </>
  );

  async function onLoad(payload: { uid: string; token: string }) {
    try {
      const { status, data } = await getSecretQuestions(payload);
      if (status === "success") {
        setForgotPasswordModel({
          secretQuestions: data,
          status: "secret-questions",
          uid: payload.uid,
          token: payload.token,
        });
        setBackdropState({
          open: false,
        });
      } else {
        enqueueSnackbar(data.message, { variant: "error" });
        navigate("/");
      }
    } catch (error) {
      enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
      navigate("/");
      console.error(error);
    }
  }
}
