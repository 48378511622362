import { Stack } from "@mui/material";
import SideInfo from "../SideInfo/SideInfo";

export const description =
  "Para completar el proceso de afiliación, asegúrate de cumplir con los siguientes requisitos:";

export const steps = [
  "Ser de nacionalidad dominicana.",
  "No estar catalogado como Persona Políticamente Expuesta (PEP).",
  "Tener un correo electrónico válido.",
  "Contar con un dispositivo que tenga cámara.",
  "Poseer contigo tu cédula de identidad y electoral.",
  "No vivir o trabajar en los Estados Unidos.",
  "No ser proveedor del estado.",
];

export default function AccessInfo(): JSX.Element {
  return (
    <Stack spacing={2} direction="column" sx={{ position: "sticky", top: "35vh" }}>
      <SideInfo title={"Proceso de afiliación digital"} description={description} steps={steps} />
    </Stack>
  );
}
