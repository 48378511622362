import { array, Decoder, DecoderType, nullable, number, object, string } from "decoders";
import { JsendSuccess, jsendSuccessDecoder } from "Utils/jsend";

const baseOption = {
  name: string,
  unique_id: string,
};

export const baseOptionDecoder = object(baseOption);

type BaseOption = DecoderType<typeof baseOptionDecoder>;

export type BaseOptionResponse = JsendSuccess<BaseOption[]>;

export const baseOptionResponse: Decoder<BaseOptionResponse> = jsendSuccessDecoder(array(baseOptionDecoder));

export const nationalityOptionDecoder = object({
  core_id: number,
  name: string,
});

type NationalityOption = DecoderType<typeof nationalityOptionDecoder>;

export type NationalityOptionResponse = JsendSuccess<NationalityOption[]>;

export const nationalityOptionResponse: Decoder<NationalityOptionResponse> = jsendSuccessDecoder(
  array(nationalityOptionDecoder)
);

export const professionOptionDecoder = object({
  ...baseOption,
  reference_code: string,
  rohi_id: string,
});

type ProfessionOption = DecoderType<typeof professionOptionDecoder>;

export type ProfessionOptionResponse = JsendSuccess<ProfessionOption[]>;

export const professionOptionResponse: Decoder<ProfessionOptionResponse> = jsendSuccessDecoder(
  array(professionOptionDecoder)
);

export const cityOptionDecoder = object({
  name: string,
  core_id: string,
  state_id: string,
});

export type CityOption = DecoderType<typeof cityOptionDecoder>;

export type CityOptionResponse = JsendSuccess<CityOption[]>;

export const cityOptionResponse: Decoder<CityOptionResponse> = jsendSuccessDecoder(array(cityOptionDecoder));

export const provinceOptionDecoder = object({
  name: string,
  core_id: string,
  region_id: string,
});

type ProvinceOption = DecoderType<typeof provinceOptionDecoder>;

export type ProvinceOptionResponse = JsendSuccess<ProvinceOption[]>;

export const provinceOptionResponse: Decoder<ProvinceOptionResponse> = jsendSuccessDecoder(
  array(provinceOptionDecoder)
);

export const regionOptionDecoder = object({
  name: string,
  core_id: string,
  country_id: nullable(number),
});

type RegionOption = DecoderType<typeof regionOptionDecoder>;

export type RegionOptionResponse = JsendSuccess<RegionOption[]>;

export const regionOptionResponse: Decoder<RegionOptionResponse> = jsendSuccessDecoder(array(regionOptionDecoder));

export const positionOptionDecoder = object({
  ...baseOption,
  reference_code: string,
  description: nullable(string),
});

type PositionOption = DecoderType<typeof positionOptionDecoder>;

export type PositionOptionResponse = JsendSuccess<PositionOption[]>;

export const positionOptionResponse: Decoder<PositionOptionResponse> = jsendSuccessDecoder(
  array(positionOptionDecoder)
);

export const departmentOptionDecoder = object(baseOption);

type DepartmentOption = DecoderType<typeof departmentOptionDecoder>;

export type DepartmentOptionResponse = JsendSuccess<DepartmentOption[]>;

export const deparmentOptionResponse: Decoder<DepartmentOptionResponse> = jsendSuccessDecoder(
  array(departmentOptionDecoder)
);

export const securityQuestionOptionDecoder = object(baseOption);

export type SecurityQuestionOption = DecoderType<typeof securityQuestionOptionDecoder>;

export type SecurityQuestionResponse = JsendSuccess<SecurityQuestionOption[]>;

export const securityQuestionOptionResponse: Decoder<SecurityQuestionResponse> = jsendSuccessDecoder(
  array(securityQuestionOptionDecoder)
);

const addressFormOptionsDecoder = object({
  regions: array(regionOptionDecoder),
});

export type AddressFormOptions = DecoderType<typeof addressFormOptionsDecoder>;

export type AddressFormOptionsResponse = JsendSuccess<AddressFormOptions>;
export const addressFormOptionsResponseDecoder: Decoder<AddressFormOptionsResponse> =
  jsendSuccessDecoder(addressFormOptionsDecoder);
