import { Box, Link, Stack, Typography } from "@mui/material";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import logo from "../../../../../public/Blanco.png";
import { useRegistrationContext } from "../Registration";

export default function Complete(): JSX.Element {
  const [{ isAffiliatedClient }] = useRegistrationContext();

  const Message = isAffiliatedClient ? AffiliateMessage : NonAffiliateMessage;
  return (
    <SplitFormContainer
      title="Registro completado"
      sideInfoTop={
        <Stack direction="column" justifyContent="center" alignItems="center" height="100%" width="100%">
          <Box src={logo} sx={{ height: 150 }} component="img" alt="Cooperativa Barcelona letter logo" />
        </Stack>
      }
      form={<Message />}
    />
  );
}

function AffiliateMessage() {
  return (
    <Typography>
      <strong>¡Felicidades!</strong> tu usuario virtual ha sido creado exitosamente. El siguiente paso es la firma del
      documento de uso de canales electrónicos, puedes agendar una cita para la firma accediendo al siguiente enlace{" "}
      <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/haz-tu-cita/ ">
        <strong>Haz Tu Cita.</strong>
      </Link>
    </Typography>
  );
}

function NonAffiliateMessage() {
  const [{ institutionIsAffiliated }] = useRegistrationContext();
  return (
    <Typography>
      {"¡Tu afiliación ha sido exitosa! "}
      {institutionIsAffiliated
        ? "Ahora solo falta que un representante de recursos humanos de tu empresa confirme los datos. Una vez verificados, serás aceptado como socio de nuestra cooperativa."
        : "Pronto un representante de negocios se pondrá en contacto para completar la información. Una vez verificada, serás aceptado como socio de nuestra cooperativa."}
    </Typography>
  );
}
