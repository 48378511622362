import { Stack } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import OtpValidationForm from "Pages/Registration/OtpValidation/OtpValidationForm";
import { useCallback, useState } from "react";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { EditContentProps } from "./interfaces";
import * as Yup from "yup";
import { emailValidation } from "Utils/validations";
import { useSnackbar } from "notistack";
import { validateEmail } from "Services/api/otp/otp";
import { OtpValidationData } from "Services/api/otp/interfaces";
import { updateEmail } from "Services/api/profile/profile";
import { useHandleError } from "Hooks/useHandleError";
import ProfileToken, { checkToken } from "./ProfileToken";

const schema = Yup.object({
  email: emailValidation,
});

interface Payload {
  email: string;
  code: string;
}

export function EmailChange({ profile, setProfile, closeDialog }: EditContentProps) {
  const { email } = profile;
  const [step, setStep] = useState<"edit" | "otp" | "verification">("edit");
  const { enqueueSnackbar } = useSnackbar();
  const [payload, setPayload] = useState<Payload>({ email, code: "" });
  const handleError = useHandleError();

  const changeEmail = useCallback(async (values: Payload) => {
    await checkToken(
      async (token) => {
        const { status, data } = await updateEmail({
          token,
          email: values.email,
          code: values.code,
        });

        if (status === "success") {
          setProfile({ ...profile, email: values.email });
          enqueueSnackbar(data, { variant: "success" });
          closeDialog();
        } else enqueueSnackbar(data, { variant: "error" });
      },
      () => {
        setPayload(values);
        setStep("verification");
      }
    );
  }, []);

  return step === "edit" ? (
    <Formik
      initialValues={{ email }}
      onSubmit={async ({ email }) => {
        await validateEmail(email);
        setPayload({ email, code: "" });
        setStep("otp");
      }}
      validationSchema={schema}
    >
      <FormikForm width="100%">
        <Stack spacing={2}>
          <FormikTextField
            name="email"
            id="email"
            type="email"
            fullWidth
            label="Correo electrónico:"
            variant="outlined"
            required
          />

          <FormikSubmitButton fullWidth variant="contained">
            Continuar
          </FormikSubmitButton>
        </Stack>
      </FormikForm>
    </Formik>
  ) : step === "otp" ? (
    <OtpValidationForm
      channel="email"
      channelDisplay="correo"
      to={payload.email}
      expiryTime={600}
      onSubmit={onEmailValidationSubmit}
    />
  ) : (
    <ProfileToken
      secretQuestions={profile.securityQuestions}
      onSubmit={async () => {
        await changeEmail(payload);
      }}
    />
  );

  async function onEmailValidationSubmit(
    { code }: { code: OtpValidationData["code"] },
    { setSubmitting }: FormikHelpers<{ code: string }>
  ) {
    try {
      await changeEmail({ email: payload.email, code });
    } catch (error) {
      handleError(error);
    }

    setSubmitting(false);
  }
}
