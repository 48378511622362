import { Clear } from "@mui/icons-material";
import { CssBaseline, IconButton, ThemeProvider, createTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Dashboard, { DashboardProps } from "Layout/Dashboard/Dashboard";
import Associate from "Pages/Associate/Associate";
import { CertificateDetails } from "Pages/CertificateDetails/CertificateDetails";
import { CertificateRequest } from "Pages/CertificateRequest/CertificateRequest";
import Contact from "Pages/Contact/Contact";
import ForgotPassword from "Pages/ForgotPassword/ForgotPassword";
import { LoanDetails } from "Pages/LoanDetails/LoanDetails";
import { LoanRequest } from "Pages/LoanRequest/LoanRequest";
import Login from "Pages/Login/Login";
import { NotFound } from "Pages/NotFound/NotFound";
import { ProductRequests } from "Pages/ProductRequests/ProductRequests";
import { Products } from "Pages/Products/Products";
import Registration from "Pages/Registration/Registration";
import { RequestSignature } from "Pages/RequestSignature/RequestSignature";
import { RequestSignatureVerification } from "Pages/RequestSignatureVerification/RequestSignatureVerification";
import { RequestStatus } from "Pages/RequestStatus/RequestStatus";
import { SavingDetails } from "Pages/SavingDetails/SavingDetails";
import { SavingsAccountRequest } from "Pages/SavingsAccountRequest/SavingsAccountRequest";
import { SavingsEditRequest } from "Pages/SavingsEditRequest/SavingsEditRequest";
import { SavingsPlanRequest } from "Pages/SavingsPlanRequest/SavingsPlanRequest";
import { WithdrawalRequest } from "Pages/WithdrawalRequest/WithdrawalRequest";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { createRef } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import baselineOverrides from "./baselineOverrides.css";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#315500",
        light: "#547725",
      },
    },
    typography: {
      fontFamily: ['"Helvetica Neue"', "Roboto", "Arial", "sans-serif"].join(","),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: baselineOverrides,
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontWeight: 700,
          },
        },
      },
    },
  },
  esES
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

export function App() {
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    (notistackRef.current as SnackbarProvider).closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <SnackbarProvider
          maxSnack={3}
          ref={notistackRef}
          hideIconVariant
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)} aria-label="remover alerta" component="span">
              <Clear />
            </IconButton>
          )}
        >
          <Router>
            <Routes>
              <Route path="/" element={<LoggedIn Element={Products} />} />
              <Route path="/productos" element={<LoggedIn Element={Products} />} />
              <Route path="/solicitudes" element={<LoggedIn Element={ProductRequests} />} />
              <Route path="/solicitudes/estados" element={<LoggedIn Element={RequestStatus} />} />
              <Route
                path="/solicitudes/firma"
                element={<LoggedIn Element={RequestSignature as () => JSX.Element} enableOnSignature />}
              />
              <Route path="/solicitudes/firma/verificar" element={<RequestSignatureVerification />} />
              <Route path="/solicitudes/financiamiento" element={<LoggedIn Element={LoanRequest} />} />
              <Route path="/solicitudes/certificado" element={<LoggedIn Element={CertificateRequest} />} />
              <Route path="/solicitudes/cuenta" element={<LoggedIn Element={SavingsAccountRequest} />} />
              <Route path="/solicitudes/plan-de-ahorro" element={<LoggedIn Element={SavingsPlanRequest} />} />
              <Route path="/solicitudes/editar-cuenta" element={<LoggedIn Element={SavingsEditRequest} />} />
              <Route path="/retiro/solicitud" element={<LoggedIn Element={WithdrawalRequest} />} />
              <Route path="/ahorro/:number" element={<LoggedIn Element={SavingDetails} />} />
              <Route path="/certificado/:number" element={<LoggedIn Element={CertificateDetails} />} />
              <Route path="/prestamo/:number" element={<LoggedIn Element={LoanDetails} />} />
              <Route path="/contacto" element={<LoggedIn Element={Contact} enableOnSignature />} />
              <Route path="/socio" element={<LoggedIn Element={Associate} enableOnSignature />} />
              <Route path="/acceso" element={<Login />} />
              <Route path="/restaurar-acceso/:uid/:token" element={<ForgotPassword />} />
              <Route path="/registro" element={<Registration />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

function LoggedIn({ Element, enableOnSignature = false }: DashboardProps & { Element: () => JSX.Element }) {
  return (
    <Dashboard enableOnSignature={enableOnSignature}>
      <Element />
    </Dashboard>
  );
}
