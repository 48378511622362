import { DecoderType, string, constant, Decoder, either, object } from "decoders";
import { JsendResponse, jsendResponseDecoder } from "Utils/jsend";
import * as Yup from "yup";
import { clientIdValidation, emailValidation, requiredValidation } from "Utils/validations";
import { SecretQuestionData } from "Shared/SecretQuestionsForm/interfaces";

export interface ForgotPasswordDialogData {
  clientId: string;
  email: string;
}

export interface ForgotPasswordData {
  status: "loading" | "secret-questions" | "restore-password";
  secretQuestions?: SecretQuestionData[];
  uid?: string;
  token?: string;
  newPassword?: string;
  newPasswordConfirm?: string;
}

export interface RestorePasswordData {
  newPassword: string;
  newPasswordConfirm: string;
}

export interface SecretQuestionsResponseFail {
  errorKey: "invalidResetPasswordToken";
  message: string;
}

const restorePasswordResponseFailDecoder = object({
  errorKey: either(constant("invalidSecretQuestions"), constant("invalidPassword")),
  message: string,
});

export type RestorePasswordResponseFail = DecoderType<typeof restorePasswordResponseFailDecoder>;

export const forgotPasswordDialogDataKey: ["clientId", "email"] = ["clientId", "email"];

export const restorePasswordDataKey: ["newPassword", "newPasswordConfirm"] = ["newPassword", "newPasswordConfirm"];

export type ForgotPasswordResponse = JsendResponse<string, string>;

export const forgotPasswordResponseDecoder: Decoder<ForgotPasswordResponse> = jsendResponseDecoder(string, string);

export type RestorePasswordResponse = JsendResponse<string, RestorePasswordResponseFail>;

export const restorePasswordResponseDecoder: Decoder<RestorePasswordResponse> = jsendResponseDecoder(
  string,
  restorePasswordResponseFailDecoder
);

export const clientIdDataSchema = Yup.object({
  [forgotPasswordDialogDataKey[0]]: clientIdValidation,
});

export const emailDataSchema = Yup.object({
  [forgotPasswordDialogDataKey[1]]: emailValidation,
});

export const restorePasswordDataSchema = Yup.object({
  [restorePasswordDataKey[0]]: requiredValidation,
  [restorePasswordDataKey[1]]: requiredValidation,
});
