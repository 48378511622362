import { StepConnector, stepConnectorClasses, StepIconProps, styled } from "@mui/material";

export const Connector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: "17px",
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#fff",
    borderRadius: 1,
  },
}));

const StepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  border: "2px solid #fff",
  zIndex: 1,
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "37px",
  height: "37px",
  borderRadius: "50%",
  fontWeight: 700,
  ...(ownerState.active && {
    backgroundColor: "#fff",
    color: theme.palette.primary.light,
  }),
  ...(ownerState.completed && {
    backgroundColor: "#fff",
    color: theme.palette.primary.light,
  }),
}));

export function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ active, completed }} className={className}>
      {props.icon}
    </StepIconRoot>
  );
}
