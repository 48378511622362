import {
  AppBar,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import LocationIcon from "Icons/LocationIcon";
import MailIcon from "Icons/MailIcon";
import PhoneIcon from "Icons/PhoneIcon";
import TelegramIcon from "Icons/TelegramIcon";
import WhatsAppIcon from "Icons/WhatsAppIcon";
import WifiIcon from "Icons/WifiIcon";

const ContactItemIcon = styled(ListItemIcon)({
  minWidth: "30px",
});

export default function Contact() {
  const theme = useTheme();

  const primaryTypographyProps = {
    color: theme.palette.primary.light,
    fontWeight: "bold",
  };
  return (
    <Stack component={Card} sx={{ backgroundColor: "#F9F9F7 !important" }} elevation={0}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <Typography component="h2">
            <strong>Contactos</strong>
          </Typography>
        </Toolbar>
      </AppBar>
      <List sx={{ color: "#315500", fontWeight: "bold", px: "16px", pb: "20px" }}>
        <ListItem disablePadding>
          <ListItemButton component="a" disableGutters href="tel:+18096890000">
            <ContactItemIcon>
              <PhoneIcon />
            </ContactItemIcon>
            <ListItemText primaryTypographyProps={primaryTypographyProps} primary="809-689-0000" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component="a" disableGutters href="https://wa.me/+18096890000" target="_blank" rel="noopener">
            <ContactItemIcon>
              <WhatsAppIcon />
            </ContactItemIcon>
            <ListItemText primaryTypographyProps={primaryTypographyProps} primary="809-689-0000" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            disableGutters
            href="https://t.me/CoopBarcelonaBot"
            target="_blank"
            rel="noopener"
          >
            <ContactItemIcon>
              <TelegramIcon />
            </ContactItemIcon>
            <ListItemText primaryTypographyProps={primaryTypographyProps} primary="CoopbarcelonaBot" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component="a"
            disableGutters
            href="https://linktr.ee/coopbarcelona"
            target="_blank"
            rel="noopener"
          >
            <ContactItemIcon>
              <WifiIcon />
            </ContactItemIcon>
            <ListItemText primaryTypographyProps={primaryTypographyProps} primary="@coopbarcelona" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component="a" disableGutters href="mailto:info@coopbarcelona.com">
            <ContactItemIcon>
              <MailIcon />
            </ContactItemIcon>
            <ListItemText primaryTypographyProps={primaryTypographyProps} primary="info@coopbarcelona.com" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ContactItemIcon>
            <LocationIcon />
          </ContactItemIcon>
          <ListItemText
            primaryTypographyProps={primaryTypographyProps}
            primary="Edificio MBC Av. Pedro Henríquez Ureña, No. 35, Gazcue, Santo Domingo, República Dominicana"
          />
        </ListItem>
      </List>
    </Stack>
  );
}
