import { Clear } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  debounce,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { searchInstitution } from "Services/api/institutions/institutions";
import { Institution, Institutions } from "Services/api/institutions/interfaces";
import { Choices } from "Services/api/interfaces";
import {
  IncomeSourceChoices,
  RegistrationModel,
  StartRegistrationRequest,
  StartRegistrationResponseFail,
} from "Services/api/register/interfaces";
import { getIncomeSourceChoices, resetRegistration, startRegistration } from "Services/api/register/register";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import ClientIdInput from "Shared/ClientIdInput/ClientIdInput";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSelect from "Shared/FormikSelect/FormikSelect";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { LabeledTextField } from "Shared/LabeledTextField/LabeledTextField";
import { Query } from "Shared/Query/Query";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { clientIdValidation, requiredValidation } from "Utils/validations";
import { Formik, useField } from "formik";
import { useSnackbar } from "notistack";
import { Dispatch, Fragment, useEffect, useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useRegistrationContext } from "../Registration";
import { debounceTime } from "./debounceTime";

const clientVerificationKeys: ["clientId", "incomeSource", "name", "rnc"] = ["clientId", "incomeSource", "name", "rnc"];

export default function ClientVerification(): JSX.Element {
  return (
    <SplitFormContainer
      title="Solicitud de afiliación"
      sideInfoTop={<AccessInfo />}
      form={<ClientVerificationForm />}
    />
  );
}

const initialValues: StartRegistrationRequest = {
  [clientVerificationKeys[0]]: "",
  [clientVerificationKeys[1]]: "",
  [clientVerificationKeys[2]]: "",
  [clientVerificationKeys[3]]: "",
};

function ClientVerificationForm(): JSX.Element {
  const [openInactiveDialog, setOpenInactiveDialog] = useState(false);
  const [openResumeDialog, setOpenResumeDialog] = useState(false);
  const [resumableData, setResumableData] = useState<RegistrationModel>();

  const onClientVerificationSubmit = useOnClientVerificationSubmit(
    setResumableData,
    setOpenResumeDialog,
    setOpenInactiveDialog
  );

  return (
    <Stack spacing={2} width="100%">
      <ClientForm
        {...{
          openResumeDialog,
          setOpenResumeDialog,
          resumableData,
          onClientVerificationSubmit,
        }}
      />
      <InactiveAffiliatedClientDialog open={openInactiveDialog} onClose={() => setOpenInactiveDialog(false)} />
    </Stack>
  );
}

function useOnClientVerificationSubmit(
  setResumableData: Dispatch<RegistrationModel>,
  setOpenResumeDialog: Dispatch<boolean>,
  setOpenInactiveDialog: Dispatch<boolean>
) {
  const { enqueueSnackbar } = useSnackbar();
  const [, setRegContext] = useRegistrationContext();
  const handleFail = useHandleFail(setOpenInactiveDialog);

  return async function onClientVerificationSubmit(
    values: StartRegistrationRequest,
    setSubmitting: (isSubmitting: boolean) => void,
    enableResume = true
  ) {
    try {
      const { status, data } = await startRegistration(values);

      if (status === "success") {
        if (enableResume && data.isResumable) {
          setResumableData(data);
          setOpenResumeDialog(true);
        } else setRegContext(data);
      } else handleFail(data, values.clientId);
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error en la comunicación con el servidor", { variant: "error" });
      console.error(error);
    }
    setSubmitting(false);
  };
}

interface ClientFormProps {
  openResumeDialog: boolean;
  setOpenResumeDialog: Dispatch<boolean>;
  resumableData?: RegistrationModel;
  onClientVerificationSubmit(
    values: StartRegistrationRequest,
    setSubmitting: (isSubmitting: boolean) => void,
    enableResume?: boolean
  ): Promise<void>;
}

function ClientForm(props: ClientFormProps) {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getIncomeSourceChoices.name],
    queryFn: async () => {
      try {
        const response = await getIncomeSourceChoices();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Error obteniendo opciones de fuentes de ingreso", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Query
      result={result}
      OnLoading={() => (
        <Fragment>
          <Stack spacing={1} width="100%">
            <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
            <Skeleton variant="rectangular" width={"100%"} height={56} />
          </Stack>
          <Stack spacing={1} width="100%">
            <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
            <Skeleton variant="rectangular" width={"100%"} height={56} />
          </Stack>
        </Fragment>
      )}
      onError={() => <>{GENERIC_ERROR_MESSAGE}</>}
      onSuccess={(data) => <Form {...props} {...data} />}
    />
  );
}

function Form({
  openResumeDialog,
  setOpenResumeDialog,
  resumableData,
  onClientVerificationSubmit,
  salaried,
  nonSalaried,
}: ClientFormProps & IncomeSourceChoices) {
  const [, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  const clientVerificationSchema = useGetSchema(salaried, nonSalaried);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={clientVerificationSchema}
      onSubmit={(values, { setSubmitting }) => onClientVerificationSubmit(values, setSubmitting)}
    >
      <FormikForm width="100%">
        <ClientIdInput name={clientVerificationKeys[0]} id={clientVerificationKeys[0]} />
        <IncomeSourceSelect salaried={salaried} nonSalaried={nonSalaried} />
        <SalariedFields salariedOptions={salaried} />

        <ResumeRegistrationDialog
          open={openResumeDialog}
          onClose={() => setOpenResumeDialog(false)}
          onReset={async () => {
            try {
              if (!resumableData) throw new Error("Ha ocurrido un error");
              const { data } = await resetRegistration(resumableData.id);

              setRegContext(data);
            } catch (error) {
              enqueueSnackbar("Ha ocurrido un error en la comunicación con el servidor", { variant: "error" });
              console.error(error);
            }
            setOpenResumeDialog(false);
          }}
          onResume={() => {
            if (resumableData) setRegContext(resumableData);
            else enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
            setOpenResumeDialog(false);
          }}
        />
        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );
}

function IncomeSourceSelect({ salaried, nonSalaried }: IncomeSourceChoices) {
  return (
    <FormikSelect id={clientVerificationKeys[1]} name={clientVerificationKeys[1]} label="Fuente de ingresos:" required>
      {salaried.map(([value, display]) => (
        <MenuItem key={value} value={value}>
          {display}
        </MenuItem>
      ))}
      {nonSalaried.map(([value, display]) => (
        <MenuItem key={value} value={value}>
          {display}
        </MenuItem>
      ))}
    </FormikSelect>
  );
}

function useGetSchema(salariedOptions: Choices, nonSalariedOptions: Choices) {
  const salariedOptionsValues = salariedOptions.map((value) => value[0]);
  const nonSalariedOptionsValues = nonSalariedOptions.map((value) => value[0]);
  const incomeSourceOptionValues = [...salariedOptionsValues, ...nonSalariedOptionsValues];

  return Yup.object({
    [clientVerificationKeys[0]]: clientIdValidation,
    [clientVerificationKeys[1]]: requiredValidation.oneOf(incomeSourceOptionValues),
    [clientVerificationKeys[2]]: Yup.string().test({
      name: "required",
      message: "Requerido",
      test: (value, { parent: { incomeSource } }) => {
        const isSalaried = salariedOptionsValues.some((option) => option === incomeSource);

        return (isSalaried && Boolean(value)) || !isSalaried;
      },
    }),
    [clientVerificationKeys[3]]: Yup.string()
      .test({
        name: "required",
        message: "Requerido",
        test: (value, { parent: { incomeSource } }) => {
          const isSalaried = salariedOptionsValues.some((option) => option === incomeSource);

          return (isSalaried && Boolean(value)) || !isSalaried;
        },
      })
      .test({
        name: "rnc",
        message: "RNC invalido",
        test: (value, { parent: { incomeSource } }) => {
          const isSalaried = salariedOptionsValues.some((option) => option === incomeSource);
          const regex = /^[0-9]{1}-?[0-9]{2}-?[0-9]{5}-?[0-9]{1}$/g;

          return (isSalaried && Boolean(value?.match(regex))) || !isSalaried;
        },
      }),
  });
}

function SalariedFields({ salariedOptions }: { salariedOptions: Choices }) {
  const salariedOptionsValues = salariedOptions.map((value) => value[0]);
  const [{ value: incomeSource }] = useField<string>("incomeSource");
  const [, , { setValue: setName }] = useField<string>("name");
  const [{ value: rnc }, , { setValue: setRnc }] = useField<string>("rnc");
  const isSalaried = salariedOptionsValues.some((value) => value === incomeSource);
  const [search, setSearch] = useState<Institution | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState<Institutions>([]);
  const [manualInput, setManualInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetch = useMemo(
    () =>
      debounce((input: string, callback: (results?: Institutions) => void) => {
        void searchInstitution(input).then((response) => {
          const { status, data } = response;
          if (status === "fail") {
            enqueueSnackbar("Ha ocurrido un error en la busqueda", { variant: "error" });
            console.error(data);
            return callback([]);
          }

          callback(data);
        });
      }, debounceTime),
    [enqueueSnackbar]
  );

  useEffect(() => {
    let active = true;
    setLoading(true);

    if (searchValue === "" || searchValue.length <= 3) {
      setOptions(search ? [search] : []);
      setLoading(false);
      return undefined;
    }

    fetch(searchValue, (results?: Institutions) => {
      if (active) {
        let newOptions: Institutions = [];

        if (search) {
          newOptions = [search];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [search, searchValue, fetch]);

  if (!isSalaried) return <></>;

  return (
    <Fragment>
      {manualInput ? (
        <Fragment>
          <Button
            variant="outlined"
            onClick={() => {
              setName("");
              setRnc("");
              setManualInput(false);
            }}
          >
            Buscar empresa
          </Button>
          <FormikTextField
            name="name"
            id="name"
            fullWidth
            label="Nombre de tu empresa:"
            placeholder="Digitar nombre de tu empresa"
            required
          />
          <FormikTextField
            name="rnc"
            id="rnc"
            fullWidth
            label="RNC de tu empresa:"
            placeholder="Digitar RNC sin guiones"
            required
          />
        </Fragment>
      ) : (
        <Fragment>
          <Autocomplete
            id="searchCompany"
            getOptionLabel={(option) => (typeof option === "string" ? option : `${option.name} - ${option.rnc}`)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={search}
            noOptionsText={
              loading ? (
                "Buscando..."
              ) : (
                <Stack spacing={2}>
                  <Typography>
                    {searchValue.length <= 3
                      ? "Escribir al menos cuatro (4) caracteres"
                      : "No se encontraron resultados..."}
                  </Typography>
                </Stack>
              )
            }
            onChange={(_, newValue: Institution | null) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setSearch(newValue);
              setName(newValue?.name || "");
              setRnc(newValue?.rnc || "");
            }}
            onInputChange={(_, newInputValue) => {
              setSearchValue(newInputValue);
            }}
            renderInput={(params) => (
              <LabeledTextField {...params} label="Empresa/RNC:" fullWidth placeholder="Buscar" />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  {option.name} - {option.rnc}
                </li>
              );
            }}
          />
          {!rnc && (
            <Button
              variant="outlined"
              onClick={() => {
                setName("");
                setRnc("");
                setManualInput(true);
              }}
            >
              No encuentro mi empresa
            </Button>
          )}
        </Fragment>
      )}

      {search && !manualInput && (
        <Fragment>
          <FormikTextField name="name" id="name" fullWidth label="Nombre de tu empresa:" required disabled />
          <FormikTextField name="rnc" id="rnc" fullWidth label="RNC de tu empresa:" required disabled />
        </Fragment>
      )}
    </Fragment>
  );
}

function useHandleFail(setOpenDialog: Dispatch<boolean>) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return function handleFail(data: StartRegistrationResponseFail, clientId: string) {
    switch (data.errorKey) {
      case "nonActiveAffiliatedClient":
        setOpenDialog(true);
        enqueueSnackbar(data.message);
        return;

      case "affiliatedClientWithAccess":
        navigate({
          pathname: "/acceso/",
          search: createSearchParams({
            client: clientId,
          }).toString(),
        });
        enqueueSnackbar(data.message);
        return;

      default:
        throw { error: "Non contemplated failure", data };
    }
  };
}

interface InactiveAffiliatedClientDialogProps {
  open: boolean;
  onClose: () => void;
}

function InactiveAffiliatedClientDialog(props: InactiveAffiliatedClientDialogProps) {
  const { onClose, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Socio Inactivo</DialogTitle>

      <DialogContent>
        <p>Al parecer ya estas asociado en nuestra cooperativa pero tu afiliación no esta activa.</p>
        <p>
          Puedes dirigirte a{" "}
          <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/contactanos/">
            <strong>nuestra pagina de contacto</strong>
          </Link>{" "}
          y solicitar ayuda en cualquiera de las vias de contacto que ofrecemos.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}

interface ResumeRegistrationDialogProps {
  open: boolean;
  onClose: () => void;
  onReset: () => Promise<void>;
  onResume: () => void;
}

function ResumeRegistrationDialog(props: ResumeRegistrationDialogProps) {
  const { onClose, onReset, onResume, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <Stack component={DialogTitle} direction={"row"} justifyContent="space-between" alignItems={"center"}>
        ¿Resumir registro?
        <IconButton onClick={onClose} sx={{ color: "#000" }}>
          <Clear />
        </IconButton>
      </Stack>
      <DialogContent>
        <p>Al parecer ya habias iniciado un proceso de registro.</p>
        <p>
          Puedes volver a iniciar un proceso nuevo o resumir el anterior dando click a una de las opciones de abajo.
        </p>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Button onClick={() => void onReset()} variant="text">
          Iniciar nuevamente
        </Button>
        <Button onClick={onResume} variant="contained">
          Resumir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
