import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS_TOKEN } from "../config";
import {
  BankAccountOptionsResponse,
  bankAccountOptionsResponseDecoder,
  ChangeAddressRequest,
  changeAddressResponseDecoder,
  ChangeEmailRequest,
  changeEmailResponseDecoder,
  ChangePasswordRequest,
  changePasswordResponseDecoder,
  ChangePhoneRequest,
  changePhoneResponseDecoder,
  ChangeProfileResponse,
  ChangeQuestionsRequest,
  changeQuestionsResponseDecoder,
  ContactResponse,
  contactResponseDecoder,
  ProfileResponse,
  profileResponseDecoder,
  ProfileTokenRequest,
  ProfileTokenResponse,
  profileTokenResponseDecoder,
  ProfileTokenVerifyResponse,
  profileTokenVerifyResponseDecoder,
  SavingsAccountsResponse,
  savingsAccountsResponseDecoder,
} from "./interfaces";

export async function getProfile(): Promise<ProfileResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/me/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return profileResponseDecoder.verify(await response.json());
}

export async function getContact(): Promise<ContactResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/contact/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return contactResponseDecoder.verify(await response.json());
}

export async function verifyToken(token: string): Promise<ProfileTokenVerifyResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/verify/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "POST",
    body: JSON.stringify({ token }),
  });

  return profileTokenVerifyResponseDecoder.verify(await response.json());
}

export async function createToken(data: ProfileTokenRequest): Promise<ProfileTokenResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/token/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "POST",
    body: JSON.stringify(data),
  });

  return profileTokenResponseDecoder.verify(await response.json());
}

export async function updateEmail(data: ChangeEmailRequest): Promise<ChangeProfileResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/email/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "POST",
    body: JSON.stringify(data),
  });

  return changeEmailResponseDecoder.verify(await response.json());
}

export async function updatePhone(data: ChangePhoneRequest): Promise<ChangeProfileResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/phone/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "POST",
    body: JSON.stringify(data),
  });

  return changePhoneResponseDecoder.verify(await response.json());
}

export async function updatePassword(data: ChangePasswordRequest): Promise<ChangeProfileResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/password/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "POST",
    body: JSON.stringify(data),
  });

  return changePasswordResponseDecoder.verify(await response.json());
}

export async function updateQuestions(data: ChangeQuestionsRequest): Promise<ChangeProfileResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/secret-questions/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "POST",
    body: JSON.stringify(data),
  });

  return changeQuestionsResponseDecoder.verify(await response.json());
}

export async function updateAddress(data: ChangeAddressRequest): Promise<ChangeProfileResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/detail/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "PATCH",
    body: JSON.stringify(data),
  });

  return changeAddressResponseDecoder.verify(await response.json());
}

export async function getSavingAccounts(
  include: string[] = [],
  exclude: string[] = []
): Promise<SavingsAccountsResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/savings-accounts/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "POST",
    body: JSON.stringify({
      include,
      exclude,
    }),
  });

  return savingsAccountsResponseDecoder.verify(await response.json());
}

export async function getBankAccounts(): Promise<BankAccountOptionsResponse> {
  const response = await enhancedFetch(`${API_URL}/profile/bank-accounts/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return bankAccountOptionsResponseDecoder.verify(await response.json());
}
