import Cookies from "js-cookie";

export const FETCH_DEFAULT_CONFIGS: RequestInit = {
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": getCSRFToken(),
  },
};

export const FETCH_DEFAULT_CONFIGS_TOKEN = (isJson = true): RequestInit => {
  const token = sessionStorage.getItem("token") || "";

  const headers: RequestInit["headers"] = { "X-CSRFToken": getCSRFToken() };
  if (isJson) headers["Content-Type"] = "application/json";
  if (token) headers["Authorization"] = `Bearer ${token}`;

  return {
    headers,
  };
};

export function getCSRFToken() {
  return Cookies.get("csrftoken") || "";
}
