import { AddressFormOptions } from "Services/api/crm/interfaces";
import { ClientFormData } from "Services/api/register/interfaces";
import { requiredValidation } from "Utils/validations";
import * as Yup from "yup";

export type AddressFormProps<T> = {
  options: AddressFormOptions;
  onBack?: () => void;
  onSubmit: (values: T, form: keyof ClientFormData) => void | Promise<void>;
  initialValues: T;
};

export const addressSimplifed = {
  country: requiredValidation,
  region: requiredValidation,
  province: requiredValidation,
  city: requiredValidation,
  sector: requiredValidation,
  line1: requiredValidation,
};

export const addressSimplifiedSchema = Yup.object(addressSimplifed);
