import { FormHelperText, Slider, SliderProps, Stack, Typography } from "@mui/material";
import { useField } from "formik";

interface FormikSliderProps extends SliderProps {
  name: string;
  label: string;
  hideSlider?: boolean;
  formatValue?: (value: string) => string;
}

export function FormikSlider(props: FormikSliderProps) {
  const { id, name, label, formatValue, hideSlider, ...rest } = props;
  let { sx = {} } = props;
  const [{ value }, { error }, { setValue }] = useField<number>(name);
  if (hideSlider) sx = { ...sx, display: "none" };

  return (
    <Stack spacing={1} width="100%">
      <label htmlFor={id}>{label}</label>
      <Typography variant="subtitle1">{formatValue ? formatValue(value.toString()) : value}</Typography>
      <Slider
        id={id}
        name={name}
        onChange={(_, value) => {
          if (typeof value === "number") setValue(value);
        }}
        value={value}
        {...rest}
        sx={sx}
      />
      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
    </Stack>
  );
}
