import { SVGProps } from "react";

export default function Stop(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 0 24 24" width="48px" fill="#d32f2f" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M6 6h12v12H6z" />
    </svg>
  );
}
