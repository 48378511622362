import { SVGProps } from "react";

export default function SavingsPlanIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_924_12066)">
        <path
          d="M40.0176 29.8605L36.9449 26.7884C36.8062 26.6497 36.6182 26.5726 36.4209 26.5726H35.9186C36.7692 25.4847 37.2746 24.1168 37.2746 22.6283C37.2746 19.0876 34.4052 16.2189 30.8642 16.2189C27.3233 16.2189 24.4536 19.0876 24.4536 22.6283C24.4536 26.1688 27.323 29.0377 30.8642 29.0377C32.3527 29.0377 33.7213 28.5323 34.8092 27.6818V28.184C34.8092 28.3813 34.8862 28.5693 35.0249 28.708L38.0977 31.7802C38.3874 32.0698 38.8558 32.0698 39.1424 31.7802L40.0146 30.908C40.3042 30.6184 40.3042 30.1502 40.0176 29.8605ZM30.8642 27.0654C28.413 27.0654 26.4266 25.0826 26.4266 22.6283C26.4266 20.1773 28.4095 18.1912 30.8642 18.1912C33.3155 18.1912 35.3019 20.174 35.3019 22.6283C35.3019 25.0791 33.3188 27.0654 30.8642 27.0654ZM31.6996 22.3645L30.3126 21.9485C30.1537 21.9007 30.0424 21.7396 30.0424 21.5562C30.0424 21.3321 30.2056 21.1496 30.4059 21.1496H31.2723C31.4128 21.1496 31.5483 21.1895 31.6674 21.2644C31.7672 21.3268 31.8941 21.3232 31.9795 21.2418L32.3416 20.8963C32.4503 20.7925 32.4442 20.6125 32.3241 20.5223C32.0436 20.3117 31.7053 20.1903 31.3573 20.1725V19.6704C31.3573 19.5342 31.247 19.4239 31.1108 19.4239H30.6177C30.4815 19.4239 30.3712 19.5342 30.3712 19.6704V20.1672C29.6429 20.1866 29.0558 20.8005 29.0558 21.5562C29.0558 22.1716 29.4562 22.7213 30.0291 22.8934L31.4161 23.3094C31.5752 23.3571 31.6865 23.5184 31.6865 23.7017C31.6865 23.9256 31.5231 24.1082 31.3228 24.1082H30.4568C30.3163 24.1082 30.1807 24.0685 30.0618 23.9936C29.9618 23.9309 29.8349 23.9346 29.7496 24.016L29.3875 24.3614C29.2787 24.4653 29.2848 24.6453 29.4051 24.7356C29.6854 24.9461 30.0238 25.0675 30.3717 25.0854V25.5874C30.3717 25.7236 30.4822 25.834 30.6184 25.834H31.1113C31.2477 25.834 31.358 25.7236 31.358 25.5874V25.0906C32.0861 25.0711 32.6732 24.4576 32.6732 23.7017C32.6732 23.0862 32.273 22.5365 31.6996 22.3645Z"
          fill="#FFFFFF"
        />
        <path
          d="M23.8264 0.00828477C23.7484 0.0223834 23.6713 0.0415 23.5957 0.0655156C23.1788 0.158934 22.8075 0.39399 22.5445 0.730627C22.2816 1.06727 22.1434 1.48465 22.1539 1.91166V3.75782H5.53846C4.06958 3.75782 2.66083 4.34134 1.62218 5.38C0.583515 6.41867 0 7.82739 0 9.29629V33.2962C0 36.3534 2.48122 38.8348 5.53846 38.8348H22.1539V39.5271L12.1145 44.5468C11.6708 44.7609 11.3305 45.1426 11.1683 45.6078C11.0061 46.0729 11.0353 46.5834 11.2495 47.027C11.4638 47.4707 11.8454 47.811 12.3106 47.9733C12.7757 48.1354 13.2862 48.1061 13.7298 47.892L22.1539 43.6808V44.3733C22.1539 44.8629 22.3484 45.3324 22.6945 45.6786C23.0408 46.0249 23.5104 46.2193 24 46.2193C24.4896 46.2193 24.9592 46.0249 25.3055 45.6786C25.6516 45.3324 25.8461 44.8629 25.8461 44.3733V43.6808L34.2702 47.892C34.7138 48.1061 35.2243 48.1354 35.6894 47.9733C36.1546 47.811 36.5363 47.4707 36.7504 47.027C36.9646 46.5834 36.9939 46.0729 36.8318 45.6078C36.6695 45.1426 36.3292 44.7609 35.8856 44.5468L25.8461 39.5271V38.8348H42.4616C45.5188 38.8348 48 36.3534 48 33.2962V9.29629C48 7.82739 47.4165 6.41867 46.3778 5.38C45.3392 4.34134 43.9305 3.75782 42.4616 3.75782H25.8461V1.91166C25.8555 1.64914 25.8087 1.38765 25.7089 1.14466C25.6091 0.90167 25.4584 0.68278 25.2673 0.502614C25.0761 0.322448 24.8487 0.185156 24.6002 0.0999102C24.3518 0.0146637 24.088 -0.0165738 23.8264 0.00828477ZM3.6923 9.29629H44.3076V33.2962H3.6923V9.29629Z"
          fill="#FFFFFF"
        />
        <path
          d="M38.9034 15.0563C39.2703 15.0563 39.5678 14.7588 39.5678 14.3919C39.5678 14.025 39.2703 13.7275 38.9034 13.7275V15.0563ZM8.34283 15.0563H38.9034V13.7275H8.34283V15.0563Z"
          fill="#FFFFFF"
        />
        <path
          d="M27.4432 18.7102C27.8101 18.7102 28.1076 18.4127 28.1076 18.0458C28.1076 17.6789 27.8101 17.3815 27.4432 17.3815V18.7102ZM8.34283 18.7102H27.4432V17.3815H8.34283V18.7102Z"
          fill="#FFFFFF"
        />
        <path
          d="M25.6162 22.5303C25.9831 22.5303 26.2806 22.2328 26.2806 21.8659C26.2806 21.499 25.9831 21.2015 25.6162 21.2015V22.5303ZM8.34283 22.5303H25.6162V21.2015H8.34283V22.5303Z"
          fill="#FFFFFF"
        />
        <path
          d="M25.6162 26.3503C25.9831 26.3503 26.2806 26.0529 26.2806 25.686C26.2806 25.3191 25.9831 25.0216 25.6162 25.0216V26.3503ZM8.34283 26.3503H25.6162V25.0216H8.34283V26.3503Z"
          fill="#FFFFFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_924_12066">
          <rect width="48" height="48.1661" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
