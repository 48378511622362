import { Stack } from "@mui/material";
import { SectionTitle } from "Shared/styled";
import { PropsWithChildren } from "react";
import NavBar from "../NavBar/NavBar";
import SideInfoContainer from "./SideInfoContainer";

interface Props {
  title: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
  sideInfoTop?: React.ReactNode;
  form?: React.ReactNode;
}

export default function SplitFormContainer({
  title,
  before,
  after,
  sideInfoTop,
  form,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <NavBar>
      <SideInfoContainer
        sideInfo={
          <Stack spacing={2} direction="column" alignItems="start" height="100%" width="100%">
            {sideInfoTop}
          </Stack>
        }
      >
        {before}
        <Stack direction="column" justifyContent="center" alignItems="start" height="100%">
          <SectionTitle variant="h1">{title}</SectionTitle>
          {form}
        </Stack>
        {after}
      </SideInfoContainer>
    </NavBar>
  );
}
