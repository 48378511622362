import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { ForgotPasswordDialogModel } from "Pages/ForgotPassword/ForgotPasswordDialog/ForgotPasswordDialog";
import { Dispatch } from "react";
import { getMaskEmail } from "Services/api/mask/mask";
import ClientIdInput from "Shared/ClientIdInput/ClientIdInput";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { clientIdDataSchema, ForgotPasswordDialogData, forgotPasswordDialogDataKey } from "../interfaces";

export default function ClientIdForm({ setModel }: { setModel: Dispatch<ForgotPasswordDialogModel> }): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      initialValues={{ [forgotPasswordDialogDataKey[0]]: "" }}
      validationSchema={clientIdDataSchema}
      onSubmit={onValidateClientId}
    >
      <FormikForm width="100%">
        <p>Por favor introduce tu cédula.</p>
        <ClientIdInput
          name={forgotPasswordDialogDataKey[0]}
          id={forgotPasswordDialogDataKey[0]}
          autoComplete="username"
        />
        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );

  async function onValidateClientId(
    { clientId }: { clientId: ForgotPasswordDialogData["clientId"] },
    { setSubmitting }: FormikHelpers<{ clientId: ForgotPasswordDialogData["clientId"] }>
  ) {
    try {
      const { status, data } = await getMaskEmail(clientId);
      if (status === "success") {
        setModel({
          status: "email-validation",
          maskedEmail: data.email,
          clientId: clientId,
        });
      } else {
        enqueueSnackbar(data, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
      console.error(error);
    }

    setSubmitting(false);
  }
}
