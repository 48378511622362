import { SVGProps } from "react";

export default function MailIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.1091 0.862235C18.0279 0.853376 17.9461 0.853376 17.8649 0.862235H1.18487C1.07796 0.863979 0.971768 0.880952 0.869141 0.912696L9.47722 9.98929L18.1091 0.862235Z"
        fill="#547725"
      />
      <path
        d="M18.9914 1.73859L10.3178 10.8846C10.0946 11.1195 9.79258 11.2514 9.47782 11.2514C9.16306 11.2514 8.86109 11.1195 8.63787 10.8846L0.0417001 1.80797C0.0152738 1.91081 0.00126213 2.01678 0 2.12335V14.7385C0 15.0731 0.125525 15.3939 0.348962 15.6305C0.572398 15.8671 0.875443 16 1.19143 16H17.8714C18.1874 16 18.4905 15.8671 18.7139 15.6305C18.9374 15.3939 19.0629 15.0731 19.0629 14.7385V2.12335C19.0581 1.99193 19.034 1.86211 18.9914 1.73859ZM2.00756 14.7385H1.17952V13.8365L5.51036 9.28875L6.35032 10.1781L2.00756 14.7385ZM17.8595 14.7385H17.0255L12.6828 10.1781L13.5227 9.28875L17.8536 13.8365L17.8595 14.7385Z"
        fill="#547725"
      />
    </svg>
  );
}
