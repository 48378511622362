import { JsendResponse, jsendResponseDecoder } from "Utils/jsend";
import { Decoder, DecoderType, array, object, string } from "decoders";

const institutionDecoder = object({
  id: string,
  name: string,
  rnc: string,
});

export type Institution = DecoderType<typeof institutionDecoder>;

const institutionsDecoder = array(institutionDecoder);

export type Institutions = DecoderType<typeof institutionsDecoder>;

export type InstitutionsResponse = JsendResponse<Institutions, string>;

export const institutionsResponseDecoder: Decoder<InstitutionsResponse> = jsendResponseDecoder(
  institutionsDecoder,
  string
);
