import { ForgotPasswordDialogModel } from "Pages/ForgotPassword/ForgotPasswordDialog/ForgotPasswordDialog";
import { useSnackbar } from "notistack";
import { Formik, FormikHelpers } from "formik";
import { ForgotPasswordDialogData, forgotPasswordDialogDataKey, emailDataSchema } from "../interfaces";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import FormikForm from "Shared/FormikForm/FormikForm";
import { sendForgotPasswordEmail } from "Services/api/auth/auth";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

export default function MaskEmailForm({
  model,
  closeDialog,
}: {
  model: ForgotPasswordDialogModel;
  closeDialog: () => void;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      initialValues={{ [forgotPasswordDialogDataKey[1]]: "" }}
      validationSchema={emailDataSchema}
      onSubmit={onValidateEmail}
    >
      <FormikForm width="100%">
        <p>
          Por favor introduce tu correo de la forma <code>{model.maskedEmail}</code>{" "}
        </p>
        <FormikTextField
          name={forgotPasswordDialogDataKey[1]}
          id={forgotPasswordDialogDataKey[1]}
          type={"email"}
          fullWidth
          label="Correo electrónico:"
          variant="outlined"
          required
        />
        <FormikSubmitButton fullWidth variant="contained">
          Enviar
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );

  async function onValidateEmail(
    { email }: { email: ForgotPasswordDialogData["email"] },
    { setSubmitting }: FormikHelpers<{ email: ForgotPasswordDialogData["email"] }>
  ) {
    try {
      const { status, data } = await sendForgotPasswordEmail({
        clientId: model.clientId as string,
        email,
      });
      if (status === "success") {
        enqueueSnackbar("El correo de recuperación se ha enviado correctamente", { variant: "success" });
        closeDialog();
      } else {
        enqueueSnackbar(data, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
      console.error(error);
    }

    setSubmitting(false);
  }
}
