import { ErrorOutline } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, Grid, Link, Stack, Typography } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import ForgotPasswordDialog from "Pages/ForgotPassword/ForgotPasswordDialog/ForgotPasswordDialog";
import ClientWithAccessDialog from "Pages/Login/ClientWithAccessDialog";
import { BasicDialogProps, LoginData, loginDataKeys, loginDataSchema, LoginFormData } from "Pages/Login/interfaces";
import { Dispatch, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { getSecretQuestions, login } from "Services/api/auth/auth";
import ClientIdInput from "Shared/ClientIdInput/ClientIdInput";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import PasswordHide from "Shared/PasswordHide/PasswordHide";

export default function AuthenticateStep({
  model,
  setModel,
}: {
  model: LoginData;
  setModel: Dispatch<LoginData>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();
  const [openDialog, setOpenDialog] = useState(searchParams.has("client"));
  const [forgotPasswordDialog, setDialogForgotPasswordDialog] = useState(false);
  const [openNotApproved, setOpenNotApproved] = useState(false);

  return (
    <Stack spacing={6} width="100%">
      <Formik
        initialValues={{ [loginDataKeys[0]]: "", [loginDataKeys[1]]: "" }}
        validationSchema={loginDataSchema}
        onSubmit={onLoginSubmit}
      >
        <FormikForm width="100%">
          <ClientIdInput
            label="Número de identificación:"
            placeholder=""
            name={loginDataKeys[0]}
            id={loginDataKeys[0]}
            autoComplete="username"
          />
          <PasswordHide
            name={loginDataKeys[1]}
            id={loginDataKeys[1]}
            fullWidth
            autoComplete="current-password"
            label="Contraseña:"
            variant="outlined"
            required
          />

          <FormikSubmitButton fullWidth variant="contained">
            Acceder
          </FormikSubmitButton>
          <Grid container>
            <Grid item xs>
              <Link sx={{ cursor: "pointer" }} target="_blank" onClick={() => void setDialogForgotPasswordDialog(true)}>
                ¿Olvidaste tu contraseña?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/registro">
                ¿Aún no eres socio?, ¡Afíliate!
              </Link>
            </Grid>
          </Grid>
          <UserNotApprovedDialog open={openNotApproved} onClose={() => setOpenNotApproved(false)} />
          <ClientWithAccessDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            openForgotPassword={() => setDialogForgotPasswordDialog(true)}
          />
          <ForgotPasswordDialog open={forgotPasswordDialog} onClose={() => setDialogForgotPasswordDialog(false)} />
        </FormikForm>
      </Formik>
    </Stack>
  );

  async function onLoginSubmit(values: LoginFormData, { setSubmitting }: FormikHelpers<LoginFormData>) {
    try {
      const { status, data } = await login({ clientId: values.clientId, password: values.password });
      if (status === "success") {
        const { status: statusQuestions, data: secretQuestions } = await getSecretQuestions(
          {
            uid: data.uid,
            token: data.token,
          },
          "?type=login"
        );
        if (statusQuestions === "success") {
          setModel({
            ...model,
            secretQuestions,
            status: "secret-questions",
            uid: data.uid,
            token: data.token,
          });
          return;
        } else {
          enqueueSnackbar(secretQuestions.message, { variant: "error" });
        }
      } else {
        setOpenNotApproved(data.errorKey === "userNotApproved");
        enqueueSnackbar(data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }
    setSubmitting(false);
  }
}

function UserNotApprovedDialog(props: BasicDialogProps): JSX.Element {
  const { onClose, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <Stack sx={{ width: "100%", alignItems: "center", justifyContent: "center" }} spacing={2}>
          <ErrorOutline sx={{ fontSize: "7.5rem" }} color="primary" />
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 400, textAlign: "center" }}>
            Usuario en proceso de aprobación
          </Typography>
          <Typography sx={{ fontSize: "1rem", fontWeight: 400, textAlign: "center" }}>
            Favor de esperar a que el proceso de aprobación sea completado e intentar nuevamente.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={onClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
