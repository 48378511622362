import {
  Alert,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Formik, FormikHelpers, useField, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { OtpValidationData } from "Services/api/otp/interfaces";
import { validatePhone } from "Services/api/otp/otp";
import { confirmPhone, savePhone } from "Services/api/register/register";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { getFullPhoneFormat } from "Utils/getFullPhoneFormat";
import { phoneValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";
import { ActionButtons } from "../ActionButtons/ActionButtons";
import OtpValidation, { InputFormProps, ValidationFormProps } from "../OtpValidation/OtpValidation";
import OtpValidationForm from "../OtpValidation/OtpValidationForm";
import { useRegistrationContext } from "../Registration";

export default function CellphoneValidation(): JSX.Element {
  const [{ name }] = useRegistrationContext();
  const msg = name ? `${name}, por favor introduce tu número teléfonico.` : "Favor introducir tu número teléfonico.";

  return <OtpValidation title={msg} InputForm={CellphoneInputForm} ValidationForm={CellphoneValidationForm} />;
}
const phoneInputSchema = Yup.object({
  tel: phoneValidation,
  channel: requiredValidation,
});

function CellphoneInputForm({ setStatus, setTo }: InputFormProps): JSX.Element {
  const [{ id, isAffiliatedClient, partialNumber }] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [phoneError, setPhoneError] = useState("");

  return (
    <Formik
      initialValues={{ tel: "", channel: "sms" }}
      validationSchema={phoneInputSchema}
      onSubmit={async ({ tel, channel }, { setSubmitting }) => {
        setPhoneError("");
        try {
          if (isAffiliatedClient) {
            const { status, data } = await confirmPhone(id, getFullPhoneFormat(tel));

            if (status === "success") {
              setStatus("validation");
              setTo(tel);
              enqueueSnackbar(data, { variant: "success" });
            } else {
              setPhoneError(data);
              enqueueSnackbar(data, { variant: "error" });
            }
          } else {
            await validatePhone(getFullPhoneFormat(tel), channel);
            setStatus("validation");
            setTo(tel);
          }
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
          console.error(error);
        }

        setSubmitting(false);
      }}
    >
      <FormikForm width="100%">
        {isAffiliatedClient ? (
          <Typography>
            Introduzca el telefono <strong>{partialNumber}</strong> para confirmar que todavía se le puede contactar por
            esta via. Si no reconoce o no recuerda este numero puede{" "}
            <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/contactanos/">
              <strong>hacer click aqui para contactarnos.</strong>
            </Link>
          </Typography>
        ) : null}
        <FormikTextField
          name="tel"
          id="tel"
          type="tel"
          fullWidth
          label="Celular:"
          variant="outlined"
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">+1</InputAdornment>,
          }}
          inputProps={{
            maxLength: "10",
          }}
          help={
            <Alert severity="info" color="success" variant="outlined">
              Este número se utilizará para acceder a todos los servicios y productos de COOPBARCELONA, así como para
              verificar su validez.
            </Alert>
          }
        />
        <FormikChannel name="channel" />
        {phoneError ? <FormHelperText error>{phoneError}</FormHelperText> : null}

        <ActionButtons />
      </FormikForm>
    </Formik>
  );
}

function CellphoneValidationForm({ to, setStatus }: ValidationFormProps): JSX.Element {
  const [{ id }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <OtpValidationForm
      channel="sms"
      channelDisplay="teléfono movil"
      to={to}
      expiryTime={600}
      onSubmit={onCellphoneValidationSubmit}
      onBack={() => setStatus("input")}
    />
  );

  async function onCellphoneValidationSubmit(
    { code }: { code: OtpValidationData["code"] },
    { setSubmitting }: FormikHelpers<{ code: string }>
  ) {
    try {
      const response = await savePhone({
        id,
        to: getFullPhoneFormat(to),
        code,
      });

      if (response.status === "success") {
        enqueueSnackbar("Teléfono movil validado", { variant: "success" });
        setRegContext({ status: response.data.status });
      } else enqueueSnackbar(response.data, { variant: "error" });
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }
    setSubmitting(false);
  }
}

interface FormikChannelProps {
  name: string;
}

function FormikChannel(props: FormikChannelProps) {
  const [{ value }, , { setValue }] = useField<string>(props);
  const { isSubmitting, errors: formikErrors, touched } = useFormikContext<{ [k: string]: string }>();
  const formikError = Boolean(touched[props.name]) && Boolean(formikErrors[props.name]);

  const checkLabelStyle = {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "25px",
    letterSpacing: "0.02em",
    color: "#383838",
  };

  return (
    <FormControl component="fieldset" variant="standard" disabled={isSubmitting}>
      <FormLabel id={props.name}>Método de envio:</FormLabel>
      <RadioGroup
        aria-labelledby={props.name}
        value={value}
        name={props.name}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      >
        <FormControlLabel
          value="sms"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>SMS</Typography>}
        />

        <FormControlLabel
          value="whatsapp"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>WhatsApp</Typography>}
        />

        <FormControlLabel
          value="call"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>Llamada</Typography>}
        />
      </RadioGroup>
      {formikError ? <FormHelperText error> {formikErrors[props.name]}</FormHelperText> : null}
    </FormControl>
  );
}
