import { useState } from "react";
import { LoginData } from "./interfaces";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import AuthenticateStep from "Pages/Login/AuthenticateStep";
import SecretQuestionsStep from "Pages/Login/SecretQuestionsStep";
import logo from "../../../../public/logo_completo_blanco.png";
import { Stack } from "@mui/material";

const LoginView = {
  login: AuthenticateStep,
  "secret-questions": SecretQuestionsStep,
};

const defaultState: LoginData = {
  status: "login",
  secretQuestions: [],
};

export default function Login(): JSX.Element {
  return (
    <SplitFormContainer
      title="Inicio de Sesión"
      sideInfoTop={
        <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
          <img style={{ maxHeight: "400px" }} src={logo} alt="Logo Cooperativa Barcelona" />
        </Stack>
      }
      form={<LoginContainer />}
    />
  );
}

function LoginContainer(): JSX.Element {
  const [loginModel, setLoginModel] = useState<LoginData>(defaultState);
  const StepElement = LoginView[loginModel.status];

  return <StepElement model={loginModel} setModel={setLoginModel} />;
}
