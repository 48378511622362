import {
  Decoder,
  DecoderType,
  array,
  boolean,
  constant,
  either,
  email,
  nullable,
  number,
  object,
  string,
} from "decoders";
import { SecretQuestionData, secretQuestionDataDecoder } from "Shared/SecretQuestionsForm/interfaces";
import { JsendResponse, JsendSuccess, jsendResponseDecoder, jsendSuccessDecoder } from "Utils/jsend";

const securityQuestionsDecoder = array(secretQuestionDataDecoder);

const contact = {
  phone: string,
  email: email,
};

const contactDecoder = object(contact);

export type Contact = DecoderType<typeof contactDecoder>;

export const contactResponseDecoder = jsendSuccessDecoder(contactDecoder);

export type ContactResponse = DecoderType<typeof contactResponseDecoder>;

const profileDecoder = object({
  id: string,
  address: object({
    country: string,
    state: string,
    city: string,
    region: string,
    reference: string,
    sector: string,
  }),
  securityQuestions: securityQuestionsDecoder,
  ...contact,
});

export type Profile = DecoderType<typeof profileDecoder>;

export const profileResponseDecoder = jsendSuccessDecoder(profileDecoder);

export type ProfileResponse = DecoderType<typeof profileResponseDecoder>;

export type ProfileTokenVerifyResponse = JsendSuccess<boolean>;

export const profileTokenVerifyResponseDecoder: Decoder<ProfileTokenVerifyResponse> = jsendSuccessDecoder(boolean);

const confirmCredentialsResponseFailDecoder = object({
  errorKey: either(constant("invalidSecretQuestions"), constant("invalidCredentials")),
  message: string,
});

type ConfirmCredentialsResponseFail = DecoderType<typeof confirmCredentialsResponseFailDecoder>;
export interface ProfileTokenRequest {
  password: string;
  secretQuestions: SecretQuestionData[];
}

export type ProfileTokenResponse = JsendResponse<string, ConfirmCredentialsResponseFail>;

export const profileTokenResponseDecoder: Decoder<ProfileTokenResponse> = jsendResponseDecoder(
  string,
  confirmCredentialsResponseFailDecoder
);

export interface ChangeEmailRequest {
  token: string;
  code: string;
  email: string;
}

export type ChangeProfileResponse = JsendResponse<string, string>;

export const changeEmailResponseDecoder: Decoder<ChangeProfileResponse> = jsendResponseDecoder(string, string);

export interface ChangePhoneRequest {
  token: string;
  code: string;
  phone: string;
}

export const changePhoneResponseDecoder: Decoder<ChangeProfileResponse> = jsendResponseDecoder(string, string);

export interface ChangePasswordRequest {
  token: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const changePasswordResponseDecoder: Decoder<ChangeProfileResponse> = jsendResponseDecoder(string, string);

export interface ChangeQuestionsRequest {
  token: string;
  newSecretQuestions: DecoderType<typeof securityQuestionsDecoder>;
}

export const changeQuestionsResponseDecoder: Decoder<ChangeProfileResponse> = jsendResponseDecoder(string, string);

export interface ChangeAddressRequest {
  address: {
    country: string;
    state: string;
    city: string;
    region: string;
    reference: string;
    sector: string;
  };
}

export const changeAddressResponseDecoder: Decoder<ChangeProfileResponse> = jsendResponseDecoder(string, string);

const bankAccountOptionDecoder = object({
  id: string,
  bank: string,
  number: string,
});

export type BankAccountOption = DecoderType<typeof bankAccountOptionDecoder>;
const bankAccountOptionsDecoder = array(bankAccountOptionDecoder);

export const bankAccountOptionsResponseDecoder = jsendSuccessDecoder(bankAccountOptionsDecoder);
export type BankAccountOptionsResponse = DecoderType<typeof bankAccountOptionsResponseDecoder>;

const savingsAccountDecoder = object({
  number: number,
  type: string,
  quota: string,
  availableBalance: number,
});

export type SavingsAccount = DecoderType<typeof savingsAccountDecoder>;

const savingsAccountsDecoder = array(savingsAccountDecoder);

export const savingsAccountsResponseDecoder = jsendSuccessDecoder(savingsAccountsDecoder);
export type SavingsAccountsResponse = DecoderType<typeof savingsAccountsResponseDecoder>;
