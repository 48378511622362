import { SVGProps } from "react";

export default function InfoIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="70" height="70" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5 0C18.5807 0 0 18.5807 0 41.5C0 64.4193 18.5807 83 41.5 83C64.4193 83 83 64.4193 83 41.5C83 18.5807 64.4193 0 41.5 0ZM39.6136 18.8636C38.613 18.8636 37.6534 19.2611 36.9459 19.9686C36.2384 20.6762 35.8409 21.6358 35.8409 22.6364C35.8409 23.637 36.2384 24.5966 36.9459 25.3041C37.6534 26.0116 38.613 26.4091 39.6136 26.4091H41.5C42.5006 26.4091 43.4602 26.0116 44.1677 25.3041C44.8752 24.5966 45.2727 23.637 45.2727 22.6364C45.2727 21.6358 44.8752 20.6762 44.1677 19.9686C43.4602 19.2611 42.5006 18.8636 41.5 18.8636H39.6136ZM33.9545 33.9545C32.954 33.9545 31.9943 34.352 31.2868 35.0596C30.5793 35.7671 30.1818 36.7267 30.1818 37.7273C30.1818 38.7279 30.5793 39.6875 31.2868 40.395C31.9943 41.1025 32.954 41.5 33.9545 41.5H37.7273V52.8182H33.9545C32.954 52.8182 31.9943 53.2157 31.2868 53.9232C30.5793 54.6307 30.1818 55.5903 30.1818 56.5909C30.1818 57.5915 30.5793 58.5511 31.2868 59.2586C31.9943 59.9662 32.954 60.3636 33.9545 60.3636H49.0455C50.046 60.3636 51.0056 59.9662 51.7132 59.2586C52.4207 58.5511 52.8182 57.5915 52.8182 56.5909C52.8182 55.5903 52.4207 54.6307 51.7132 53.9232C51.0056 53.2157 50.046 52.8182 49.0455 52.8182H45.2727V37.7273C45.2727 36.7267 44.8752 35.7671 44.1677 35.0596C43.4602 34.352 42.5006 33.9545 41.5 33.9545H33.9545Z"
        fill="#547725"
      />
    </svg>
  );
}
