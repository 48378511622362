import { useFormikContext } from "formik";
import OtpInput from "react18-otp-input";
import { useField } from "formik";
import { FormHelperText, TextFieldProps } from "@mui/material";

export default function FormikOtpCodeField(props: TextFieldProps & { name: string }): JSX.Element {
  // @ts-expect-error ignore incompatible props
  const [{ value }, , { setValue }] = useField<string>(props);
  const { isSubmitting, errors, touched } = useFormikContext<{ [k: string]: string }>();
  const error = Boolean(touched[props.name]) && Boolean(errors[props.name]);
  return (
    <>
      <OtpInput
        numInputs={6}
        onChange={(value: string) => setValue(value)}
        hasErrored={error}
        isInputNum={true}
        isDisabled={isSubmitting}
        value={value}
        shouldAutoFocus
        containerStyle={{
          justifyContent: "space-between",
          maxWidth: "600px",
        }}
        inputStyle={{
          width: "70px",
          height: "90px",
          fontSize: "40px",
          fontWeight: "bold",
        }}
        errorStyle={{
          outline: "#ff0000 auto 1px",
        }}
        focusStyle={{ outline: "#315500 auto 1px" }}
      />
      {error ? <FormHelperText error> {errors[props.name]}</FormHelperText> : null}
    </>
  );
}
