import { Backdrop as BackdropModal, CircularProgress, Stack } from "@mui/material";

export interface BackdropState {
  open: boolean;
  msg?: string | React.ReactNode;
}

export function Backdrop({ open, msg }: BackdropState): JSX.Element {
  return (
    <BackdropModal sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }} open={open}>
      <Stack spacing={2} alignItems="center">
        <CircularProgress color="inherit" />
        <div>{msg}</div>
      </Stack>
    </BackdropModal>
  );
}
