import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, Card, Link, Stack, Typography } from "@mui/material";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { acceptTerms } from "Services/api/register/register";
import AccessInfo, { description, steps } from "Shared/AccessInfo/AccessInfo";
import FormikCheckbox from "Shared/FormikCheckbox/FormikCheckbox";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import * as Yup from "yup";
import { defaultRegContext, useRegistrationContext } from "../Registration";

export function Terms() {
  const [{ id }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <SplitFormContainer
      title="Términos y condiciones"
      sideInfoTop={<AccessInfo />}
      form={
        <Formik
          initialValues={{ terms: false }}
          onSubmit={async ({ terms }, { setSubmitting }) => {
            try {
              const response = await acceptTerms(id, terms);
              setRegContext({ status: response.data.status });
              setSubmitting(false);
            } catch (error) {
              enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
            }
          }}
          validationSchema={Yup.object({
            terms: Yup.boolean().isTrue("Debe leer y aceptar los terminos, requisitos y condiciones"),
          })}
        >
          <FormikForm width="100%">
            <ReadRequirements />
            <FormikCheckbox
              name="terms"
              label={
                <Typography>
                  Acepto que he leído y comprendido los requisitos de afiliación, así como los{" "}
                  <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/condiciones-uso-digital/">
                    Términos y Condiciones
                  </Link>
                  , y autorizo el uso de mi información conforme a los propósitos detallados.
                </Typography>
              }
            />

            <Stack direction="row" width="100%" spacing={2}>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<ChevronLeft />}
                sx={{ justifyContent: "flex-start" }}
                onClick={() => setRegContext(defaultRegContext)}
              >
                ATRAS
              </Button>
              <FormikSubmitButton
                fullWidth
                variant="contained"
                endIcon={<ChevronRight />}
                sx={{ justifyContent: "flex-end" }}
              >
                SIGUIENTE
              </FormikSubmitButton>
            </Stack>
          </FormikForm>
        </Formik>
      }
    />
  );
}

function ReadRequirements() {
  return (
    <Fragment>
      <Stack spacing={2} component={Card} padding="15px" elevation={5} display={{ sm: "none" }}>
        <Typography component="p">{description}</Typography>
        <ol>
          {steps.map((step) => (
            <li key={step}>{step}</li>
          ))}
        </ol>
      </Stack>
    </Fragment>
  );
}
