import Password from "Shared/Password/Password";
import { Stack } from "@mui/material";
import { Formik } from "formik";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { SecretQuestions } from "Shared/SecretQuestionsForm/SecretQuestionsForm";
import { SecretQuestionData } from "Shared/SecretQuestionsForm/interfaces";
import { TokenInputs } from "./interfaces";
import { passwordValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";
import { createToken, verifyToken } from "Services/api/profile/profile";
import { useSnackbar } from "notistack";
import { useHandleError } from "Hooks/useHandleError";

export default function ProfileToken({
  secretQuestions,
  onSubmit,
}: {
  secretQuestions: SecretQuestionData[];
  onSubmit: () => Promise<void>;
}) {
  const initValues = Object.assign(
    { password: "" },
    ...secretQuestions.map((record, index) => ({
      [`secret-question-${index}`]: record.question,
      [`secret-answer-${index}`]: record.answer || "",
    }))
  ) as TokenInputs;
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();

  return (
    <Formik
      initialValues={initValues}
      onSubmit={async ({ password, ...questions }, { setSubmitting }) => {
        try {
          const secretQuestions = [...new Array(Object.keys(questions).length / 2).keys()].map((index) => ({
            question: questions[`secret-question-${index}`],
            answer: questions[`secret-answer-${index}`],
          })) as SecretQuestionData[];

          const { status, data } = await createToken({ password, secretQuestions });

          if (status === "success") {
            sessionStorage.setItem("profile-token", data);
            await onSubmit();
          } else enqueueSnackbar(data.message, { variant: "error" });
          setSubmitting(false);
        } catch (error) {
          handleError(error);
        }
      }}
      validationSchema={Yup.object({
        password: passwordValidation,
        ...Object.assign(
          {},
          ...[...new Array(secretQuestions.length).keys()].map((index) => ({
            [`secret-question-${index}`]: requiredValidation,
            [`secret-answer-${index}`]: requiredValidation,
          }))
        ),
      })}
    >
      <FormikForm width="100%">
        <Stack spacing={2}>
          <Password
            name="password"
            id="password"
            fullWidth
            label="Confirma tu contraseña actual para poder continuar:"
            variant="outlined"
            required
            InputProps={{
              autoComplete: "current-password",
            }}
          />

          <SecretQuestions data={secretQuestions} questionAsLabel />

          <FormikSubmitButton fullWidth variant="contained">
            Continuar
          </FormikSubmitButton>
        </Stack>
      </FormikForm>
    </Formik>
  );
}

export async function checkToken(
  onValid: (token: string) => void | Promise<void>,
  onInvalid: () => void | Promise<void>
) {
  const token = sessionStorage.getItem("profile-token");
  if (!token) return onInvalid();

  const { data: validToken } = await verifyToken(token);
  if (!validToken) return onInvalid();

  return onValid(token);
}
