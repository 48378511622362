import { Stack } from "@mui/material";
import { Formik } from "formik";
import { useCallback, useState } from "react";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { EditContentProps } from "./interfaces";
import * as Yup from "yup";
import { requiredValidation } from "Utils/validations";
import { useSnackbar } from "notistack";
import QuestionsSelect from "Shared/QuestionsSelect/QuestionsSelect";
import { useAsync } from "Hooks/useAsync";
import { getSecurityQuestions } from "Services/api/crm/crm";
import { SecurityQuestionOption } from "Services/api/crm/interfaces";
import { updateQuestions } from "Services/api/profile/profile";
import { useHandleError } from "Hooks/useHandleError";
import ProfileToken, { checkToken } from "./ProfileToken";

export const UserFormSchema = Yup.object({
  question1: requiredValidation,
  answer1: requiredValidation.max(40, "La respuesta no debe exceder 40 caracteres"),
  question2: requiredValidation,
  answer2: requiredValidation.max(40, "La respuesta no debe exceder 40 caracteres"),
});

export function QuestionsChange({ profile, setProfile, closeDialog }: EditContentProps) {
  const { enqueueSnackbar } = useSnackbar();

  const getInitData = useCallback(async () => {
    const { data: securityQuestions } = await getSecurityQuestions();
    return securityQuestions;
  }, []);
  const { status, value } = useAsync(getInitData, null, (error) => {
    enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
    console.error(error);
  });

  return status === "pending" || status === "idle" ? (
    <div>Cargando opciones...</div>
  ) : status === "success" ? (
    <Form securityQuestions={value} {...{ profile, setProfile, closeDialog }} />
  ) : status === "error" ? (
    <div>Ha ocurrido un error cargando los datos ...</div>
  ) : (
    <></>
  );
}

interface Payload {
  question1: string;
  answer1: string;
  question2: string;
  answer2: string;
}

const initialValues: Payload = {
  question1: "",
  answer1: "",
  question2: "",
  answer2: "",
};

function Form({
  profile,
  setProfile,
  closeDialog,
  securityQuestions,
}: EditContentProps & { securityQuestions: SecurityQuestionOption[] }) {
  const { enqueueSnackbar } = useSnackbar();
  const [payload, setPayload] = useState<Payload>(initialValues);
  const [step, setStep] = useState<"edit" | "verification">("edit");
  const handleError = useHandleError();

  const changeQuestions = useCallback(async (values: Payload) => {
    await checkToken(
      async (token) => {
        const { status, data } = await updateQuestions({
          token: token,
          newSecretQuestions: [
            {
              question: values.question1,
              answer: values.answer1,
            },
            {
              question: values.question2,
              answer: values.answer2,
            },
          ],
        });

        if (status === "success") {
          enqueueSnackbar(data, { variant: "success" });
          setProfile({
            ...profile,
            securityQuestions: [
              {
                question: values.question1,
                answer: "",
              },
              {
                question: values.question2,
                answer: "",
              },
            ],
          });
          closeDialog();
        } else enqueueSnackbar(data, { variant: "error" });
      },
      () => {
        setPayload(values);
        setStep("verification");
      }
    );
  }, []);

  return step === "edit" ? (
    <Formik
      initialValues={initialValues}
      validationSchema={UserFormSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await changeQuestions(values);
        } catch (error) {
          handleError(error);
        }
        setSubmitting(false);
      }}
    >
      <FormikForm width="100%">
        <Stack spacing={2}>
          <QuestionsSelect data={securityQuestions} />

          <FormikSubmitButton fullWidth variant="contained">
            Continuar
          </FormikSubmitButton>
        </Stack>
      </FormikForm>
    </Formik>
  ) : (
    <ProfileToken
      secretQuestions={profile.securityQuestions}
      onSubmit={async () => {
        await changeQuestions(payload);
      }}
    />
  );
}
