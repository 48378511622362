import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { useTimer } from "react-timer-hook";
import { validateEmail, validatePhone, validateUserEmail, validateUserPhone } from "Services/api/otp/otp";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { getFullPhoneFormat } from "Utils/getFullPhoneFormat";
import * as Yup from "yup";
import FormikOtpCodeField from "./FormikOtpCodeField";

export const otpCodeSchema = Yup.string().required("Codigo Requerido").length(6, "Codigo incorrecto");

export function OtpValidation({ onBack }: { onBack: () => void }) {
  const [, , { setValue: setFieldChannel }] = useField<"" | "sms" | "whatsapp" | "call" | "email">("channel");
  const { enqueueSnackbar } = useSnackbar();
  const [to, setTo] = useState("");
  const [channel, setChannel] = useState<"" | "loading" | "sms" | "whatsapp" | "call" | "email">("");
  const isEmpty = channel == "";
  const isLoading = channel == "loading";
  const isEmail = channel === "email";
  const isSms = channel === "sms";
  const isWhatsApp = channel === "whatsapp";
  const isCall = channel === "call";

  return (
    <Fragment>
      <Stack spacing={1}>
        {isEmpty && (
          <Alert severity="info" color="success" variant="outlined">
            Para completar la solicitud debe de confirmar un código enviado a su correo electrónico o teléfono móvil.
          </Alert>
        )}
        <label>{"Enviar código de confirmación:"}</label>
        <Stack direction={"row"} spacing={1}>
          <Button
            onClick={() => {
              setChannel("loading");
              void validateUserEmail()
                .then(({ data: { to } }) => {
                  setTo(to);
                  setChannel("email");
                  setFieldChannel("email");
                })
                .catch((error) => {
                  setChannel("");
                  setFieldChannel("");
                  enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
                  console.error(error);
                  throw error;
                });
            }}
            variant="outlined"
            fullWidth
            disabled={isEmail}
          >
            Correo
          </Button>
          <Button
            onClick={() => {
              setChannel("loading");
              void validateUserPhone("sms")
                .then(({ data: { to } }) => {
                  setTo(to);
                  setChannel("sms");
                  setFieldChannel("sms");
                })
                .catch((error) => {
                  setChannel("");
                  setFieldChannel("");
                  enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
                  console.error(error);
                  throw error;
                });
            }}
            variant="outlined"
            fullWidth
            disabled={isSms}
          >
            SMS
          </Button>
          <Button
            onClick={() => {
              setChannel("loading");
              void validateUserPhone("whatsapp")
                .then(({ data: { to } }) => {
                  setTo(to);
                  setChannel("whatsapp");
                  setFieldChannel("whatsapp");
                })
                .catch((error) => {
                  setChannel("");
                  setFieldChannel("");
                  enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
                  console.error(error);
                  throw error;
                });
            }}
            variant="outlined"
            fullWidth
            disabled={isWhatsApp}
          >
            WhatsApp
          </Button>
          <Button
            onClick={() => {
              setChannel("loading");
              void validateUserPhone("call")
                .then(({ data: { to } }) => {
                  setTo(to);
                  setChannel("call");
                  setFieldChannel("call");
                })
                .catch((error) => {
                  setChannel("");
                  setFieldChannel("");
                  enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
                  console.error(error);
                  throw error;
                });
            }}
            variant="outlined"
            fullWidth
            disabled={isCall}
          >
            Llamada
          </Button>
        </Stack>
      </Stack>

      {isLoading && (
        <Stack spacing={2} alignItems="center">
          <CircularProgress color="inherit" />
          <div>Enviando código...</div>
        </Stack>
      )}
      {isEmail && <OtpValidationFormFields channel={channel} channelDisplay="correo" to={to} expiryTime={600} />}
      {(isSms || isWhatsApp || isCall) && (
        <OtpValidationFormFields channel={channel} channelDisplay="teléfono movil" to={to} expiryTime={600} />
      )}
      <Stack direction="row" width="100%" spacing={2}>
        <Button fullWidth variant="text" sx={{ justifyContent: "flex-start" }} onClick={onBack}>
          Atras
        </Button>
        <FormikSubmitButton
          fullWidth
          variant="contained"
          sx={{ justifyContent: "flex-end" }}
          disabled={isEmpty || isLoading}
        >
          Solicitar
        </FormikSubmitButton>
      </Stack>
    </Fragment>
  );
}

const VerificationTitle = styled(Typography)(() => ({
  color: "#7C7C7C",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "55px",
}));

const Text = styled(Typography)(() => ({
  color: "#7C7C7C",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
}));

export interface OtpProps {
  channel: "sms" | "whatsapp" | "call" | "email";
  channelDisplay: string;
  to: string;
  expiryTime: number;
}

export function OtpValidationFormFields({ channel, channelDisplay, to, expiryTime }: OtpProps) {
  const textButtonStyle = { justifyContent: "left", padding: "6px 0px;", width: "fit-content" };
  const { enqueueSnackbar } = useSnackbar();

  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + expiryTime);
  const { seconds, minutes, restart } = useTimer({ expiryTimestamp });
  const [openDialog, setOpenDialog] = useState(false);

  async function resendCode() {
    try {
      if (channel === "email") await validateEmail(to);
      else await validatePhone(getFullPhoneFormat(to), channel);
      enqueueSnackbar("Codigo reenviado");
      const expiryTimestamp = new Date();
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + expiryTime);
      restart(expiryTimestamp);
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }
  }

  return (
    <Fragment>
      <Text>
        Hemos enviado un código OTP a su {channelDisplay} <strong>{to}</strong>:
      </Text>
      <VerificationTitle variant="h2">Insertar código</VerificationTitle>
      <FormikOtpCodeField name="code" id="code" fullWidth variant="outlined" required />
      <Text>
        Este código estará vigente los próximos{" "}
        {`${minutes}:${seconds.toString().length === 2 ? seconds : `0${seconds}`}`} minutos
      </Text>

      <Button sx={textButtonStyle} onClick={() => void resendCode()}>
        Volver a enviar código
      </Button>
      <Button sx={textButtonStyle} onClick={() => void setOpenDialog(true)}>
        No he recibido el código
      </Button>
      {openDialog ? <NoCodeDialog open={openDialog} channel={channel} onClose={() => setOpenDialog(false)} /> : null}
    </Fragment>
  );
}

interface NoCodeDialogProps {
  open: boolean;
  channel: OtpProps["channel"];
  onClose: () => void;
}

function NoCodeDialog(props: NoCodeDialogProps) {
  const { onClose, channel, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>¿No has recibido el código?</DialogTitle>
      <DialogContent sx={{ textAlign: "start" }}>
        <p>Si no te llega el código puedes:</p>
        <ul>
          <li>
            <strong>Esperar unos minutos:</strong> A veces puede tomar algunos minutos para el código llegar a tu{" "}
            {channel === "sms" ? "dispositivo" : "correo"}.
          </li>
          {channel === "email" ? (
            <li>
              <strong>Revisar spam:</strong> Puedes intentar revisar tu correo no deseado (spam).
            </li>
          ) : null}
          <li>
            <strong>Contactarnos:</strong> Si aún no recibes el código puedes escribirnos en{" "}
            <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/contactanos/">
              nuestra pagina de contacto
            </Link>{" "}
            y con gusto te ayudaremos.
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
