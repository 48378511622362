import { Stack } from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { SavingsAccount } from "Services/api/profile/interfaces";
import { requestWithdrawal } from "Services/api/request/request";
import { BankAccountFields, BankAccountFieldsSchema } from "Shared/BankAccountFields/BankAccountFields";
import FormikForm from "Shared/FormikForm/FormikForm";
import { FormikMoney, numericConfig } from "Shared/FormikMoney/FormikMoney";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { SavingsAccountSelect } from "Shared/SavingAccountSelect/SavingAccountSelect";
import { SectionTitle } from "Shared/styled";
import { requiredNumberValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";

export function WithdrawalRequest() {
  const navigate = useNavigate();
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });
  const { enqueueSnackbar } = useSnackbar();
  const [account, setAccount] = useState<SavingsAccount>();
  const accountField = "toAccount";
  const bankAccountSchema = BankAccountFieldsSchema(accountField, true);
  const balance = account?.availableBalance || 0;

  return (
    <Stack>
      <SectionTitle variant="h1">Solicitud de retiro electrónico</SectionTitle>
      <Formik
        initialValues={{
          fromAccount: "",
          [accountField]: "",
          accountNumber: "",
          accountBank: "",
          accountType: "",
          accountId: "",
          accountIdType: "Cedula",
          accountName: "",
          accountEmail: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload = {
              ...values,
              fromAccount: account?.number,
            };
            const { status, data } = await requestWithdrawal(payload);
            enqueueSnackbar(data, { variant: status === "fail" ? "error" : "success" });
            if (status === "success") navigate("/");
          } catch (error) {
            enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
          }

          setSubmitting(false);
        }}
        validationSchema={Yup.object({
          fromAccount: requiredNumberValidation,
          [accountField]: requiredValidation,
          amount: requiredNumberValidation
            .positive("Debe ser positivo")
            .max(balance, `No puede ser mayor que RD$${format(balance.toString())}`),
        }).concat(bankAccountSchema)}
      >
        <FormikForm width="100%">
          <SavingsAccountSelect
            name="fromAccount"
            label="Cuenta a debitar"
            exclude={["contributionCertificate"]}
            onChange={(value) => setAccount(value)}
          />
          <BankAccountFields accountField={accountField} label="Cuenta a transferir" extended />

          <Amount balance={balance} />
          <FormikSubmitButton fullWidth variant="contained">
            Continuar
          </FormikSubmitButton>
        </FormikForm>
      </Formik>
    </Stack>
  );
}

function Amount({ balance }: { balance: number }) {
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });

  return (
    <FormikMoney
      name="amount"
      id="amount"
      fullWidth
      variant="outlined"
      label="Monto a transferir"
      helperText={`Balance disponible RD$${format(balance.toString())}`}
    />
  );
}
