import { SVGProps } from "react";

export default function TelegramIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.8632 0.0832399L0.351164 6.24324C-0.152836 6.41124 -0.0968356 7.13924 0.407164 7.30724L4.32716 8.53924L5.89516 13.4672C6.00716 13.8592 6.51116 13.9712 6.79116 13.6912L9.08716 11.5632L13.4552 14.7552C13.7912 14.9792 14.2392 14.8112 14.3512 14.4192L17.3752 1.42724C17.5432 0.47524 16.7032 -0.25276 15.8632 0.0832399ZM6.79116 9.49124L6.28716 12.4032L5.16716 8.37124L16.0872 1.20324L6.79116 9.49124Z"
        fill="#547725"
      />
    </svg>
  );
}
