import { SVGProps } from "react";

export default function CertificateIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="48" height="42" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_923_11976)">
        <path
          d="M0 13.0503C3.465 13.0144 3.465 13.0144 7 12.9777C8.09255 12.9618 8.09255 12.9618 9.20716 12.9456C10.0992 12.9404 10.0992 12.9404 10.5173 12.9394C10.8083 12.9383 11.0991 12.934 11.39 12.9277C11.7621 12.9196 12.1338 12.9173 12.5059 12.9189C12.6419 12.9186 12.7779 12.9163 12.9138 12.9118C13.8995 12.8806 13.8995 12.8806 14.2735 13.1648C14.5988 13.4804 14.8518 13.8388 15.1061 14.2123C15.2034 14.3361 15.3015 14.4592 15.4015 14.5807C15.4784 14.6793 15.555 14.7781 15.6313 14.8771C15.7092 14.9777 15.7872 15.0781 15.8652 15.1785C15.9201 15.2493 15.9201 15.2493 15.9762 15.3216C16.0944 15.4701 16.2176 15.6136 16.3415 15.7574C16.5363 16.0001 16.5363 16.0001 16.5363 16.1788C12.333 16.2231 12.333 16.2231 8.04469 16.2682C8.04469 16.7992 8.04469 17.3301 8.04469 17.8771C7.7869 18.9496 7.25777 19.8268 6.34637 20.4693C5.38218 21.0393 4.74681 21.095 3.57542 21.095C3.57542 25.0476 3.57542 29.0003 3.57542 33.0727C3.98838 33.0727 4.40134 33.0727 4.82682 33.0727C6.01913 33.3617 6.93333 33.9986 7.59776 35.0392C8.04478 35.9436 8.04469 36.5581 8.04469 37.6313C18.6047 37.6313 29.1647 37.6313 40.0447 37.6313C40.0447 37.1889 40.0447 36.7464 40.0447 36.2906C40.3308 35.0985 40.9719 34.1833 42.0112 33.5196C43.0078 33.027 43.6654 33.0727 44.8715 33.0727C44.8715 29.1201 44.8715 25.1674 44.8715 21.095C44.3406 21.095 43.8096 21.095 43.2626 21.095C42.1901 20.8372 41.313 20.3081 40.6704 19.3967C40.0478 18.3436 40.0447 17.5651 40.0447 16.2682C37.1835 16.2387 34.3222 16.2092 31.3743 16.1788C31.5796 15.8366 31.7459 15.6027 32.0112 15.3185C32.3678 14.9295 32.7042 14.529 33.0339 14.117C33.3354 13.7535 33.6527 13.4033 33.9665 13.0503C38.5975 13.0503 43.2286 13.0503 48 13.0503C48 22.3125 48 31.5746 48 41.1174C32.16 41.1174 16.32 41.1174 0 41.1174C0 31.8552 0 22.5931 0 13.0503Z"
          fill="#FFFFFF"
        />
        <path
          d="M19.0391 0C22.3428 0 25.6465 0 29.0503 0C29.0503 2.86123 29.0503 5.72246 29.0503 8.67039C30.2597 8.67039 31.469 8.67039 32.7151 8.67039C32.4136 9.17279 32.4136 9.17279 32.2144 9.40014C32.171 9.4501 32.1276 9.50005 32.0829 9.55152C32.0371 9.60365 31.9913 9.65579 31.9441 9.7095C31.8471 9.82127 31.7501 9.93312 31.6533 10.045C31.6057 10.1 31.5581 10.155 31.509 10.2116C31.3245 10.4268 31.1451 10.6459 30.9665 10.8659C30.6746 11.2253 30.378 11.5802 30.0782 11.933C29.7767 12.2877 29.4783 12.6445 29.1844 13.0056C28.8493 13.4172 28.5077 13.8226 28.1641 14.227C27.905 14.5323 27.6495 14.8402 27.3966 15.1508C27.0616 15.5624 26.72 15.9678 26.3764 16.3722C26.0513 16.7554 25.7329 17.1437 25.4158 17.5335C25.1306 17.8802 24.8399 18.2221 24.5478 18.563C24.3665 18.7757 24.2 18.9849 24.0447 19.2179C23.9562 19.2179 23.8677 19.2179 23.7765 19.2179C23.6089 19.0436 23.6089 19.0436 23.419 18.7989C23.348 18.7091 23.277 18.6194 23.2039 18.5269C23.1662 18.479 23.1285 18.4312 23.0897 18.3819C22.892 18.1345 22.686 17.8944 22.4804 17.6536C22.112 17.2202 21.7502 16.782 21.3911 16.3408C21.161 16.061 20.9273 15.7844 20.6927 15.5084C20.3244 15.0749 19.9625 14.6367 19.6033 14.1955C19.3733 13.9157 19.1396 13.6392 18.905 13.3631C18.5367 12.9296 18.1748 12.4915 17.8156 12.0503C17.5856 11.7705 17.3519 11.4939 17.1173 11.2179C16.6789 10.7016 16.2476 10.18 15.8212 9.65363C15.7179 9.5266 15.7179 9.5266 15.6124 9.397C15.5467 9.31576 15.4811 9.23453 15.4134 9.15084C15.3543 9.07779 15.2952 9.00473 15.2343 8.92947C15.1061 8.75978 15.1061 8.75978 15.1061 8.67039C16.404 8.67039 17.7019 8.67039 19.0391 8.67039C19.0391 5.80916 19.0391 2.94793 19.0391 0Z"
          fill="#FFFFFF"
        />
        <path
          d="M23.0614 21.3632C23.6219 21.3632 24.1823 21.3632 24.7598 21.3632C24.7598 21.8056 24.7598 22.2481 24.7598 22.7039C25.0861 22.7371 25.0861 22.7371 25.419 22.771C26.0181 22.839 26.5394 22.9707 27.0838 23.2403C27.0292 23.9223 26.8339 24.5544 26.6369 25.2067C26.5461 25.1755 26.4553 25.1443 26.3617 25.1121C25.3932 24.7881 24.4183 24.4744 23.419 24.8492C23.1877 24.9909 23.1877 24.9909 23.0614 25.2067C23.0019 25.4749 23.0019 25.4749 23.0614 25.743C23.5483 26.3183 24.3121 26.5725 25.0006 26.8339C26.0361 27.2276 26.8958 27.7401 27.4302 28.7375C27.6814 29.5107 27.639 30.4438 27.2912 31.1771C26.7919 32.0117 26.0992 32.5549 25.1564 32.7933C24.9158 32.8431 24.9158 32.8431 24.6704 32.8939C24.6704 33.4248 24.6704 33.9558 24.6704 34.5028C24.1394 34.5028 23.6085 34.5028 23.0614 34.5028C23.0614 34.0014 23.0614 33.4999 23.0614 32.9833C22.8955 32.9759 22.7296 32.9685 22.5587 32.9609C21.781 32.9022 20.981 32.734 20.2905 32.3576C20.3493 31.8867 20.4454 31.4368 20.5642 30.9777C20.5809 30.9123 20.5975 30.8469 20.6147 30.7795C20.6553 30.6202 20.6963 30.4609 20.7374 30.3017C21.1682 30.4432 21.5927 30.5978 22.0171 30.7574C22.7653 31.0089 23.7556 31.1137 24.4804 30.7598C24.7621 30.6015 24.7621 30.6015 24.8492 30.3911C24.8816 30.0471 24.8944 29.8384 24.7098 29.5405C24.4473 29.2734 24.174 29.1473 23.8324 29.0112C23.7695 28.9848 23.7066 28.9583 23.6417 28.9311C23.3906 28.8258 23.1384 28.7235 22.8854 28.6225C21.7928 28.1866 20.9698 27.7489 20.4693 26.6369C20.28 25.8948 20.311 25.0958 20.648 24.4023C21.1039 23.7396 21.6818 23.2599 22.4357 22.9721C22.7455 22.9279 22.7455 22.9279 23.0614 22.8827C23.0614 22.3813 23.0614 21.8798 23.0614 21.3632Z"
          fill="#FFFFFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_923_11976">
          <rect width="48" height="41.2961" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
