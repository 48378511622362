import { string, array, constant, Decoder, either, object, DecoderType } from "decoders";
import { JsendResponse, jsendResponseDecoder } from "Utils/jsend";
import * as Yup from "yup";
import { requiredValidation } from "Utils/validations";

export const secretQuestionDataDecoder = object({
  question: string,
  answer: string,
});

export type SecretQuestionData = DecoderType<typeof secretQuestionDataDecoder>;

const secretQuestionsResponseFailDecoder = object({
  errorKey: either(constant("invalidToken")),
  message: string,
});

export type SecretQuestionsResponseFail = DecoderType<typeof secretQuestionsResponseFailDecoder>;

export type SecretQuestionsResponse = JsendResponse<SecretQuestionData[], SecretQuestionsResponseFail>;

export const secretQuestionsResponseDecoder: Decoder<SecretQuestionsResponse> = jsendResponseDecoder(
  array(secretQuestionDataDecoder),
  secretQuestionsResponseFailDecoder
);

export const secretQuestionsSchema = (n: number) =>
  Yup.object(
    Object.assign(
      {},
      ...[...new Array(n).keys()].map((index) => ({
        [`secret-question-${index}`]: requiredValidation,
        [`secret-answer-${index}`]: requiredValidation,
      }))
    )
  );
