import { SVGProps } from "react";

export default function LoanIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="48" height="53" viewBox="0 0 48 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.92114 28.097V29.1794V48.5432H13.0188V45.8117L13.0617 45.8285C15.7011 46.7861 19.4758 47.6375 23.2063 49.0927L23.4837 49.1951L23.757 49.127C24.9295 48.8292 28.0882 47.7664 31.8547 46.4465C35.6212 45.1264 39.9178 43.5672 43.1124 42.3641C44.6413 41.7866 45.4636 39.9211 44.9016 38.2646C44.3111 36.5698 42.5985 35.8076 41.1399 36.2962C39.925 36.7021 34.2233 38.6234 30.4798 39.8287C30.515 38.6639 30.7957 37.4042 29.9289 36.6625L29.8822 36.6114L29.8236 36.5856C25.6156 33.8471 25.259 33.4922 19.343 30.0619L19.0656 29.9127L18.7609 29.9468C16.7265 30.1849 13.2702 30.809 13.0188 30.8544V28.0973L2.92114 28.097ZM4.92114 30.2791H11.0305V46.3614H4.92114V30.2791ZM18.6711 32.1624C24.075 35.3021 24.6956 35.7816 28.6906 38.3799C29.3386 38.9346 29.5111 39.6158 29.1204 40.2292C28.9105 40.5585 28.6908 40.6891 28.3859 40.7445C28.0813 40.8042 27.6603 40.7275 27.179 40.4971C25.3799 39.5899 24.4009 39.0115 22.4134 37.7953L21.5422 37.2628L20.5657 39.1634L21.4368 39.6959C23.4372 40.9199 24.4938 41.5582 26.3548 42.4955H26.3704C27.1306 42.8651 27.933 43.0418 28.7219 42.8959C29.0493 42.836 29.2631 42.4718 29.5617 42.2951L29.5931 42.3934L30.5539 42.0947C34.3025 40.897 40.4718 38.8139 41.7219 38.3959V38.3786C42.1944 38.2207 42.8326 38.481 43.0227 39.0262C43.1996 39.5731 42.9689 40.1025 42.4562 40.296C39.2832 41.4911 34.995 43.0461 31.2415 44.3617C27.5818 45.644 24.4852 46.667 23.5109 46.9268C19.7566 45.5046 16.0257 44.6078 13.6906 43.7607L13.0188 43.526V33.0602L13.1867 33.0345C13.1867 33.0345 16.6388 32.4253 18.6711 32.1652L18.6711 32.1624Z"
        fill="#FFFFFF"
      />
      <path
        d="M32.2796 3.16833C25.453 3.16833 19.8901 9.23661 19.8901 16.6835C19.8901 24.1304 25.453 30.1819 32.2796 30.1819C39.1062 30.1819 44.6536 24.1304 44.6536 16.6835C44.6536 9.23661 39.1062 3.16833 32.2796 3.16833ZM32.2796 5.35142C38.0251 5.35142 42.6523 10.4157 42.6523 16.6835C42.6523 22.9512 38.0251 27.9988 32.2796 27.9988C26.5338 27.9988 21.8915 22.9512 21.8915 16.6835C21.8915 10.4157 26.5338 5.35142 32.2796 5.35142Z"
        fill="#FFFFFF"
      />
      <path
        d="M31.2649 9.38538V10.4891V11.2776H28.5423V17.7675H34.0032V19.8941H29.5501H28.5423V22.0759H29.5501H31.2649V22.8598V23.9592H33.2649V22.8598V22.0759H36.0032V15.5857H30.5423V13.4594H34.9952H36.0032V11.2776H34.9952H33.2649V10.4891V9.38538H31.2649Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}
