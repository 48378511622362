import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { CircularProgress, Link, Stack, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { getProductRequests, verifySignature } from "Services/api/request/request";
import { Query } from "Shared/Query/Query";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../../../public/logo_completo_blanco.png";

export function RequestSignatureVerification() {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [signature_id] = useState(searchParams.get("signature_id"));
  const [status] = useState(searchParams.get("status"));

  useEffect(() => {
    if (!signature_id || !status) {
      enqueueSnackbar("Parámetros inválidos", { variant: "error" });
      navigate("/");
    }
  }, [signature_id, status, navigate, enqueueSnackbar]);

  if (!signature_id || !status) return <></>;

  return (
    <SplitFormContainer
      title=""
      sideInfoTop={
        <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
          <img style={{ maxHeight: "400px" }} src={logo} alt="Logo Cooperativa Barcelona" />
        </Stack>
      }
      form={<Confirm signature_id={signature_id} status={status} />}
    />
  );
}

function Confirm(payload: { signature_id: string; status: string }) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const result = useQuery({
    queryKey: [verifySignature.name],
    queryFn: async () => {
      try {
        const response = await verifySignature(payload);
        enqueueSnackbar(response.data, { variant: "success" });
        void queryClient.invalidateQueries({
          queryKey: [getProductRequests.name],
          exact: true,
        });
        return response;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
    gcTime: 0,
    retry: false,
    refetchOnReconnect: false,
  });

  return (
    <Query
      result={result}
      OnLoading={() => (
        <Stack sx={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }} spacing={2}>
          <CircularProgress size={"7.5rem"} thickness={4} />
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 400 }}>Verificando firma...</Typography>
        </Stack>
      )}
      onSuccess={({ status, data }) => (
        <Stack sx={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }} spacing={2}>
          {status === "success" ? (
            <CheckCircleOutline sx={{ fontSize: "7.5rem" }} color="primary" />
          ) : (
            <ErrorOutline sx={{ fontSize: "7.5rem" }} color="primary" />
          )}
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 400, textAlign: "center" }}>{data}</Typography>
          {status == "fail" && (
            <Typography sx={{ fontSize: "1rem", fontWeight: 400, textAlign: "center" }}>
              Revisa el estado de tu solicitud nuevamente y si el problema persiste contactar a servicio al cliente.
            </Typography>
          )}
          <Link component={RouterLink} to="/solicitudes/estados/">
            Volver a solicitudes
          </Link>
          <Typography sx={{ fontSize: "1rem", fontWeight: 400, textAlign: "center" }}>
            o si vienes desde la aplicación cierra esta ventana para continuar.
          </Typography>
        </Stack>
      )}
      onError={() => (
        <Stack sx={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }} spacing={2}>
          <ErrorOutline sx={{ fontSize: "7.5rem" }} color="error" />
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 400, textAlign: "center" }}>
            Error verificando firma
          </Typography>
          <Typography sx={{ fontSize: "1rem", fontWeight: 400, textAlign: "center" }}>
            Si el problema persiste contactar a servicio al cliente.
          </Typography>
          <Link component={RouterLink} to="/solicitudes/estados/">
            Volver a solicitudes
          </Link>
        </Stack>
      )}
    />
  );
}
