import { TextFieldProps, Stack, TextField } from "@mui/material";

export function LabeledTextField(props: TextFieldProps): JSX.Element {
  const { label, hidden, ...filteredProps } = props;
  return (
    <Stack spacing={1} width="100%" sx={hidden ? { display: "none" } : {}}>
      <label htmlFor={props.id}>{label}</label>
      <TextField {...filteredProps} />
    </Stack>
  );
}
