import { SVGProps } from "react";

export default function PhotoUpload(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M71.5789 22.3684V35.7447C71.5789 35.7447 62.6763 35.7895 62.6316 35.7447V22.3684H49.2105C49.2105 22.3684 49.2553 13.4658 49.2105 13.4211H62.6316V0H71.5789V13.4211H85V22.3684H71.5789ZM58.1579 40.2632V26.8421H44.7368V13.4211H8.94737C4.02632 13.4211 0 17.4474 0 22.3684V76.0526C0 80.9737 4.02632 85 8.94737 85H62.6316C67.5526 85 71.5789 80.9737 71.5789 76.0526V40.2632H58.1579ZM8.94737 76.0526L22.3684 58.1579L31.3158 71.5789L44.7368 53.6842L62.6316 76.0526H8.94737Z"
        fill="#BABABA"
      />
    </svg>
  );
}
