import { Accordion, AccordionDetails, Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { ProductSummary } from "Pages/Products/styled";
import { Link as RouterLink } from "react-router-dom";
import { CertificateData } from "Services/api/finance/interfaces";
import { MoneyFormat } from "Shared/MoneyFormat/MoneyFormat";
import ValueElement from "Shared/ValueElement/ValueElement";

export function CertificateSummary({ data }: { data: CertificateData }): JSX.Element | null {
  if (data.items.length === 0) return null;

  return (
    <Accordion defaultExpanded variant="outlined" sx={{ backgroundColor: "#F6F9F2", border: "none" }}>
      <ProductSummary>
        <Typography component="h2" sx={{ fontWeight: "bold" }}>
          Certificados
        </Typography>
      </ProductSummary>
      <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {data.items.map((item) => (
          <Card key={item.accountNumber}>
            <CardContent>
              <Stack flexWrap="wrap" justifyContent={"space-between"} sx={{ flexDirection: { md: "row" } }}>
                <ValueElement title={"Número de producto"} value={item.accountNumber} />
                <ValueElement
                  title={"Tipo de producto"}
                  value={<Typography sx={{ textTransform: "capitalize" }}>{item.accountType.toLowerCase()}</Typography>}
                />

                <Stack>
                  <Stack
                    flexWrap="wrap"
                    gap="5px 15px"
                    justifyContent={"space-between"}
                    sx={{ flexDirection: { md: "row" } }}
                  >
                    <Typography>
                      <strong>Balance:</strong>
                    </Typography>
                    <Typography>
                      <MoneyFormat amount={item.accountBalance} />
                    </Typography>
                  </Stack>
                  <Stack
                    flexWrap="wrap"
                    sx={{ flexDirection: { md: "row" } }}
                    gap="5px 15px"
                    justifyContent={"space-between"}
                  >
                    <Typography>
                      <strong>Balance disponible:</strong>
                    </Typography>
                    <Typography>
                      <MoneyFormat amount={item.availableAccountBalance} />
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions>
              <Button component={RouterLink} to={`/certificado/${item.accountNumber}`}>
                Ver detalle de producto
              </Button>
            </CardActions>
          </Card>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
