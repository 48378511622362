import { InputAdornment, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import { NumericFormat, useNumericFormat } from "react-number-format";
import FormikTextField from "Shared/FormikTextField/FormikTextField";

export const numericConfig = {
  allowNegative: false,
  decimalScale: 2,
  thousandSeparator: ",",
};

export type FormikMoneyField = number | null;

export function FormikMoney(props: TextFieldProps & { name: string }) {
  const { id, name, fullWidth, label, required, variant, helperText, placeholder, disabled } = props;
  const [{ value }, , { setValue }] = useField<FormikMoneyField>(name);
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  const valueFormatted = value ? format(value.toString()) : "";

  const p: Pick<TextFieldProps, "helperText"> = {};
  if (helperText) p["helperText"] = helperText;

  return (
    <NumericFormat
      id={id}
      name={name}
      fullWidth={fullWidth}
      label={label}
      required={required}
      disabled={disabled}
      variant={variant}
      value={valueFormatted}
      placeholder={placeholder}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      customInput={FormikTextField}
      {...numericConfig}
      onValueChange={({ floatValue }) => {
        setValue(floatValue ? floatValue : null);
      }}
      {...p}
    />
  );
}
