import { Email, ErrorOutline, Phone } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Link,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { getContact } from "Services/api/profile/profile";
import { ProductRequest } from "Services/api/request/interfaces";
import { getRequestSigningData } from "Services/api/request/request";
import { Query } from "Shared/Query/Query";
import { SectionTitle } from "Shared/styled";
import ValueElement from "Shared/ValueElement/ValueElement";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

type Step = "confirm" | "documents";

interface windowProp {
  w?: typeof window;
}

export function RequestSignature({ w = window }: windowProp) {
  const [step, setStep] = useState<Step>("confirm");
  const [searchParams] = useSearchParams();

  if (!searchParams.has("id") || !searchParams.has("type")) return <Typography>{GENERIC_ERROR_MESSAGE}</Typography>;

  if (step === "confirm") return <ProfileConfirmation onContinue={() => setStep("documents")} />;
  return <Documents w={w} />;
}

interface ProfileConfirmationProps {
  onContinue: () => void;
}

function ProfileConfirmation({ onContinue }: ProfileConfirmationProps) {
  const [confirmation, setConfirmation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getContact.name],
    queryFn: async () => {
      try {
        const response = await getContact();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmation(event.target.checked);
  };

  return (
    <Stack>
      <SectionTitle variant="h1">Confirma tus datos</SectionTitle>
      <Stack spacing={2}>
        <Typography>
          Valida que tus datos están correctos. En tu correo recibirás información necesaria para completar tu solicitud
          y los documentos correspondientes a la misma:
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Phone />
          <Query
            result={result}
            OnLoading={() => <Skeleton variant="rectangular" height={48} width={200} />}
            onError={() => <>Ha ocurrido un error al intentar obtener el teléfono</>}
            onSuccess={({ phone }) => <ValueElement title="Teléfono móvil:" value={phone} />}
          />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Email />
          <Query
            result={result}
            OnLoading={() => <Skeleton variant="rectangular" height={48} width={200} />}
            onError={() => <>Ha ocurrido un error al intentar obtener el correo electrónico</>}
            onSuccess={({ email }) => <ValueElement title="Correo electrónico:" value={email} />}
          />
        </Stack>

        <Typography>Si deseas modificar tus datos, presiona el botón &quot;EDITAR PERFIL&quot;</Typography>

        <Box>
          <FormControlLabel
            control={
              <Checkbox checked={confirmation} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />
            }
            label="Confirmo que ambos datos son correctos."
            sx={{ my: "20px" }}
          />
        </Box>

        <Stack gap="15px" sx={{ flexDirection: { md: "row" } }}>
          <Button variant="outlined" fullWidth component={RouterLink} to="/socio">
            EDITAR PERFIL
          </Button>
          <Query
            result={result}
            OnLoading={() => <Skeleton variant="rectangular" height={37} width={"100%"} />}
            onError={() => <>Ha ocurrido un error</>}
            onSuccess={() => (
              <Button
                variant="contained"
                fullWidth
                onClick={() => confirmation && onContinue()}
                disabled={!confirmation}
              >
                CONTINUAR
              </Button>
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

function Documents({ w = window }: windowProp) {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  let showError = true;
  const result = useMutation({
    mutationFn: async () => {
      if (!searchParams.has("id") || !searchParams.has("type")) {
        enqueueSnackbar("Parámetros no válidos", { variant: "error" });
        throw "Invalid search params";
      }
      const id = searchParams.get("id") as ProductRequest["id"];
      const type = searchParams.get("type") as ProductRequest["type"];
      const { status, data } = await getRequestSigningData({
        id,
        type,
      });

      if (status === "fail") {
        showError = false;
        enqueueSnackbar(data, { variant: "error" });
        throw data;
      }

      if (data.state === "required") w.location.href = data.url;
      return data;
    },
    onError: (error) => {
      if (showError) enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    },
  });

  const { mutateAsync: startSignature, data, isPending } = result;

  if (isPending) return <LoadingSignature />;

  if (data)
    return (
      <Fragment>
        {data.state === "required" ? (
          <LoadingSignature />
        ) : data.state === "signed" ? (
          <Message
            title="Solicitud firmada"
            subtitle="La firma de esta solicitud ya habia sido completada anteriormente"
          />
        ) : data.state === "invalid_client" ? (
          <Message title="Solicitud inválida" subtitle="Esta solicitud no pertenece a este a cliente." />
        ) : (
          <Message
            title="Firma no disponible"
            subtitle="Esta solicitud no requiere firma o requiere ser firmada en una sucursal."
          />
        )}
      </Fragment>
    );

  return (
    <Stack>
      <SectionTitle variant="h1">Proceso de firma</SectionTitle>
      <Stack spacing={3}>
        <Typography>Estamos a un paso de firmar los documentos y completar tu solicitud</Typography>
        <Typography>
          Al hacer click en &quot;iniciar&quot;, podrás ver los documentos que{" "}
          <strong>debes firmar y aceptar usando un código que recibirás en tu correo</strong>.
        </Typography>

        <Typography>Luego de firmar, enviaremos los documento relevantes a tu correo.</Typography>

        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            void startSignature();
          }}
        >
          INICIAR
        </Button>
      </Stack>
    </Stack>
  );
}

function LoadingSignature() {
  return (
    <Stack
      sx={{ width: "100%", height: "calc(100vh - 80px - 24px - 56px)", alignItems: "center", justifyContent: "center" }}
      spacing={2}
    >
      <CircularProgress size={"7.5rem"} thickness={4} />
      <Typography sx={{ fontSize: "1.5rem", fontWeight: 400 }}>Preparando firma...</Typography>
    </Stack>
  );
}

function Message({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <Stack
      sx={{ width: "100%", height: "calc(100vh - 80px - 24px - 56px)", alignItems: "center", justifyContent: "center" }}
      spacing={2}
    >
      <ErrorOutline sx={{ fontSize: "7.5rem" }} color="primary" />
      <Typography sx={{ fontSize: "1.5rem", fontWeight: 400, textAlign: "center" }}>{title}</Typography>
      <Typography sx={{ fontSize: "1rem", fontWeight: 400, textAlign: "center" }}>{subtitle}</Typography>
      <Link component={RouterLink} to="/solicitudes/estados/">
        Volver a solicitudes
      </Link>
    </Stack>
  );
}
