import { Decoder, string, object, boolean, either, unknown, DecoderType } from "decoders";
import { JsendResponse, jsendResponseDecoder, JsendSuccess, jsendSuccessDecoder } from "Utils/jsend";

const metamapVerificationDecoder = object({
  id: string,
  identity: string,
});

export type MetamapVerification = DecoderType<typeof metamapVerificationDecoder>;

export type MetamapVerificationResponse = JsendSuccess<MetamapVerification>;

export const metamapVerificationResponseDecoder: Decoder<MetamapVerificationResponse> =
  jsendSuccessDecoder(metamapVerificationDecoder);

const verificationResponseSuccessDecoder = object({ result: boolean });

export type VerificationResponseSuccess = DecoderType<typeof verificationResponseSuccessDecoder>;

const verificationResponseFailDecoder = object({ errorKey: string, message: string });

export type VerificationResponseFail = DecoderType<typeof verificationResponseFailDecoder>;

const metaMapIdVerificationResponseSuccessDecoder = object({
  front: verificationResponseSuccessDecoder,
  back: verificationResponseSuccessDecoder,
});

export type MetaMapIdVerificationResponseSuccess = DecoderType<typeof metaMapIdVerificationResponseSuccessDecoder>;

const metaMapIdVerificationResponseFailDecoder = object({
  front: either(verificationResponseSuccessDecoder, verificationResponseFailDecoder),
  back: either(verificationResponseSuccessDecoder, verificationResponseFailDecoder),
});

export type MetaMapIdVerificationResponseFail = DecoderType<typeof metaMapIdVerificationResponseFailDecoder>;

export type MetaMapIdVerificationResponse = JsendResponse<
  MetaMapIdVerificationResponseSuccess,
  MetaMapIdVerificationResponseFail
>;

export const metaMapIdVerificationResponseDecoder: Decoder<MetaMapIdVerificationResponse> = jsendResponseDecoder(
  metaMapIdVerificationResponseSuccessDecoder,
  metaMapIdVerificationResponseFailDecoder
);

export type MetaMapVideoVerificationResponse = JsendResponse<VerificationResponseSuccess, VerificationResponseFail>;

export const metaMapVideoVerificationResponseDecoder: Decoder<MetaMapVideoVerificationResponse> = jsendResponseDecoder(
  verificationResponseSuccessDecoder,
  verificationResponseFailDecoder
);

const metaMapVerificationStatusDataDecoder = object({
  id: string,
  valid: boolean,
});

export type MetaMapVerificationStatus = JsendResponse<
  DecoderType<typeof metaMapVerificationStatusDataDecoder>,
  unknown
>;

export const metaMapVerificationStatusDecoder: Decoder<MetaMapVerificationStatus> = jsendResponseDecoder(
  metaMapVerificationStatusDataDecoder,
  unknown
);
