import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { OtpValidationData } from "Services/api/otp/interfaces";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import { Dispatch, useState } from "react";

type Status = "input" | "validation";

export interface InputFormProps {
  setStatus: Dispatch<Status>;
  setTo: Dispatch<string>;
}

export interface ValidationFormProps {
  setStatus: Dispatch<Status>;
  to: OtpValidationData["to"];
}

interface Props {
  title: string;
  InputForm: ({ setStatus, setTo }: InputFormProps) => JSX.Element;
  ValidationForm: ({ to }: ValidationFormProps) => JSX.Element;
}

export default function OtpValidation({ title, InputForm, ValidationForm }: Props): JSX.Element {
  const [status, setStatus] = useState<Status>("input");
  const [to, setTo] = useState<OtpValidationData["to"]>("");

  return (
    <SplitFormContainer
      title={status === "input" ? title : "Código de seguridad"}
      sideInfoTop={<AccessInfo />}
      form={
        status === "input" ? (
          <InputForm setStatus={setStatus} setTo={setTo} />
        ) : (
          <ValidationForm to={to} setStatus={setStatus} />
        )
      }
    />
  );
}
