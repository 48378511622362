import { ChevronRight, ImportContacts } from "@mui/icons-material";
import { Box, Button, Stack, SxProps, Theme, Typography } from "@mui/material";
import CertificateIcon from "Icons/CertificateIcon";
import EditNoteIcon from "Icons/EditNoteIcon";
import LoanIcon from "Icons/LoanIcon";
import SavingsPlanIcon from "Icons/SavingsPlanIcon";
import { SectionTitle } from "Shared/styled";
import { Link } from "react-router-dom";

export function ProductRequests() {
  return (
    <div>
      <SectionTitle variant="h1">Solicitudes</SectionTitle>
      <Button
        variant="contained"
        fullWidth
        endIcon={<ChevronRight />}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          py: "16px",
          maxWidth: "315px",
        }}
        component={Link}
        to="estados"
      >
        Estado de tus solicitudes
      </Button>
      <Typography variant="h2" sx={{ fontSize: "1.25rem", fontWeight: "bold", py: "20px" }}>
        Solicitar producto:
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          flexWrap: "wrap",
        }}
      >
        <ProductButton text="Préstamo" icon={<LoanIcon />} link="financiamiento" />
        <ProductButton text="Cértificado" icon={<CertificateIcon />} link="certificado" />
        <ProductButton text="Cuenta" icon={<ImportContacts sx={{ fontSize: "48px" }} />} link="cuenta" />
        <ProductButton text="Plan de ahorro" icon={<SavingsPlanIcon />} link="plan-de-ahorro" />
        <ProductButton text="Editar cuenta" icon={<EditNoteIcon width="48px" height="48px" />} link="editar-cuenta" />
      </Box>
    </div>
  );
}

interface ProductButtonProps {
  text: string;
  icon: JSX.Element;
  link: string;
  sx?: SxProps<Theme>;
}

function ProductButton(props: ProductButtonProps) {
  const { text, icon, link, sx } = props;
  return (
    <Button
      variant="contained"
      sx={{
        width: "150px",
        height: "130px",
        borderRadius: "15px",
        ...sx,
      }}
      component={Link}
      to={link}
    >
      <Stack alignItems="center" rowGap={1}>
        <Box sx={{ height: "55px", display: "flex", alignItems: "center" }}>{icon}</Box>
        {text}
      </Stack>
    </Button>
  );
}
