import { Stack } from "@mui/material";
import { Formik } from "formik";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import Password from "Shared/Password/Password";
import PasswordHide from "Shared/PasswordHide/PasswordHide";
import { EditContentProps } from "./interfaces";
import * as Yup from "yup";
import { passwordValidation } from "Utils/validations";
import { useSnackbar } from "notistack";
import { updatePassword } from "Services/api/profile/profile";
import { useCallback, useState } from "react";
import ProfileToken, { checkToken } from "./ProfileToken";
import { useHandleError } from "Hooks/useHandleError";

const schema = Yup.object({
  password: passwordValidation,
  confirm: passwordValidation.oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir"),
});

interface Payload {
  password: string;
  confirm: string;
}

export function PasswordChange({ profile: { securityQuestions }, closeDialog }: EditContentProps) {
  const [payload, setPayload] = useState<Payload>({ password: "", confirm: "" });
  const [step, setStep] = useState<"edit" | "verification">("edit");
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();

  const changePassword = useCallback(async ({ password, confirm }: Payload) => {
    await checkToken(
      async (token) => {
        const { status, data } = await updatePassword({
          token,
          newPassword: password,
          newPasswordConfirm: confirm,
        });

        if (status === "success") {
          enqueueSnackbar(data, { variant: "success" });
          closeDialog();
        } else enqueueSnackbar(data, { variant: "error" });
      },
      () => {
        setPayload({ password, confirm });
        setStep("verification");
      }
    );
  }, []);

  return step === "edit" ? (
    <Formik
      initialValues={{ password: "", confirm: "" }}
      validationSchema={schema}
      onSubmit={async (inputs, { setSubmitting }) => {
        try {
          await changePassword(inputs);
        } catch (error) {
          handleError(error);
        }
        setSubmitting(false);
      }}
    >
      <FormikForm width="100%">
        <Stack spacing={2}>
          <PasswordHide
            name="password"
            id="password"
            label="Crea una contraseña (4 dígitos):"
            InputProps={{
              autoComplete: "new-password",
            }}
          />
          <Password
            name="confirm"
            id="confirm"
            label="Confirmar contraseña:"
            type="password"
            InputProps={{
              autoComplete: "new-password",
            }}
          />
          <FormikSubmitButton fullWidth variant="contained">
            Cambiar
          </FormikSubmitButton>
        </Stack>
      </FormikForm>
    </Formik>
  ) : (
    <ProfileToken
      secretQuestions={securityQuestions}
      onSubmit={async () => {
        await changePassword(payload);
      }}
    />
  );
}
