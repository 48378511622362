import { SVGProps } from "react";

export default function FileIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="35" height="44" viewBox="0 0 35 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.5433 21.8576L15.6754 26.729L13.6321 24.1558C13.5949 24.1088 13.5474 24.0709 13.4931 24.0448C13.4388 24.0186 13.3793 24.0051 13.3189 24.0051C13.2585 24.0051 13.199 24.0186 13.1447 24.0448C13.0904 24.0709 13.0429 24.1088 13.0057 24.1558L8.04403 30.4022C7.99813 30.4601 7.96965 30.5296 7.96184 30.6028C7.95404 30.6759 7.96723 30.7497 7.9999 30.8158C8.03257 30.8819 8.08341 30.9376 8.1466 30.9764C8.2098 31.0153 8.28279 31.0359 8.35724 31.0357H26.6477C26.9808 31.0357 27.1648 30.6576 26.9609 30.4022L20.1747 21.8576C20.137 21.8106 20.089 21.7726 20.0344 21.7465C19.9797 21.7203 19.9198 21.7068 19.859 21.7068C19.7983 21.7068 19.7383 21.7203 19.6837 21.7465C19.629 21.7726 19.581 21.8106 19.5433 21.8576ZM9.94318 18.5625C9.94318 19.0835 10.1527 19.5831 10.5256 19.9515C10.8986 20.3198 11.4044 20.5268 11.9318 20.5268C12.4592 20.5268 12.9651 20.3198 13.338 19.9515C13.7109 19.5831 13.9205 19.0835 13.9205 18.5625C13.9205 18.0415 13.7109 17.5419 13.338 17.1735C12.9651 16.8052 12.4592 16.5982 11.9318 16.5982C11.4044 16.5982 10.8986 16.8052 10.5256 17.1735C10.1527 17.5419 9.94318 18.0415 9.94318 18.5625ZM34.5327 11.0295L23.8338 0.461607C23.5355 0.166964 23.1328 0 22.7102 0H1.59091C0.710938 0 0 0.702232 0 1.57143V42.4286C0 43.2978 0.710938 44 1.59091 44H33.4091C34.2891 44 35 43.2978 35 42.4286V12.1442C35 11.7268 34.831 11.3241 34.5327 11.0295ZM31.331 12.8661H21.9744V3.62411L31.331 12.8661ZM31.4205 40.4643H3.57955V3.53571H18.5938V14.1429C18.5938 14.6899 18.8137 15.2145 19.2053 15.6013C19.5969 15.9881 20.128 16.2054 20.6818 16.2054H31.4205V40.4643Z"
        fill="#547725"
      />
    </svg>
  );
}
