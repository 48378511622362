import { SVGProps } from "react";

export default function PhotoCapture(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="80" height="74" viewBox="0 0 80 74" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.375 10.8334V0.458374H17.2917V10.8334H27.6667V17.75H17.2917V28.125H10.375V17.75H0V10.8334H10.375ZM20.75 31.5834V21.2084H31.125V10.8334H55.3333L61.6621 17.75H72.625C76.4292 17.75 79.5417 20.8625 79.5417 24.6667V66.1667C79.5417 69.9709 76.4292 73.0834 72.625 73.0834H17.2917C13.4875 73.0834 10.375 69.9709 10.375 66.1667V31.5834H20.75ZM44.9583 62.7084C54.5033 62.7084 62.25 54.9617 62.25 45.4167C62.25 35.8717 54.5033 28.125 44.9583 28.125C35.4133 28.125 27.6667 35.8717 27.6667 45.4167C27.6667 54.9617 35.4133 62.7084 44.9583 62.7084ZM33.8917 45.4167C33.8917 51.538 38.8371 56.4834 44.9583 56.4834C51.0796 56.4834 56.025 51.538 56.025 45.4167C56.025 39.2955 51.0796 34.35 44.9583 34.35C38.8371 34.35 33.8917 39.2955 33.8917 45.4167Z"
        fill="#BABABA"
      />
    </svg>
  );
}
