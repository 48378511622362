import { Alert, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { AffiliationOptions, SaveSavingsRequestPayload } from "Services/api/register/interfaces";
import {
  getAffiliationTermsOptions,
  saveAffiliationTerms,
  saveAffiliationTermsInitial,
} from "Services/api/register/register";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import FormikCheckbox from "Shared/FormikCheckbox/FormikCheckbox";
import FormikForm from "Shared/FormikForm/FormikForm";
import { FormikMoneyField, numericConfig } from "Shared/FormikMoney/FormikMoney";
import { FormikSlider } from "Shared/FormikSlider/FormikSlider";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { Query } from "Shared/Query/Query";
import { SavingsAccountForm } from "Shared/SavingsAccountForm/SavingsAccountForm";
import { TypeOfPayment } from "Shared/TypeOfPayment/TypeOfPayment";
import { requiredNumberValidation, requiredValidation } from "Utils/validations";
import { Formik, useField, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import { Dispatch, Fragment, useEffect, useState } from "react";
import { useNumericFormat } from "react-number-format";
import * as Yup from "yup";
import { ActionButtons } from "../ActionButtons/ActionButtons";
import { useRegistrationContext } from "../Registration";

type Step = "initial" | "monthly";

interface AffiliationTermsProps {
  init?: Step;
}

export function AffiliationTerms({ init = "initial" }: AffiliationTermsProps) {
  const [step, setStep] = useState<Step>(init);
  const initial = step === "initial";

  const [{ id }] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getAffiliationTermsOptions.name],
    queryFn: async () => {
      try {
        const response = await getAffiliationTermsOptions({ id });
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
    gcTime: 0,
  });

  return (
    <SplitFormContainer
      title={initial ? "Montos de apertura" : "Cuotas mensuales"}
      sideInfoTop={<AccessInfo />}
      form={
        <Query
          result={result}
          OnLoading={() => (
            <Fragment>
              <Skeleton variant="rectangular" width={"100%"} height={100} />
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Fragment>
          )}
          onSuccess={(result) =>
            initial ? <Initial options={result} setStep={setStep} /> : <Monthly options={result} setStep={setStep} />
          }
        />
      }
    />
  );
}

interface Props {
  options: AffiliationOptions;
  setStep: Dispatch<Step>;
}

function Initial({ options: { initial }, setStep }: Props) {
  const [{ id }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: Partial<SaveSavingsRequestPayload> = {
    id,
    editInitialDeposit: false,
    initialDeposit: null,
    typeofPayment: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const requestData = new FormData();

          for (const key in values) {
            const el = values[key as keyof SaveSavingsRequestPayload];
            if (el) requestData.append(key, typeof el === "number" || typeof el === "boolean" ? el.toString() : el);
          }
          const { data } = await saveAffiliationTermsInitial(requestData);
          setRegContext(data);
          setStep("monthly");
          setSubmitting(false);
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        }
      }}
      validationSchema={Yup.object({
        editInitialDeposit: Yup.boolean(),
        typeofPayment: Yup.string(),
      })}
    >
      <FormikForm width="100%">
        <InitialForm options={initial} />
      </FormikForm>
    </Formik>
  );
}

function InitialForm({ options }: { options: AffiliationOptions["initial"] }) {
  const [{ institutionIsAffiliated, purpose }] = useRegistrationContext();
  const { captureTypeOfPayment, tooltip } = options;
  const [{ value: editInitialDeposit }] = useField<boolean>("editInitialDeposit");
  const [{ value: initialDeposit }, ,] = useField<number>("initialDeposit");
  const { values, setValues } = useFormikContext<Partial<SaveSavingsRequestPayload>>();
  const is_certificate = purpose !== "certificate";

  useEffect(() => {
    if (!editInitialDeposit)
      setValues({
        ...values,
        initialDeposit: null,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editInitialDeposit]);

  return (
    <Fragment>
      <Alert severity="info" color="success" variant="outlined">
        {tooltip}
      </Alert>
      <InitialChargesDistributionTable options={options} />
      {is_certificate && (
        <FormikCheckbox
          id="editInitialDeposit"
          name="editInitialDeposit"
          label={`¿Desea aumentar el balance de apertura?`}
        />
      )}
      {editInitialDeposit && <SavingsAccountForm type="in_sight" isRegistration />}
      {(captureTypeOfPayment || (!institutionIsAffiliated && initialDeposit > 0)) && (
        <Stack spacing={1}>
          <label htmlFor="typeofPayment">¿Cómo entregará los montos de apertura?</label>
          <TypeOfPayment placeholder="Seleccionar" ariaLabel="¿Cómo entregará los montos de apertura?" />
        </Stack>
      )}
      <ActionButtons />
    </Fragment>
  );
}

function InitialChargesDistributionTable({
  options: {
    distribution: { values, total },
  },
}: {
  options: AffiliationOptions["initial"];
}) {
  const [{ value: initialDeposit }, ,] = useField<number>("initialDeposit");
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  const hasIncrease = initialDeposit > 0;

  let totalSum = total[1];
  if (hasIncrease) totalSum += initialDeposit;

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Concepto</TableCell>
            <TableCell align="right">Monto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map(([id, heading, value]) => (
            <TableRow key={id}>
              <TableCell>{heading}</TableCell>
              <TableCell align="right">
                {value < 0 && "-"}
                {id === "savings" && hasIncrease
                  ? `RD$${format((value + initialDeposit).toString())}`
                  : `RD$${format(value.toString())}`}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <strong>{total[0]}</strong>
            </TableCell>
            <TableCell align="right">
              <strong>RD${format(totalSum.toString())}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
}

function Monthly({ setStep, options }: Props) {
  return <MonthlyForm options={options} setStep={setStep} />;
}

function MonthlyForm({ options: { monthly }, setStep }: Props) {
  const {
    distribution: { values },
    monthlyDiscount: { max, step },
  } = monthly;

  const [{ id }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);

  let min = 0;
  for (const value of values) {
    if (value[0] === "savings") {
      min = value[2];
      break;
    }
  }

  return (
    <Formik
      initialValues={{ id, monthlyDiscount: min }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { data } = await saveAffiliationTerms(values);
          setRegContext(data);
          setSubmitting(false);
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        }
      }}
      validationSchema={Yup.object({
        id: requiredValidation,
        monthlyDiscount: requiredNumberValidation
          .min(min, `Monto minimo: RD$${format(min.toString())}`)
          .max(max, `Monto maximo: RD$${format(max.toString())}`),
      })}
    >
      <FormikForm width="100%">
        <MonthlyDiscount min={min} max={max} step={step} />
        <MonthlyChargesDistributionTable result={monthly} />
        <ActionButtons onBack={() => setStep("initial")} />
      </FormikForm>
    </Formik>
  );
}

function MonthlyDiscount({ min, max, step }: { min: number; max: number; step: number }) {
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);

  return (
    <FormikSlider
      id="monthlyDiscount"
      name="monthlyDiscount"
      label="¿Qué monto deseas ahorrar mensualmente?"
      aria-label="¿Qué monto deseas ahorrar mensualmente?"
      valueLabelDisplay="auto"
      min={min}
      max={max}
      step={step}
      marks
      formatValue={(value) => `RD$${format(value)}`}
    />
  );
}

function MonthlyChargesDistributionTable({
  result: {
    distribution: { values, total },
  },
}: {
  result: AffiliationOptions["monthly"];
}) {
  const [{ value: monthlyDiscount }, ,] = useField<FormikMoneyField>("monthlyDiscount");
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  const quotaExtraordinary = monthlyDiscount || 0;

  let savingsQuota = 0;
  for (const value of values) {
    if (value[0] === "savings") {
      savingsQuota = value[2];
      break;
    }
  }
  const totalQuota = total[1] - savingsQuota + quotaExtraordinary;

  if (totalQuota <= 0) return null;

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Concepto</TableCell>
            <TableCell align="right">Cuota mensual</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map(([id, heading, quota]) => {
            quota = id === "savings" ? quotaExtraordinary : quota;
            if (quota <= 0) return null;

            return (
              <TableRow key={id}>
                <TableCell>{heading}</TableCell>
                <TableCell align="right">{`RD$${format(quota.toString())}`}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell>
              <strong>{total[0]}</strong>
            </TableCell>
            <TableCell align="right">
              <strong>RD${format(totalQuota.toString())}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
}
